import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

export interface HubSpotFormContext {
  pageUri: string;
  pageName: string;
}

export interface HubSpotFormField {
  objectTypeId: string;
  name: string;
  value: string;
}

export interface HubSpotFormData {
  fields: HubSpotFormField[];
  context: HubSpotFormContext;
}

@Injectable({ providedIn: 'root' })
export class HubspotService {
  #http = inject(HttpClient);

  async submitForm(
    formId: string,
    data: HubSpotFormData,
  ): Promise<string | null> {
    const response = await this.#http
      .post<{
        redirectUri?: string;
      }>('/api/submit-hubspot-form', { formId, data })
      .toPromise();

    if (response?.redirectUri) {
      return response.redirectUri;
    }
    return null;
  }
}
