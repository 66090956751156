import { GutupBubbleCardComponent } from './../../components/gutup-bubbles/gutup-bubble-card.component';
import { Component } from '@angular/core';
import { SourceItem } from './source-item.component';
import { SourcesContentComponent } from './sources-content.component';

export interface SourceSection {
  title: string;
  items: SourceItem[];
}

@Component({
  standalone: true,
  imports: [GutupBubbleCardComponent, SourcesContentComponent],
  template: `
    <div class="flex">
      <app-bubble-card
        class="hidden w-full md:block"
        backgroundName="bg-sources-desktop-bubble"
      >
        <app-sources-content />
      </app-bubble-card>

      <app-bubble-card
        class="w-full md:hidden"
        backgroundName="bg-sources-bubble"
      >
        <app-sources-content />
      </app-bubble-card>
    </div>
  `,
})
export class NosSourcesComponent {}
