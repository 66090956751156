import { Component, Input } from '@angular/core';
import { GutupBubbleCardComponent } from '../../../components/gutup-bubbles/gutup-bubble-card.component';

@Component({
  selector: 'app-podcast-description',
  standalone: true,
  imports: [GutupBubbleCardComponent],
  template: `
    <div class="max-w-[1154px]">
      <app-bubble-card>
        <div [innerHTML]="description"></div>
      </app-bubble-card>
    </div>
  `,
})
export class PodcastDescriptionComponent {
  @Input({ required: true }) description!: string;
}
