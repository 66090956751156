import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AwsSesService {
  #http = inject(HttpClient);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async sendEmail(formValue: any) {
    await firstValueFrom(this.#http.post('/api/amazon-ses', formValue));
  }
}
