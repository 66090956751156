import { Component, input } from '@angular/core';
import { websitesSourceData } from './website-source.data';

export interface Website {
  title: string;
  url: string;
}

@Component({
  selector: 'app-website-source-list',
  standalone: true,
  template: `
    <div class="mb-8 space-y-4">
      <h3 class="mb-4 text-center font-vinasans text-3xl">Sites internet</h3>
      <div class="mb-8 space-y-4">
        @for (item of sourceItems(); track item.title) {
          <div class="mb-4 flex flex-col items-center text-center">
            {{ item.title }}
            <span
              >Site officiel :
              <span class="underline">{{ item.url }}</span></span
            >
          </div>
        }
      </div>
    </div>
  `,
})
export class WebsiteSourceListComponent {
  title = input<string>('');
  sourceItems = input<Website[]>([]);
  protected readonly bookSourceItems = websitesSourceData;
}
