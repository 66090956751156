import { Data, DataProvider } from './data-provider';
import data from '../../generated/db-data.json';

export class ProdDataProvider implements DataProvider {
  getData(): Promise<Data> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (data) {
          resolve(data as unknown as Data);
        } else {
          reject(new Error('Data is not available'));
        }
      }, 0);
    });
  }
}
