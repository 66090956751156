import { Component, input } from '@angular/core';

export interface SourceItem {
  itemTitle: string;
  author: string;
  reference: string;
}

@Component({
  selector: 'app-source-item',
  standalone: true,
  template: `
    <div class="mb-8 space-y-4">
      <h3 class="px-12 text-center font-vinasans text-2xl md:text-3xl">
        {{ title() }}
      </h3>
      @for (item of sourceItems(); track item.itemTitle) {
        <div
          class="mb-4 flex flex-col items-center text-center text-xs md:px-12 md:text-base"
        >
          <span class="px-12">{{ item.itemTitle }}</span>
          <span>{{ item.author }}</span>
          <span>{{ item.reference }}</span>
        </div>
      }
    </div>
  `,
})
export class SourceItemComponent {
  title = input<string>('');
  sourceItems = input<SourceItem[]>([]);
}
