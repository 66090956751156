import { Component, input } from '@angular/core';
import { studiesSourceData } from './studies-source.data';

export interface Study {
  title: string;
  publisher: string;
}

@Component({
  selector: 'app-studies-source-list',
  standalone: true,
  template: `
    <div class="mb-8 space-y-4">
      <h3 class="mb-4 text-center font-vinasans text-3xl">
        Etudes scientifiques set rapports
      </h3>
      <div class="mb-8 space-y-4">
        @for (item of sourceItems(); track item.title) {
          <div class="mb-4 flex flex-col items-center px-12 text-center">
            {{ item.title }}
            <span class="underline">{{ item.publisher }}</span>
          </div>
        }
      </div>
    </div>
  `,
})
export class StudiesSourceListComponent {
  title = input<string>('');
  sourceItems = input<Study[]>([]);
  protected readonly bookSourceItems = studiesSourceData;
}
