import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

interface NavigateOptions {
  scrollTop: boolean;
}

@Injectable({ providedIn: 'root' })
export class RoutingService {
  #router = inject(Router);

  async navigate(commands: any[], options: NavigateOptions) {
    await this.#router.navigate(commands);
    if (options.scrollTop) this.#scrollTop();
  }

  #scrollTop() {
    window.scrollTo(0, 0);
  }
}
