// ingredient.component.ts
import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { RecipeVm } from '../recipe/recipe-vm';
import { FoodResolveData } from './food-resolver';
import { FoodVm } from './food-vm';

@Component({
  standalone: true,
  imports: [CommonModule, RouterModule],
  template: `
    <h4 class="gutup-title">Aliment:</h4>
    <br />
    <h2>{{ ingredient.name }}</h2>
    <br />
    <h3 class="gutup-title">Recettes qui contiennent cette aliment:</h3>
    <br />
    @if (recipes.length > 0) {
      <ul>
        @for (recipe of recipes; track recipe.id) {
          <li>
            <a [routerLink]="['/recettes', recipe.id]">{{ recipe.title }}</a>
          </li>
        }
      </ul>
    } @else {
      <p>No recipes found using this ingredient.</p>
    }
  `,
})
export class FoodComponent {
  private route = inject(ActivatedRoute);

  ingredient: FoodVm;
  recipes: RecipeVm[];

  constructor() {
    const data = this.route.snapshot.data['data'] as FoodResolveData;
    this.ingredient = data.food;
    this.recipes = data.recipes;
  }
}
