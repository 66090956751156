import { Component, computed, input } from '@angular/core';
import { NgClass, NgOptimizedImage } from '@angular/common';

@Component({
  selector: 'app-bubble-card',
  standalone: true,
  imports: [NgClass, NgOptimizedImage],
  template: `
    <div class="relative mx-auto w-full">
      <div
        class="absolute inset-0 -z-10 bg-no-repeat"
        [ngClass]="backgroundName()"
      ></div>
      <div
        class="flex flex-col items-center space-y-12 px-6 pb-4 md:px-12 md:pb-12"
        [ngClass]="paddingClasses()"
      >
        <ng-content></ng-content>
      </div>
    </div>
  `,
})
export class GutupBubbleCardComponent {
  hasTitle = input<boolean>(false);
  backgroundName = input<string>('bg-bubble');

  paddingClasses = computed(() =>
    this.hasTitle() ? 'pt-6 md:pt-0' : 'pt-8 md:pt-12',
  );
}
