import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { DataService } from '../../services/data.service';
import { ProfilesResponse } from '@pocketbase-types';
import { GutupSimpleBubbleCardComponent } from '../../components/gutup-bubbles/gutup-simple-bubble-card.component';

@Component({
  selector: 'app-profiles',
  standalone: true,
  imports: [CommonModule, RouterModule, GutupSimpleBubbleCardComponent],
  template: `
    <div class="mx-[2%] md:mx-0">
      <app-gutup-simple-bubble-card>
        <div class="p-2 md:p-8">
          <h2 class="pb-12 text-center font-vinasans text-5xl">
            ANNUAIRE DES PRATICIENS
          </h2>

          <!-- profile-list.component.html -->
          <div class="container mx-auto px-4">
            <!-- Profile Grid -->
            <div
              class="grid grid-cols-1 gap-3 md:grid-cols-2 md:gap-1 lg:grid-cols-3"
            >
              @for (profile of paginatedProfiles; track profile.id) {
                <div class="md:p-2">
                  <div class="flex flex-col">
                    <a
                      (click)="goToProfile(profile)"
                      class="cursor-pointer underline"
                    >
                      {{ profile.first_name }} {{ profile.last_name }}
                    </a>

                    <a (click)="goToProfile(profile)" class="cursor-pointer">
                      {{ profile.title }}
                    </a>

                    @if (profile.address_text) {
                      <a (click)="goToProfile(profile)" class="cursor-pointer">
                        {{ profile.address_text }}
                      </a>
                    }
                  </div>
                </div>
              }
            </div>

            <!-- Pagination -->
            @if (totalPages > 1) {
              <div
                class="mt-8 flex flex-wrap items-center justify-center space-x-2"
              >
                <!-- Page numbers -->
                @for (page of [].constructor(totalPages); track $index) {
                  <button
                    (click)="goToPage($index + 1)"
                    class="rounded-lg px-2"
                    [class.bg-gutup-dark]="currentPage === $index + 1"
                    [class.text-white]="currentPage === $index + 1"
                  >
                    {{ $index + 1 }}
                  </button>
                }
              </div>
            }
          </div>
        </div>
      </app-gutup-simple-bubble-card>
    </div>
  `,
})
export class ProfileListComponent {
  readonly #router = inject(Router);
  profiles = inject(DataService).getProfiles();

  private readonly ITEMS_PER_PAGE = 16;
  currentPage = 1;

  get totalPages(): number {
    return Math.ceil(this.profiles.length / this.ITEMS_PER_PAGE);
  }

  get paginatedProfiles(): ProfilesResponse[] {
    const startIndex = (this.currentPage - 1) * this.ITEMS_PER_PAGE;
    return this.profiles.slice(startIndex, startIndex + this.ITEMS_PER_PAGE);
  }

  goToPage(page: number): void {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
    }
  }

  goToProfile(profile: ProfilesResponse) {
    this.#router.navigate(['/profiles', this.#createSlug(profile)]);
  }

  #createSlug(profile: ProfilesResponse) {
    // Utility function to sanitize slugs
    function sanitizeSlug(input: string): string {
      return input
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, '-') // Replace non-alphanumeric characters with hyphens
        .replace(/^-+|-+$/g, ''); // Trim leading and trailing hyphens
    }

    return `${sanitizeSlug(profile.first_name)}-${sanitizeSlug(profile.last_name)}-${profile.id}`;
  }
}
