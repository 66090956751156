import { Component, Input } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { PodcastDetail } from './podcast.detail';

@Component({
  selector: 'app-podcast-header',
  standalone: true,
  imports: [NgOptimizedImage],
  styles: [
    `
      .gutup-podcast-title {
        @apply break-keep font-vinasans text-3xl md:-mr-[9vh] md:w-[calc(100%+9vh)] md:text-5xl;
      }

      .gutup-podcast-subtitle {
        @apply font-cupcakes text-2xl md:text-3xl;
      }
    `,
  ],
  template: `
    <div class="flex space-y-12 md:flex-row md:space-y-0">
      <div class="md:w-1/2 md:min-w-[400px]">
        <div class="hidden justify-center md:flex">
          <img
            [ngSrc]="podcastDetail.headerImageUrl"
            alt="podcast header"
            width="400"
            height="400"
          />
        </div>
      </div>

      <div class="content-center space-y-4 text-left md:w-1/2">
        <div
          class="gutup-podcast-title relative flex flex-col flex-wrap items-start"
        >
          <span [innerHTML]="podcastDetail.title"></span>
        </div>
        <div class="gutup-podcast-subtitle">
          {{ podcastDetail.subtitle }}
        </div>
        <div class="flex justify-center md:hidden">
          <img [src]="podcastDetail.headerImageUrl" alt="podcast header" />
        </div>
      </div>
    </div>
  `,
})
export class PodcastHeaderComponent {
  @Input({ required: true }) podcastDetail!: PodcastDetail;
}
