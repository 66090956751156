import {
  ChangeDetectorRef,
  Component,
  inject,
  NgZone,
  OnInit,
} from '@angular/core';
import { HomeAboutComponent } from './home-about/home-about.component';
import { HomePodcastComponent } from './home-podcast/home-podcast.component';
import { GutupFoodieComponent } from '../../components/gutup-foodie/gutup-foodie.component';
import { GutupFooterComponent } from '../../components/gutup-footer/gutup-footer.component';
import { GutupBannerComponent } from '../../components/gutup-banner/gutup-banner.component';
import { GutupBubbleCardComponent } from '../../components/gutup-bubbles/gutup-bubble-card.component';
import FreeResourceFormComponent from '../../components/free-resource-form.component';
import { GutupEliseAboutComponent } from '../../components/gutup-elise-about/gutup-elise-about.component';
import ProPodcastHubspotFormComponent from '../../components/pro-podcast-hubspot-form.component';
import NewsletterFormComponent from '../../components/newsletter-form.component';
import { EbookModalComponent } from '../../components/gutup-ebook-modal';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    HomeAboutComponent,
    HomePodcastComponent,
    GutupFoodieComponent,
    GutupFooterComponent,
    GutupBannerComponent,
    GutupBubbleCardComponent,
    FreeResourceFormComponent,
    GutupEliseAboutComponent,
    ProPodcastHubspotFormComponent,
    NewsletterFormComponent,
    EbookModalComponent,
  ],
  template: `
    <div
      class="gutup-container flex flex-col items-center justify-center space-y-14 md:space-y-24"
    >
      <div class="relative">
        <img
          class="absolute left-2 top-1/2 h-1/4 -translate-y-1/2 transform md:-left-1 md:h-40"
          src="/gutup/home/soleil.png"
          alt="soleil"
        />
        @for (image of heroImages; track image; let i = $index) {
          <img
            class="md:min-w-[600px] md:max-w-[600px]"
            [src]="image"
            [alt]="'gutup ' + i"
            [class.hidden]="i !== currentIndex"
          />
        }
      </div>
      <app-home-about class="w-full" />
      <div class="w-full">
        <app-bubble-card
          class="hidden md:block"
          [hasTitle]="true"
          [backgroundName]="'bg-green-bubble-desktop'"
        >
          <app-free-resource-hubspot-form class="w-full" />
        </app-bubble-card>

        <app-bubble-card
          class="md:hidden"
          [hasTitle]="true"
          [backgroundName]="'bg-green-bubble'"
        >
          <app-free-resource-hubspot-form class="w-full" />
        </app-bubble-card>
      </div>
      <app-gutup-elise-about class="!mt-0 md:!mt-12" />
      <app-home-podcast class="w-full" />
      <app-gutup-banner />
    </div>
  `,
})
export class HomeComponent implements OnInit {
  heroImages = [
    'gutup/home-hero/1.svg',
    'gutup/home-hero/2.svg',
    'gutup/home-hero/3.svg',
  ];
  currentIndex = 0;
  #ngZone = inject(NgZone);
  #cd = inject(ChangeDetectorRef);

  ngOnInit() {
    this.#ngZone.runOutsideAngular(() => {
      setInterval(() => {
        this.currentIndex = (this.currentIndex + 1) % this.heroImages.length;
        this.#ngZone.run(() => {
          this.#cd.markForCheck(); // Manually trigger change detection
        });
      }, 2000);
    });
  }
}
