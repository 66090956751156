import { Component, input } from '@angular/core';
import { NgClass } from '@angular/common';
import { GutupSimpleBubbleCardComponent } from '../../components/gutup-bubbles/gutup-simple-bubble-card.component';

@Component({
  selector: 'app-profile-faq-bubble-card',
  standalone: true,
  imports: [NgClass, GutupSimpleBubbleCardComponent],
  template: `
    <div>
      <app-gutup-simple-bubble-card>
        <h1
          class="-mt-10 text-center font-cupcakes text-4xl md:ml-12 md:self-start md:text-4xl"
        >
          {{ title() }}
        </h1>
        <div class="flex items-center space-x-4 py-2 md:pl-12">
          <ng-content></ng-content>
        </div>
      </app-gutup-simple-bubble-card>
    </div>
  `,
})
export class ProfileFaqBubbleCardComponent {
  title = input.required<string>();
}
