import { Component, input } from '@angular/core';
import { NgClass, NgOptimizedImage } from '@angular/common';

@Component({
  selector: 'app-gutup-simple-bubble-card',
  standalone: true,
  imports: [NgClass, NgOptimizedImage],
  template: `
    <div class="relative">
      <div
        class="absolute inset-0 -z-10 bg-bubble bg-no-repeat"
        [ngClass]="backgroundName()"
      ></div>
      <div class="flex flex-col p-4">
        <ng-content></ng-content>
      </div>
    </div>
  `,
})
export class GutupSimpleBubbleCardComponent {
  backgroundName = input<string>('bg-bubble');
}
