import { Component } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { GutupSimpleBubbleCardComponent } from '../../../components/gutup-bubbles/gutup-simple-bubble-card.component';

@Component({
  selector: 'app-logo-explaination',
  standalone: true,
  imports: [NgOptimizedImage, GutupSimpleBubbleCardComponent],
  template: `
    <div class="flex flex-col items-center space-y-24 px-[10vw]">
      <img
        class="md:w-1/2"
        alt="Logo gutup avec des explications de la signification"
        ngSrc="/gutup/mission/explaination.png"
        height="710"
        width="1816"
      />

      <app-gutup-simple-bubble-card class="md:self-end md:pr-40">
        <div class="flex items-center space-x-4 px-8 md:p-4 md:px-8">
          <div>
            <span>On va littéralement mettre tes intestins au top niveau!</span>
          </div>
          <img
            class="absolute -bottom-24 -right-10 w-40 md:-right-28 md:bottom-2"
            alt="Main qui tient un drapeau Suisse"
            ngSrc="/gutup/mission/mission-elise.png"
            height="779"
            width="540"
          />
        </div>
      </app-gutup-simple-bubble-card>
    </div>
  `,
})
export class LogoExplainationComponent {}
