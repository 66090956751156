import { ResolveFn } from '@angular/router';
import { inject } from '@angular/core';
import { DataService } from '../../services/data.service';
import { ProfilesResponse } from '@pocketbase-types';

export const profileResolver: ResolveFn<ProfilesResponse | undefined> = (
  route,
) => {
  const slug = route.paramMap.get('id')!;
  // Extract ID from the slug (assuming it ends with `-id`)
  const id = slug.split('-').pop(); // Gets the last part of the slug

  return inject(DataService)
    .getProfiles()
    .find((i) => i.id === id);
};
