import { Component } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { GutupSimpleBubbleCardComponent } from '../../../components/gutup-bubbles/gutup-simple-bubble-card.component';

@Component({
  selector: 'app-hippocrate-bubble',
  standalone: true,
  imports: [NgOptimizedImage, GutupSimpleBubbleCardComponent],
  template: `
    <div class="flex">
      <app-gutup-simple-bubble-card>
        <div
          class="flex items-center space-x-2 py-4 md:space-x-4 md:px-6 md:py-2"
        >
          <img
            class="mb-4 hidden w-28 md:block"
            alt="Etoile filante décorative"
            ngSrc="/gutup/home/gutup-starfall.png"
            height="448"
            width="377"
          />
          <div>
            <div class="flex space-x-2">
              <img
                class="mb-4 w-10 md:hidden"
                alt="Etoile filante décorative"
                ngSrc="/gutup/home/gutup-starfall.png"
                height="448"
                width="377"
              />
              <div>
                <span class="font-moresugar text-base md:text-2xl"
                  >"Toute maladie commence dans l'intestin."</span
                >
                <br />
                <span class="font-cupcakes text-base md:text-3xl"
                  >Hippocrate, pere de la medecine</span
                >
              </div>
            </div>
            <div class="px-6">
              <span>
                C'était il y a 2000 ans...
                <br />
                Aujourd'hui, les dernières études médicales l'attestent!
              </span>
            </div>
          </div>
        </div>
      </app-gutup-simple-bubble-card>
    </div>
  `,
})
export class HippocrateBubbleComponent {}
