import { inject, Injectable } from '@angular/core';
import { RecipeVm } from './recipe-vm';
import { DataService } from '../../services/data.service';
import { AlimentsResponse, RecettesResponse } from '@pocketbase-types';

@Injectable({
  providedIn: 'root',
})
export class RecipeService {
  #dataService = inject(DataService);

  getRecipe(recipeId: string): RecipeVm {
    const recipes = this.#dataService.getRecipes();
    const recipe = recipes.find((r) => r.id === recipeId);
    if (!recipe) {
      throw new Error(`Recipe with id ${recipeId} not found`);
    }
    return this.#mapToRecipeDto(recipe);
  }

  async getAllRecipesForIngredient(ingredientId: string): Promise<RecipeVm[]> {
    const recipes = this.#dataService.getRecipes();
    const filteredRecipes = recipes.filter((recipe) =>
      recipe.ingredients.includes(ingredientId),
    );
    return Promise.all(
      filteredRecipes.map((recipe) => this.#mapToRecipeDto(recipe)),
    );
  }

  #mapToRecipeDto(recipe: RecettesResponse): RecipeVm {
    const ingredients = this.#mapFoods(recipe.ingredients);
    return {
      id: recipe.id,
      title: recipe.title,
      foods: ingredients,
    };
  }

  #mapFoods(ingredientIds: string[]): { id: string; name: string }[] {
    const ingredientsMap = this.#createIngredientsMap();
    return ingredientIds.map((ingId) => {
      const ingredient = ingredientsMap.get(ingId);
      return ingredient
        ? { id: ingredient.id, name: ingredient.name }
        : { id: ingId, name: 'Unknown Ingredient' };
    });
  }

  #createIngredientsMap(): Map<string, AlimentsResponse> {
    const ingredients = this.#dataService.getFoods();
    return new Map(ingredients.map((ing) => [ing.id, ing]));
  }
}
