import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { SocialIconComponent } from '../social-icon/social-icon.component';

@Component({
  selector: 'app-gutup-header-desktop',
  standalone: true,
  imports: [RouterLink, SocialIconComponent],
  template: `
    <!-- Desktop -->
    <header class="hidden h-40 bg-header bg-contain bg-no-repeat md:block">
      <div class="flex justify-between pt-4 md:mx-[10vh]">
        <div routerLink="/">
          <img
            src="/gutup/logo.svg"
            alt="Logo GutUp"
            class="w-32 cursor-pointer"
          />
        </div>
        <div class="flex items-center space-x-5 text-white">
          <div routerLink="/mission" class="gutup-header-button">MISSION</div>
          <div routerLink="/podcast" class="gutup-header-button">PODCAST</div>
          <div routerLink="/contact" class="gutup-header-button">CONTACT</div>

          <app-social-icon
            [src]="'/social/insta.svg'"
            [link]="'https://www.instagram.com/gutup_foodie/'"
          />
          <app-social-icon
            [src]="'/social/youtube.svg'"
            [link]="'https://www.youtube.com/@gutup_foodie'"
          />
          <app-social-icon
            [src]="'/social/lkdn.svg'"
            [link]="'https://www.linkedin.com/company/gutup/'"
          />
          <app-social-icon
            [src]="'/social/spotify.svg'"
            [link]="'https://open.spotify.com/show/3oLhLQFNBUadCvciSze0PZ'"
          />
          <!--          <app-social-icon-->
          <!--            [src]="'/social/tiktok.svg'"-->
          <!--            [link]="'https://www.tiktok.com/@gutup_foodie'"-->
          <!--          />-->
        </div>
      </div>
    </header>
  `,
})
export class GutupHeaderDesktopComponent {}
