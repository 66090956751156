import { Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { RoutingService } from '../../../services/routing.service';
import { GutupPodcastListComponent } from '../../podcast/gutup-podcast-list/gutup-podcast-list.component';
import { NgOptimizedImage } from '@angular/common';

@Component({
  selector: 'app-home-podcast',
  standalone: true,
  imports: [RouterLink, GutupPodcastListComponent, NgOptimizedImage],
  template: `
    <div class="relative flex w-full flex-col items-center">
      <img
        ngSrc="/gutup/podcast/podcast-about.png"
        alt="Photo elise avec un micro podcast"
        class="md:hidden"
        height="2215"
        width="1896"
      />

      <!-- Title and Button Area -->
      <div class="flex flex-col space-y-6">
        <div class="flex">
          <div class="">
            <!-- Title -->
            <div class="gutup-title relative flex flex-col items-center">
              <div class="text-6xl md:text-8xl">LE PODCAST</div>
              <div class="flex text-4xl md:text-6xl">qui te veut du bien.</div>
            </div>

            <div class="hidden md:flex md:justify-end md:pt-2">
              <button (click)="navigateToPodcast()" class="gutup-button">
                Découvre tous les épisodes
              </button>
            </div>
          </div>

          <!-- Image -->
          <div class="hidden content-start md:block">
            <img
              ngSrc="/gutup/podcast/podcast-about.png"
              alt="Photo elise avec un micro podcast"
              class="max-w-[300px]"
              height="2215"
              width="1896"
            />
          </div>
        </div>
      </div>

      <div class="z-10 w-full pt-4 md:hidden">
        <button (click)="navigateToPodcast()" class="gutup-button w-full">
          Découvre tous les épisodes
        </button>
      </div>

      <app-gutup-podcast-list />
    </div>
  `,
})
export class HomePodcastComponent {
  #routingService = inject(RoutingService);

  navigateToPodcast() {
    this.#routingService.navigate(['/podcast'], { scrollTop: true });
  }
}
