import { Component, OnInit } from '@angular/core';
import { GutupBannerComponent } from '../../components/gutup-banner/gutup-banner.component';
import { GutupPodcastAboutComponent } from './gutup-podcast-about/gutup-podcast-about.component';
import { GutupPodcastListComponent } from './gutup-podcast-list/gutup-podcast-list.component';
import { GutupBubbleCardComponent } from '../../components/gutup-bubbles/gutup-bubble-card.component';
import ProPodcastHubspotFormComponent from '../../components/pro-podcast-hubspot-form.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  standalone: true,
  imports: [
    GutupBannerComponent,
    GutupPodcastAboutComponent,
    GutupPodcastListComponent,
    GutupBubbleCardComponent,
    ProPodcastHubspotFormComponent,
  ],
  template: `
    <div class="md:space-y-12">
      <div>
        <app-gutup-podcast-about />
      </div>

      <div>
        <div class="flex w-full flex-col items-center space-y-24">
          <div class="-ml-24 hidden md:block">
            <img src="/gutup/podcast/title-2-desktop.svg" alt="C'est parti" />
          </div>

          <div>
            <div class="md:hidden">
              <img src="/gutup/podcast/title-2.svg" alt="C'est parti" />
            </div>
            <div>
              <app-gutup-podcast-list />
            </div>
          </div>

          <!-- Hubspot form -->
          <div class="py-4 pb-12">
            <div class="hidden md:block">
              <app-bubble-card
                [hasTitle]="true"
                [backgroundName]="'bg-orange-bubble-desktop'"
              >
                <app-pro-podcast-hubspot-form class="w-full" />
              </app-bubble-card>
            </div>
            <div class="md:hidden">
              <app-bubble-card
                [hasTitle]="true"
                [backgroundName]="'bg-orange-bubble'"
              >
                <app-pro-podcast-hubspot-form class="w-full" />
              </app-bubble-card>
            </div>
          </div>
        </div>
      </div>

      <div class="pt-6 md:pt-0">
        <app-gutup-banner />
      </div>
    </div>
  `,
  styleUrl: './podcast.component.scss',
})
export class PodcastComponent implements OnInit {
  constructor(
    private titleService: Title,
    private metaService: Meta,
  ) {}

  ngOnInit() {
    this.titleService.setTitle('Podcast');
    this.metaService.updateTag({
      name: 'description',
      content: `Le podcast qui te veut du bien`,
    });
  }
}
