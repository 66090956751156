import { Component, computed, inject, input } from '@angular/core';
import FaqItemComponent from './faq-item.component';
import { ProfileFaqBubbleCardComponent } from './profile-faq-bubble-card.component';
import { ProfilesResponse } from '@pocketbase-types';
import { DataService } from '../../services/data.service';

type LanguageMapping = Record<string, string>;

@Component({
  selector: 'app-profile-faq',
  standalone: true,
  template: `
    <div>
      <app-profile-faq-bubble-card title="Questions frequentes">
        <div class="flex flex-col space-y-3 py-3">
          @if (profile().address_text) {
            <app-faq-item
              [question]="addressFaq().question"
              [reponse]="addressFaq().reponse"
            ></app-faq-item>
          }

          @if (profile().languages && profile().languages.length > 0) {
            <app-faq-item
              [question]="languagesFaq().question"
              [reponse]="languagesFaq().reponse"
            />
          }

          @if (profile().website) {
            <app-faq-item
              [question]="websiteFaq().question"
              [reponse]="websiteFaq().reponse"
            />
          }

          @if (profile().phone_number) {
            <app-faq-item
              [question]="phoneFaq().question"
              [reponse]="phoneFaq().reponse"
            />
          }
        </div>
      </app-profile-faq-bubble-card>
    </div>
  `,
  imports: [FaqItemComponent, ProfileFaqBubbleCardComponent],
})
export default class ProfileFaqComponent {
  private readonly languages = inject(DataService).getLanguages();

  profile = input.required<ProfilesResponse>();
  fullName = computed(
    () => `${this.profile().first_name} ${this.profile().last_name}`,
  );

  addressFaq = computed(() => {
    return {
      question: `Quelle est l'adresse de ${this.fullName()}?`,
      reponse: `${this.fullName()} reçoit des patients à ${this.profile().address_text}.`,
    };
  });

  languagesFaq = computed(() => {
    const languageMapping: LanguageMapping = this.languages.reduce(
      (acc: LanguageMapping, lang) => {
        acc[lang.id] = lang.language;
        return acc;
      },
      {},
    );

    const languageNames = this.profile()
      .languages.map((id: string) => languageMapping[id])
      .filter((name: string) => name !== undefined);

    return {
      question: `Quelles sont les langues parlées par ${this.fullName()}?`,
      reponse: `Les langues parlées par ${this.fullName()} sont: ${formatLanguageList(languageNames)}.`,
    };
  });

  websiteFaq = computed(() => {
    return {
      question: `Quel est le site web de ${this.fullName()}?`,
      reponse: `Le site web de ${this.fullName()} est ${this.profile().website}`,
    };
  });

  phoneFaq = computed(() => {
    return {
      question: `Quel est le numéro de téléphone de ${this.fullName()}?`,
      reponse: `Le numéro de téléphone de ${this.fullName()} est ${this.profile().phone_number}.`,
    };
  });
}

function formatLanguageList(languages: string[]): string {
  if (languages.length === 1) {
    return languages[0];
  }
  if (languages.length === 2) {
    return `${languages[0]} et ${languages[1]}`;
  }
  const lastLanguage = languages.pop();
  return `${languages.join(', ')} et ${lastLanguage}`;
}
