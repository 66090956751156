import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DataService } from '../../services/data.service';

@Component({
  standalone: true,
  selector: 'app-recipe-list',
  imports: [CommonModule, RouterModule],
  template: `
    <h2 class="gutup-title">Recettes</h2>
    <br />
    <ul>
      @for (recipe of recipes; track recipe.id) {
        <li>
          <a [routerLink]="['/recettes', recipe.id]">{{ recipe.title }}</a>
        </li>
      }
    </ul>
  `,
})
export class RecipeListComponent {
  recipes = inject(DataService).getRecipes();
}
