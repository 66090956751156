import { Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { RoutingService } from '../../../services/routing.service';
import { NgOptimizedImage } from '@angular/common';

@Component({
  selector: 'app-gutup-podcast-list',
  standalone: true,
  imports: [RouterLink, NgOptimizedImage],
  template: `
    <div class="flex space-x-4 overflow-x-auto py-4 max-md:w-[100vw]">
      @for (podcast of podcasts; track podcast.id) {
        <div class="flex-shrink-0">
          <img
            [ngSrc]="podcast.imageSrc"
            [alt]="podcast.alt"
            (click)="navigateToPodcastId(podcast.id)"
            class="cursor-pointer"
            width="300"
            height="300"
          />
        </div>
      }
    </div>
  `,
})
export class GutupPodcastListComponent {
  #routingService = inject(RoutingService);

  podcasts = [
    {
      id: '1',
      imageSrc: '/gutup/podcast/podcast-list/podcast-elise-miniature.png',
      alt: 'Podcast disponible bientôt',
    },
    {
      id: '2',
      imageSrc: '/gutup/podcast/podcast-list/podcast-deb-miniature.png',
      alt: "Podcast sur l'alimentation anti-inflammatoire",
    },
    {
      id: '3',
      imageSrc: '/gutup/podcast/podcast-list/podcast-melanie-miniature.png',
      alt: 'Microbiote Revolution',
    },
    // {
    //   id: '4',
    //   imageSrc: '/gutup/home-podcast/bubble-soon.png',
    //   alt: 'Podcast disponible bientôt',
    // },
  ];

  navigateToPodcastId(id: string) {
    this.#routingService.navigate([`/podcast/${id}`], { scrollTop: true });
  }
}
