import {
  ChangeDetectorRef,
  Component,
  inject,
  NgZone,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'app-gutup-banner',
  standalone: true,
  imports: [],
  template: `
    <div class="hidden justify-center md:flex">
      @for (image of bandImages; track image; let i = $index) {
        <img
          [src]="image"
          [alt]="'gutup ' + i"
          [class.hidden]="i !== currentIndex"
        />
      }
    </div>

    <div class="gutup-container md:hidden">
      @for (image of bandMobileImages; track image; let i = $index) {
        <img
          [src]="image"
          [alt]="'gutup ' + i"
          [class.hidden]="i !== currentIndex"
        />
      }
    </div>
  `,
})
export class GutupBannerComponent implements OnInit {
  bandImages = [
    'gutup/home/band-1.svg',
    'gutup/home/band-2.svg',
    'gutup/home/band-3.svg',
  ];
  bandMobileImages = [
    'gutup/home/band-1-mobile.svg',
    'gutup/home/band-2-mobile.svg',
    'gutup/home/band-3-mobile.svg',
  ];
  currentIndex = 0;
  #ngZone = inject(NgZone);
  #cd = inject(ChangeDetectorRef);

  ngOnInit() {
    this.#ngZone.runOutsideAngular(() => {
      setInterval(() => {
        this.currentIndex = (this.currentIndex + 1) % this.bandImages.length;
        this.#ngZone.run(() => {
          this.#cd.markForCheck(); // Manually trigger change detection
        });
      }, 2000);
    });
  }
}
