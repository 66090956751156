import { Component, Input } from '@angular/core';
import { SourceItemComponent } from './source-item.component';
import { sourceData } from './source.data';
import { bookData } from './book-source.data';
import { WebsiteSourceListComponent } from './website-source-item.component';
import { websitesSourceData } from './website-source.data';
import { studiesSourceData } from './studies-source.data';
import { StudiesSourceListComponent } from './studies-source-item.component';
import { BookSourceListComponent } from './book-source-item.component';

@Component({
  selector: 'app-sources-content',
  standalone: true,
  imports: [
    SourceItemComponent,
    BookSourceListComponent,
    WebsiteSourceListComponent,
    StudiesSourceListComponent,
  ],
  template: `
    <div class="flex flex-col items-center space-y-12 pb-4 md:px-12 md:pb-12">
      <h2 class="px-16 pt-6 text-center font-vinasans text-5xl md:px-0 md:pt-0">
        CE QUE DIT LA SCIENCE
      </h2>
      <div class="px-10 text-center font-cupcakes text-xl">
        PubMed (National Library of Medicine)
        <br />
        Site officiel: https://pubmed.ncbi.nlm.nih.gov
      </div>

      @for (section of sourceSections; track section.title) {
        <app-source-item
          [title]="section.title"
          [sourceItems]="section.items"
        ></app-source-item>
      }
      <h2 class="px-6 font-vinasans text-5xl">LES AUTRES SOURCES</h2>

      <app-book-source-list [sourceItems]="bookSourceItems" />
      <app-website-source-list [sourceItems]="websitesData" />
      <app-studies-source-list [sourceItems]="studiesSourceData" />
    </div>
  `,
})
export class SourcesContentComponent {
  @Input() showWebsites = true;
  sourceSections = sourceData;
  bookSourceItems = bookData;
  protected readonly websitesData = websitesSourceData;
  protected readonly studiesSourceData = studiesSourceData;
}
