import { Website } from './website-source-item.component';

export const websitesSourceData: Website[] = [
  {
    title:
      "ANSES (Agence nationale de sécurité sanitaire de l'alimentation, de l'environnement et du travail)",
    url: 'https://www.anses.fr',
  },
  {
    title: 'Passeport Santé',
    url: 'https://www.passeportsante.net',
  },
  {
    title: 'La Fondation PiLeJe',
    url: 'https://www.pileje.fr',
  },
  {
    title: 'INRAE (Institut National de la Recherche Agronomique)',
    url: 'https://www.inrae.fr',
  },
  {
    title: 'Harvard T.H. Chan School of Public Health - The Nutrition Source',
    url: 'https://www.hsph.harvard.edu/nutritionsource',
  },
  {
    title: 'National Institutes of Health (NIH)',
    url: 'https://www.nih.gov',
  },
  {
    title: 'PubMed (National Library of Medicine)',
    url: 'https://pubmed.ncbi.nlm.nih.gov',
  },
  {
    title: 'La Nutrition.fr',
    url: 'https://www.lanutrition.fr',
  },
  {
    title: 'Futura Santé',
    url: 'https://www.futura-sciences.com/sante',
  },
  {
    title: 'Vigie-Nutrition (AFDN)',
    url: 'https://www.afdn.org',
  },
  {
    title: 'Santé Canada - Guide Alimentaire',
    url: 'https://www.canada.ca/fr/services/sante/alimentation',
  },
  {
    title: 'European Food Safety Authority (EFSA)',
    url: 'https://www.efsa.europa.eu/fr',
  },
];
