import { Component, Output, EventEmitter } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { GutupPodcastListComponent } from '../gutup-podcast-list/gutup-podcast-list.component';

@Component({
  selector: 'app-podcast-list',
  standalone: true,
  imports: [NgOptimizedImage, GutupPodcastListComponent],
  template: `
    <div class="flex flex-col items-center justify-center pt-12">
      <div class="flex justify-center pb-2">
        <img
          class="hidden md:block"
          src="/gutup/podcast/detail/podcast-detail-title3-desktop.svg"
          alt="gutup 1"
        />
        <img
          class="md:hidden"
          src="/gutup/podcast/detail/podcast-detail-title3.svg"
          alt="gutup 1"
        />
      </div>
      <div class="w-54 text-center">
        <button (click)="onNavigateToPodcast()" class="gutup-button md:w-54">
          Découvre tous les épisodes
        </button>
      </div>
      <div class="flex items-center">
        <app-gutup-podcast-list />
      </div>
    </div>
  `,
})
export class PodcastListComponent {
  @Output() navigateToPodcast = new EventEmitter<void>();

  onNavigateToPodcast() {
    this.navigateToPodcast.emit();
  }
}
