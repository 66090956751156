import { Study } from './studies-source-item.component';

export const studiesSourceData: Study[] = [
  {
    title: 'The Gut-Brain Axis: The Missing Link',
    publisher: 'Science Direct',
  },
  {
    title: 'Role of Antioxidants in Disease Prevention',
    publisher: 'The Journal of Nutritional Biochemistry',
  },
  {
    title: 'Nutritional Modulation of Immune Function: A Critical Appraisal',
    publisher: 'The American Journal of Clinical Nutrition',
  },
  {
    title: 'Dietary Protein Intake and Human Health',
    publisher: 'Advances in Nutrition',
  },
  {
    title:
      'The Role of Omega-3 Fatty Acids in Preventing Inflammation and Chronic Disease',
    publisher: 'The American Heart Association',
  },
  {
    title: 'The Role of Probiotics in Gastrointestinal Health and Disease',
    publisher: 'Gastroenterology & Hepatology',
  },
  {
    title: 'Gut Microbiota in Health and Disease',
    publisher: 'Nature Reviews Gastroenterology & Hepatology',
  },
  {
    title: 'The Role of Nutrition in Immune Function',
    publisher: 'British Journal of Nutrition',
  },
  {
    title: 'Dietary Fibre and the Gut Microbiome',
    publisher: 'The Lancet Gastroenterology & Hepatology',
  },
  {
    title: 'Omega-3 Fatty Acids in Inflammation and Autoimmune Diseases',
    publisher: 'The American Journal of Clinical Nutrition',
  },
  {
    title: 'Effects of Glutamine on the Immune System',
    publisher: 'Journal of Parenteral and Enteral Nutrition (JPEN)',
  },
  {
    title: 'Vitamin D and Immune Function',
    publisher: 'The Journal of Investigative Medicine',
  },
];
