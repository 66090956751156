import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { RecipeVm } from '../recipe/recipe-vm';
import { FoodService } from './food.service';
import { FoodVm } from './food-vm';
import { RecipeService } from '../recipe/recipe.service';

export interface FoodResolveData {
  food: FoodVm;
  recipes: RecipeVm[];
}

export const foodResolver: ResolveFn<FoodResolveData> = async (route) => {
  const foodService = inject(FoodService);
  const recipeService = inject(RecipeService);
  const id = route.paramMap.get('id')!;

  const food = foodService.getFoods(id);
  if (!food) {
    throw new Error('Ingredient not found');
  }

  const recipes = (await recipeService.getAllRecipesForIngredient(id)) || [];

  return { food, recipes };
};
