import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-page-not-found',
  standalone: true,
  imports: [RouterLink],
  template: `
    <!-- Mobile version -->
    <div
      class="flex flex-col items-center space-y-12 bg-contact-desktop bg-center bg-no-repeat pb-52 md:hidden"
    >
      <div class="gutup-container flex flex-col items-center">
        <div
          class="flex w-full flex-col items-center justify-center space-y-12 pt-36"
        >
          <img src="/gutup/404.svg" class="h-96" alt="404" />
          <span class="gutup-title uppercase"
            >Il semblerait que cette page soit introuvable</span
          >
          <button class="gutup-button" routerLink="/">
            Revenir à la page d'accueil
          </button>
        </div>
      </div>
    </div>

    <!-- Desktop version -->
    <div
      class="relative hidden h-screen max-h-[70vh] items-center justify-center md:flex"
    >
      <img
        src="/gutup/contact-bg-desktop.svg"
        alt="Background"
        class="absolute h-full w-full object-contain"
      />

      <div
        class="relative z-10 flex w-full flex-col items-center space-y-8 pr-[5%]"
      >
        <img src="/gutup/404.svg" alt="404" class="" />
        <span class="font-vinasans text-3xl uppercase"
          >Il semblerait que cette page soit introuvable</span
        >
        <button class="gutup-button" routerLink="/">
          Revenir à la page d'accueil
        </button>
      </div>
    </div>
  `,
})
export class PageNotFoundComponent {}
