{
  "aliments": [
    {
      "bienfaits": "<p>L'airelle rouge est un concentr&eacute; de bienfaits : elle est riche en antioxydants, comme les flavono&iuml;des et la vitamine C, qui boostent ton syst&egrave;me immunitaire. Elle aide aussi &agrave; pr&eacute;venir les infections urinaires, &agrave; r&eacute;duire le cholest&eacute;rol et &agrave; soutenir la sant&eacute; cardiovasculaire.</p>",
      "collectionId": "wir52u8tj9y3rnj",
      "collectionName": "aliments",
      "comment_cuisiner": "<p>Pour maximiser les bienfaits, consomme les airelles rouges crues, en salade, ou mix&eacute;es en smoothie. Si tu veux les cuire, fais-le &agrave; feu doux et rapidement pour conserver un maximum de vitamines et d'antioxydants.</p>",
      "created": "2024-08-21 15:12:05.832Z",
      "disponibilite": "<p>Les airelles rouges sont disponibles de septembre &agrave; novembre en Suisse. Toutefois, tu peux les trouver surgel&eacute;es ou en conserve tout au long de l'ann&eacute;e.</p>",
      "fait_nutritionnel": "<p>Les airelles peuvent am&eacute;liorer la sant&eacute; vasculaire gr&acirc;ce &agrave; leur haute teneur en polyph&eacute;nols. Elles ont aussi des effets positifs sur la gestion du cholest&eacute;rol.</p>",
      "id": "hghermmnx7ljhsy",
      "intro": "Découvre tous les secrets de l'airelle rouge, de ses bienfaits à ses méthodes de consommation pour optimiser ta santé avec des conseils pratiques et des faits scientifiques.",
      "name": "L'airelle rouge",
      "picture": "",
      "recipes_ideas": [],
      "selection": "<p>Choisis des airelles rouges bien fermes, d'un rouge intense et brillant, sans t&acirc;ches ni signes de moisissure. La fra&icirc;cheur se v&eacute;rifie aussi par leur texture, qui doit &ecirc;tre l&eacute;g&egrave;rement &eacute;lastique.</p>",
      "sources": "<p>- <a target=\"_new\" rel=\"noopener\">Sant&eacute; et Nutrition - CHUV Lausanne</a> <br>- <em>Journal of Nutrition</em> <a target=\"_new\" rel=\"noopener\">lien</a> <br>- <a target=\"_new\" rel=\"noopener\">Swiss Food Research</a></p>",
      "updated": "2024-08-21 15:12:05.832Z",
      "visible": false
    },
    {
      "bienfaits": "<div>\r\n<p>L'abricot est une excellente source de vitamine A, essentielle pour la sant&eacute; des yeux et la peau. Il contient &eacute;galement des fibres qui favorisent la digestion et des antioxydants qui aident &agrave; pr&eacute;venir les maladies cardiovasculaires et certains cancers. Les abricots sont &eacute;galement riches en potassium, ce qui est b&eacute;n&eacute;fique pour la sant&eacute; cardiaque.</p>\r\n</div>",
      "collectionId": "wir52u8tj9y3rnj",
      "collectionName": "aliments",
      "comment_cuisiner": "<div>\r\n<p>Pour pr&eacute;server les nutriments, il est pr&eacute;f&eacute;rable de consommer les abricots frais ou s&eacute;ch&eacute;s. Si tu souhaites les cuisiner, opte pour une cuisson rapide &agrave; la vapeur ou au four &agrave; basse temp&eacute;rature. Les abricots peuvent &eacute;galement &ecirc;tre ajout&eacute;s crus aux salades ou utilis&eacute;s dans des smoothies.</p>\r\n</div>",
      "created": "2024-08-08 12:57:37.650Z",
      "disponibilite": "<div>\r\n<p>Les abricots sont disponibles en Suisse principalement durant les mois d'&eacute;t&eacute;, de juin &agrave; ao&ucirc;t. C'est durant cette p&eacute;riode qu'ils sont les plus savoureux et les plus nutritifs.</p>\r\n</div>",
      "fait_nutritionnel": "<div>\r\n<p>Une &eacute;tude a montr&eacute; que les abricots s&eacute;ch&eacute;s conservent une grande partie de leurs nutriments, notamment les fibres et les antioxydants, ce qui en fait un excellent choix pour les collations!</p>\r\n</div>",
      "id": "gl1u809xl7j5xrf",
      "intro": "Découvre tous les secrets de l'abricot, de ses bienfaits à ses méthodes de cuisson, et optimise ta santé avec des conseils pratiques et des faits scientifiques.",
      "name": "L'abricot",
      "picture": "aliments_publication_instagram_2_LkzWn9dxVt.png",
      "recipes_ideas": [],
      "selection": "<div>\r\n<p>Choisis des abricots fermes mais l&eacute;g&egrave;rement souples au toucher, avec une couleur orange uniforme. &Eacute;vite ceux qui pr&eacute;sentent des t&acirc;ches brunes ou des rides. Une l&eacute;g&egrave;re odeur sucr&eacute;e indique souvent que l'abricot est m&ucirc;r.</p>\r\n</div>",
      "sources": "<p><a href=\"https://www.inrae.fr/\">INRAE</a> - Institut national de la recherche agronomique et environnementale</p>\r\n<p><a href=\"https://www.anses.fr/fr\">ANSES</a> - Agence nationale de s&eacute;curit&eacute; sanitaire de l'alimentation, de l'environnement et du travail&nbsp;</p>\r\n<p><a href=\"https://www.aprifel.com/fr/fiche-nutritionnelle/\">Aprifel</a><!-- notionvc: 07b6d199-7ee9-48c0-b760-f5aec09523e3 --></p>",
      "updated": "2024-08-21 13:42:47.609Z",
      "visible": false
    },
    {
      "bienfaits": "",
      "collectionId": "wir52u8tj9y3rnj",
      "collectionName": "aliments",
      "comment_cuisiner": "",
      "created": "2024-08-02 12:10:16.554Z",
      "disponibilite": "",
      "fait_nutritionnel": "",
      "id": "x3lrzvlrw7s1bf5",
      "intro": "",
      "name": "carotte",
      "picture": "whats_app_image_2024_07_30_at_14_17_xy9gSm72iu.50.jpeg",
      "recipes_ideas": [],
      "selection": "",
      "sources": "",
      "updated": "2024-08-08 14:24:35.150Z",
      "visible": true
    },
    {
      "bienfaits": "",
      "collectionId": "wir52u8tj9y3rnj",
      "collectionName": "aliments",
      "comment_cuisiner": "",
      "created": "2024-07-29 20:59:33.114Z",
      "disponibilite": "",
      "fait_nutritionnel": "",
      "id": "vxx7618bs47nknh",
      "intro": "",
      "name": "patate",
      "picture": "",
      "recipes_ideas": [],
      "selection": "",
      "sources": "",
      "updated": "2024-08-08 14:24:31.709Z",
      "visible": true
    },
    {
      "bienfaits": "",
      "collectionId": "wir52u8tj9y3rnj",
      "collectionName": "aliments",
      "comment_cuisiner": "",
      "created": "2024-07-29 20:55:44.342Z",
      "disponibilite": "",
      "fait_nutritionnel": "",
      "id": "vxq3k9gb1k1pdqv",
      "intro": "",
      "name": "courgette",
      "picture": "",
      "recipes_ideas": [],
      "selection": "",
      "sources": "",
      "updated": "2024-08-08 14:24:21.158Z",
      "visible": true
    }
  ],
  "recettes": [
    {
      "Elise_touch": "<div>\r\n<p>Mon combo pr&eacute;f&eacute;r&eacute; c&rsquo;est d&eacute;finitivement graines de chia, lait de cajou maison, cacao en poudre, banane, beurre de noisettes. Le plein de bonnes fibres pour mon microbiote d&egrave;s le matin. Et &eacute;videmment accompagn&eacute; d&rsquo;un matcha latt&eacute;. Prends soin de toi!</p>\r\n</div>",
      "avant_toute_chose": "<div>\r\n<p>Si tu cherches une recette de pudding de chia simple, rapide, sans lactose, sans gluten, riche en prot&eacute;ines, &agrave; faible indice glyc&eacute;mique et v&eacute;g&eacute;tarienne, tu es au bon endroit. Le pudding de chia est top pour un petit-d&eacute;jeuner ou un dessert sain et d&eacute;licieux. Suis ce guide pour pr&eacute;parer le pudding de chia parfait!</p>\r\n</div>",
      "bienfaits": "<div>\r\n<p>Les graines de chia sont pleines de fibres, de prot&eacute;ines, d'om&eacute;ga-3, de vitamines et de min&eacute;raux. Elles sont un vrai tr&eacute;sor nutritionnel. Elles aident &agrave; am&eacute;liorer la digestion, &agrave; r&eacute;guler la glyc&eacute;mie et &agrave; te donner de l'&eacute;nergie tout au long de la journ&eacute;e. Les graines de chia sont une excellente source de prot&eacute;ines, parfaites pour les v&eacute;g&eacute;tariens ou ceux qui veulent augmenter leur apport en prot&eacute;ines.</p>\r\n</div>",
      "collectionId": "9ix6y3v9rzsuxy5",
      "collectionName": "recettes",
      "created": "2024-08-29 09:22:12.042Z",
      "faq": null,
      "id": "2bhoxsnrullp4c8",
      "ingredients": [],
      "nos_conseils": "<div>\r\n<p>Le choix du lait v&eacute;g&eacute;tal peut affecter le go&ucirc;t et la texture du pudding. Le lait d'amande, le lait de coco ou le lait de noisette sont d'excellents choix. Lis bien les &eacute;tiquettes et choisis un lait <strong>sans sucres ajout&eacute;s </strong>qui n&rsquo;en fera plus un petit-d&eacute;jeuner &agrave; IG bas. Pour &eacute;viter que les graines de chia ne forment des grumeaux, remue le m&eacute;lange apr&egrave;s 10 minutes de repos, puis laisse reposer &agrave; nouveau.</p>\r\n</div>",
      "recette": "<div>\r\n<p><strong>Pr&eacute;paration des ingr&eacute;dients (la veille)</strong> <br>Rassemble tous les ingr&eacute;dients n&eacute;cessaires pour ton pudding de chia. Assure-toi que le lait v&eacute;g&eacute;tal est bien m&eacute;lang&eacute; avant utilisation. </p>\r\n<p><strong>M&eacute;lange des ingr&eacute;dients&nbsp;</strong><br>Dans un bol, m&eacute;lange les graines de chia avec le lait d'amande et l'extrait de vanille. Remue bien pour &eacute;viter que les graines de chia ne collent ensemble.</p>\r\n<div>\r\n<p><strong>Temps de repos</strong> <br>Couvre le bol et laisse reposer au r&eacute;frig&eacute;rateur pendant au moins 2 heures, ou id&eacute;alement toute la nuit. Les graines de chia vont absorber le liquide et cr&eacute;er une texture de pudding. <br><br><strong>Ajout des garnitures</strong> <br>Le matin, sors le pudding et ajoute des fruits frais, des noix, ou des graines selon tes go&ucirc;ts. Les garnitures peuvent inclure des baies, des tranches de banane, des amandes, de la noix de coco r&acirc;p&eacute;e, ou des graines de lin.</p>\r\n</div>\r\n</div>",
      "recipe_idea": "",
      "subtitle": "Préparation - 5 minutes",
      "title": "Pudding de chia inratable",
      "updated": "2024-08-29 09:22:12.042Z"
    },
    {
      "Elise_touch": "",
      "avant_toute_chose": "",
      "bienfaits": "",
      "collectionId": "9ix6y3v9rzsuxy5",
      "collectionName": "recettes",
      "created": "2024-07-29 20:55:58.726Z",
      "faq": {
        "faq": [
          {
            "question": "Salut?",
            "reponse": "salut"
          }
        ]
      },
      "id": "ra9gttlyesefv16",
      "ingredients": ["vxq3k9gb1k1pdqv", "vxx7618bs47nknh"],
      "nos_conseils": "",
      "recette": "",
      "recipe_idea": "",
      "subtitle": "",
      "title": "Salade de courgette",
      "updated": "2024-08-08 14:35:08.658Z"
    }
  ],
  "profiles": [
    {
      "address_link": "https://maps.app.goo.gl/Bq6ZXCKRcv7jjbYKA",
      "address_text": "Cabinet Valsanté, Espace des Remparts 14",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:48:10.274Z",
      "description": "",
      "email": "marianne@valsante-dieteticiennes.ch",
      "faq": null,
      "first_name": "Marianne",
      "hidden": false,
      "id": "8uw5ms92pndmkfm",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Reynard Saraiva",
      "phone_number": "077 470 45 55",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Diététicienne ASDD",
      "updated": "2024-12-04 09:49:32.329Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/2hmC2Ni9NcvMQDEz5",
      "address_text": "Av. Mercier-de-Molin 2",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:48:08.601Z",
      "description": "",
      "email": "nutriconsult_providoli@hotmail.com",
      "faq": null,
      "first_name": "Nathalie",
      "hidden": false,
      "id": "x5t9lyejksfktni",
      "languages": ["g8reptg5cwnhvor", "egbe5ll1g9bq31l"],
      "last_name": "Providoli",
      "phone_number": "027 455 66 22",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Diététicienne ASDD",
      "updated": "2024-12-04 09:49:31.268Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/cEaGBtfrardgDfVB9",
      "address_text": "Essentia, Rue d'Aoste 9",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:48:06.873Z",
      "description": "",
      "email": "info@essentiavalais.ch",
      "faq": null,
      "first_name": "Céline",
      "hidden": false,
      "id": "0vwal4snnzjcj89",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Lovey",
      "phone_number": "079 108 66 06",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Diététicienne ASDD",
      "updated": "2024-12-04 09:49:30.168Z",
      "website": "http://www.essentiavalais.ch"
    },
    {
      "address_link": "https://maps.app.goo.gl/Zu1eTzDKXyDBkp137",
      "address_text": "Cabinet Atout Santé, Rue Louis Antille 2",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:48:05.221Z",
      "description": "",
      "email": "dietetique@cabinet-atoutsante.ch",
      "faq": null,
      "first_name": "Karin",
      "hidden": false,
      "id": "o8t4dpxk46auwn1",
      "languages": ["g8reptg5cwnhvor", "ciwr918a5o8h84g", "egbe5ll1g9bq31l"],
      "last_name": "Gendre-Frederiks",
      "phone_number": "079 611 62 51",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Diététicienne ASDD",
      "updated": "2024-12-04 09:49:29.083Z",
      "website": "http://www.cabinet-atoutsante.ch/"
    },
    {
      "address_link": "https://maps.app.goo.gl/E4gafC6dr6H83jzPA",
      "address_text": "Espace Nutrition et Harmonie, Rue de la Majorie 4",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:48:03.684Z",
      "description": "",
      "email": "francoise@nutrition-et-harmonie.ch",
      "faq": null,
      "first_name": "Françoise",
      "hidden": false,
      "id": "obdy1r4ar0wghvl",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "de Riedmatten In-Albon",
      "phone_number": "079 795 65 69",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Diététicienne ASDD",
      "updated": "2024-12-04 09:49:27.942Z",
      "website": "http://www.nutrition-et-harmonie.ch"
    },
    {
      "address_link": "https://maps.app.goo.gl/PaKJhwvhpQTtQXEU6",
      "address_text": "Rue du Scex 2",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:48:02.112Z",
      "description": "",
      "email": "laurence.bridel@nutritiondietetique.ch",
      "faq": null,
      "first_name": "Laurence",
      "hidden": false,
      "id": "ni2cc6xo4gppzdq",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Bridel-Albrecht",
      "phone_number": "079 355 34 33",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Diététicienne ASDD",
      "updated": "2024-12-04 09:49:26.800Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/STrov1RAzqL2iYUE8",
      "address_text": "Ch Clos Pépinière 6",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:47:59.913Z",
      "description": "",
      "email": "helene.schoeb@hin.ch",
      "faq": null,
      "first_name": "Hélène",
      "hidden": false,
      "id": "hjbpcm54losf7l9",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Schoeb Germano",
      "phone_number": "021 881 19 20",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Diététicienne ASDD",
      "updated": "2024-12-04 09:49:24.448Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/6LHKWkk4ELvNVQ6z7",
      "address_text": "Rue du Collège 4",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:47:58.309Z",
      "description": "",
      "email": "christinesandoz@sunrise.ch",
      "faq": null,
      "first_name": "Christine",
      "hidden": false,
      "id": "n6426ipw4svwsm3",
      "languages": ["g8reptg5cwnhvor", "ciwr918a5o8h84g"],
      "last_name": "Sandoz",
      "phone_number": "024 425 16 00",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Diététicienne ASDD",
      "updated": "2024-12-04 09:49:23.215Z",
      "website": "http://www.diet-div.ch"
    },
    {
      "address_link": "https://maps.app.goo.gl/hRSJMLXy8AnmLz1G7",
      "address_text": "Espace Santé Valerette, Chemin de la Valerette 1A",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:47:56.826Z",
      "description": "",
      "email": "danaeronchi@diets-hin.ch",
      "faq": null,
      "first_name": "Danaé",
      "hidden": false,
      "id": "qmfe7wqx3eu7j8y",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Ronchi",
      "phone_number": "078 690 94 84",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Diététicienne ASDD",
      "updated": "2024-12-04 09:49:22.084Z",
      "website": "http://www.danaeronchi.ch"
    },
    {
      "address_link": "https://maps.app.goo.gl/ifHJoUMrMGRU5hfV7",
      "address_text": "Le Montauban, 1",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:47:55.301Z",
      "description": "",
      "email": "nutrition.i.rolfo@gmail.com",
      "faq": null,
      "first_name": "Irène",
      "hidden": false,
      "id": "x3pmgd8zj51h4pi",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Rolfo",
      "phone_number": "076 490 42 34",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Diététicienne ASDD",
      "updated": "2024-12-04 09:49:20.993Z",
      "website": "http://www.impulsemoves.ch"
    },
    {
      "address_link": "https://maps.app.goo.gl/hMJ4qGawKA5icgan8",
      "address_text": "Ruelle des Cèdres 2",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:47:53.771Z",
      "description": "",
      "email": "c.pouly@bluewin.ch",
      "faq": null,
      "first_name": "Catherine",
      "hidden": false,
      "id": "umsqeyemwb39p6q",
      "languages": ["g8reptg5cwnhvor", "ciwr918a5o8h84g"],
      "last_name": "Pouly-Plumettaz",
      "phone_number": "021 811 03 00",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Diététicienne ASDD",
      "updated": "2024-12-04 09:49:19.853Z",
      "website": ""
    },
    {
      "address_link": "",
      "address_text": "Av. Cerjat 1",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:47:52.000Z",
      "description": "",
      "email": "veronique.pidoux@diets-hin.ch",
      "faq": null,
      "first_name": "Véronique",
      "hidden": false,
      "id": "ix5aozgh6wtb40q",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Pidoux",
      "phone_number": "079 797 08 53",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Diététicienne ASDD",
      "updated": "2024-12-04 09:49:18.797Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/RRh3rTQoFcYAHkGW6",
      "address_text": "Avenue des Découvertes 3",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:47:50.345Z",
      "description": "",
      "email": "rosette.pedro@medicit-y.ch",
      "faq": null,
      "first_name": "Rosette",
      "hidden": false,
      "id": "sc4cn6gxyuouvgc",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Pedro",
      "phone_number": "024 423 04 00",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Diététicienne ASDD",
      "updated": "2024-12-04 09:49:17.709Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/ATgcvoVhChxoBuXR7",
      "address_text": "Mathurin Cordier 11",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:47:48.594Z",
      "description": "",
      "email": "chantal.negre@diets-hin.ch",
      "faq": null,
      "first_name": "Chantal",
      "hidden": false,
      "id": "xw8nvxxa52bujva",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Nègre",
      "phone_number": "021 312 48 04",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Diététicienne ASDD",
      "updated": "2024-12-04 09:49:16.572Z",
      "website": "www.cabinetdietetique-lausanne.ch"
    },
    {
      "address_link": "https://maps.app.goo.gl/SSjV7KjNczZYM1nPA",
      "address_text": "Pôle de Santé Avenchois, Pré-Vert 5b",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:47:46.916Z",
      "description": "",
      "email": "zestenutrition@diets-hin.ch",
      "faq": null,
      "first_name": "Nadège",
      "hidden": false,
      "id": "capo3r45556d3de",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Müller",
      "phone_number": "026 675 16 60",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Diététicienne ASDD",
      "updated": "2024-12-04 09:49:15.390Z",
      "website": "http://www.zestenutrition.ch"
    },
    {
      "address_link": "",
      "address_text": "Avenue Jomini 4",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:47:45.147Z",
      "description": "",
      "email": "vincent.monney@novidiet.ch",
      "faq": null,
      "first_name": "Vincent",
      "hidden": false,
      "id": "xgqn4kq5reg75ea",
      "languages": ["g8reptg5cwnhvor", "ciwr918a5o8h84g"],
      "last_name": "Monney",
      "phone_number": "077 451 69 99",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Diététicienne ASDD",
      "updated": "2024-12-04 09:49:14.160Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/9a4ngDXHDMVjKVZ66",
      "address_text": "Nutriconseil, Galeries Benjamin-Constant 1",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:47:43.360Z",
      "description": "",
      "email": "nutriconseil.nm@hotmail.com",
      "faq": null,
      "first_name": "Nicole",
      "hidden": false,
      "id": "stc2d7d0id07nth",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Mégroz Tarabori",
      "phone_number": "021 323 68 45",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Diététicienne ASDD",
      "updated": "2024-12-04 09:49:13.072Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/3jLwEkiKampFAKZV8",
      "address_text": "Passage St-Antoine 7",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:47:41.672Z",
      "description": "",
      "email": "m-j.marguerat@nutriviva.ch",
      "faq": null,
      "first_name": "Marie-José",
      "hidden": false,
      "id": "aw6hvbapv4qhtcr",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Marguerat Luyet",
      "phone_number": "079 231 74 74",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Diététicienne ASDD",
      "updated": "2024-12-04 09:49:11.995Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/DnKwheXt5LukMPqS6",
      "address_text": "Avenue Villamont 17",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:47:38.038Z",
      "description": "",
      "email": "catherineleger.dieteticienne@gmail.com",
      "faq": null,
      "first_name": "Catherine",
      "hidden": false,
      "id": "3c016rduw3euhtf",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Léger",
      "phone_number": "076 309 45 70",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Diététicienne ASDD",
      "updated": "2024-12-04 09:49:10.821Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/vTNqfWLg6ZURoS7h7",
      "address_text": "Cabinet diététique, Rte d'Oron 2",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:47:36.432Z",
      "description": "",
      "email": "isabelle@kudelski.name",
      "faq": null,
      "first_name": "Isabelle",
      "hidden": false,
      "id": "qn5d1hi84r106oa",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Kudelski Haldy",
      "phone_number": "021 653 43 65",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Diététicienne ASDD",
      "updated": "2024-12-04 09:49:09.766Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/RFg9G7ZAL6VRXEcL8",
      "address_text": "",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:47:34.234Z",
      "description": "",
      "email": "held.dieteticienne@gmail.com",
      "faq": null,
      "first_name": "Nicole",
      "hidden": false,
      "id": "ez4959iptx2pzb0",
      "languages": ["g8reptg5cwnhvor", "ciwr918a5o8h84g"],
      "last_name": "Held",
      "phone_number": "079 4554003",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Diététicienne ASDD",
      "updated": "2024-12-04 09:49:07.359Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/8UmeyviqMgH7pntt7",
      "address_text": "Chemin de Courseboux 7",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:47:32.671Z",
      "description": "",
      "email": "noemie.hattich@novidiet.ch",
      "faq": null,
      "first_name": "Noémie",
      "hidden": false,
      "id": "c0hkxwykwomnn75",
      "languages": ["g8reptg5cwnhvor", "ciwr918a5o8h84g", "egbe5ll1g9bq31l"],
      "last_name": "Hattich",
      "phone_number": "021 799 28 28",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Diététicienne ASDD",
      "updated": "2024-12-04 09:49:06.303Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/ikLs957UB9MUdLq49",
      "address_text": "Attitudes Nutrition, Boulevard de la Forêt 32",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:47:31.048Z",
      "description": "",
      "email": "attitudes.nutrition@hispeed.ch",
      "faq": null,
      "first_name": "Jannick",
      "hidden": false,
      "id": "lcuhh8j3jz38pk7",
      "languages": [],
      "last_name": "Grin",
      "phone_number": "021 711 16 66",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Diététicienne ASDD",
      "updated": "2024-12-02 12:47:31.048Z",
      "website": "www.attitudesnutrition.ch"
    },
    {
      "address_link": "https://maps.app.goo.gl/ATgcvoVhChxoBuXR7",
      "address_text": "Rue Mathurin Cordier",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:47:29.383Z",
      "description": "",
      "email": "andrea.gaspar@diets-hin.ch",
      "faq": null,
      "first_name": "Andrea",
      "hidden": false,
      "id": "5t3f2cc4e5qm03g",
      "languages": ["g8reptg5cwnhvor", "ciwr918a5o8h84g"],
      "last_name": "Gaspar",
      "phone_number": "021 312 37 68",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Diététicienne ASDD",
      "updated": "2024-12-04 09:49:04.744Z",
      "website": "https://www.cabinetdietetique-lausanne.ch/"
    },
    {
      "address_link": "https://maps.app.goo.gl/39p1SL6gykNKtht46",
      "address_text": "Centre Médical de Gland, Allée du Coummunet 20",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:47:27.743Z",
      "description": "",
      "email": "patricia.forrer@magellan.ch",
      "faq": null,
      "first_name": "Patricia",
      "hidden": false,
      "id": "gobwd9diklpr23g",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Forrer Larivière",
      "phone_number": "022 364 04 04",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Diététicienne ASDD",
      "updated": "2024-12-04 09:49:03.594Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/ooAX6nkSLps74SKT9",
      "address_text": "Avenue Servan 36",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:47:26.097Z",
      "description": "",
      "email": "contact@feedgood.ch",
      "faq": null,
      "first_name": "Stéphanie",
      "hidden": false,
      "id": "62umi9ket0y68kq",
      "languages": ["g8reptg5cwnhvor", "ciwr918a5o8h84g"],
      "last_name": "Foglietta Dreyfuss",
      "phone_number": "076 517 23 35",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Diététicienne ASDD",
      "updated": "2024-12-04 09:49:02.517Z",
      "website": "http://www.feedgood.ch"
    },
    {
      "address_link": "https://maps.app.goo.gl/Kj7Qp9hCkvh4HT9K9",
      "address_text": "Centre Medical de Vidy, Route de Chavannes 9A",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:47:24.548Z",
      "description": "",
      "email": "nat.fischer@bluewin.ch",
      "faq": null,
      "first_name": "Nathalie",
      "hidden": false,
      "id": "30hstth60ngqnns",
      "languages": ["g8reptg5cwnhvor", "ciwr918a5o8h84g", "egbe5ll1g9bq31l"],
      "last_name": "Fischer-Lachenal",
      "phone_number": "021 622 88 41",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Diététicienne ASDD",
      "updated": "2024-12-04 09:49:01.446Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/6x3bHzYBTLkersgm8",
      "address_text": "Route du Collège 16",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:47:22.846Z",
      "description": "",
      "email": "cdemartini@bluewin.ch",
      "faq": null,
      "first_name": "Catherine",
      "hidden": false,
      "id": "x21hjk3p49gf6du",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "de Martini",
      "phone_number": "079 507 04 62",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Diététicienne ASDD",
      "updated": "2024-12-04 09:49:00.319Z",
      "website": ""
    },
    {
      "address_link": "",
      "address_text": "Avenue du Fey 8",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:47:21.321Z",
      "description": "",
      "email": "cecile.crisinelfavre@diets-hin.ch",
      "faq": null,
      "first_name": "Cécile",
      "hidden": false,
      "id": "1nqzt2t0bxxpikn",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Crisinel Favre",
      "phone_number": "079 371 96 18",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Diététicienne ASDD",
      "updated": "2024-12-04 09:48:59.053Z",
      "website": "http://www.moudon-nutrition.ch"
    },
    {
      "address_link": "https://maps.app.goo.gl/Miy7P8eng5ZKx9cj6",
      "address_text": "Solution santé, Rue Louis de Savoie 29",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:47:19.685Z",
      "description": "",
      "email": "sylvie.chenaux@bluewin.ch",
      "faq": null,
      "first_name": "Sylvie",
      "hidden": false,
      "id": "7qqknfye1u8yy0b",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Chenaux-Boisselet",
      "phone_number": "079 310 13 94",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Diététicienne ASDD",
      "updated": "2024-12-04 09:48:57.865Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/2rh5SWgPtmw4sFob9",
      "address_text": "CAD - Conseils en Alimentation et Diététique, Rue de Plan 12",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:47:18.112Z",
      "description": "",
      "email": "cad_barbarablanc@bluewin.ch",
      "faq": null,
      "first_name": "Barbara",
      "hidden": false,
      "id": "hzfswj5akj6r1kn",
      "languages": ["g8reptg5cwnhvor", "09wr6j2qj6lsu6a"],
      "last_name": "Blanc",
      "phone_number": "021 634 57 94",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Diététicienne ASDD",
      "updated": "2024-12-04 09:48:56.836Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/Gr3FnsbRQMBz8zwc9",
      "address_text": "Rue des Remparts 20",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:47:16.424Z",
      "description": "",
      "email": "ansermetnutrition@gmail.com",
      "faq": null,
      "first_name": "Olivia",
      "hidden": false,
      "id": "bhykxyx37kxq92g",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Ansermet",
      "phone_number": "024 426 04 18",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Diététicienne ASDD",
      "updated": "2024-12-04 09:48:55.633Z",
      "website": "https://www.mumtobe.ch/prestations-interdisciplinaires-soins-alternatifs#dietetique"
    },
    {
      "address_link": "https://maps.app.goo.gl/gkTpjnp4EUFPadVT8",
      "address_text": "Vinet 25",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:47:14.740Z",
      "description": "",
      "email": "catherine.andre@diets-hin.ch",
      "faq": null,
      "first_name": "Catherine",
      "hidden": false,
      "id": "xor6dxcr2ukbz6e",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "André",
      "phone_number": "021 312 20 62",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Diététicienne ASDD",
      "updated": "2024-12-04 09:48:54.442Z",
      "website": "https://www.diet-ethique.ch/"
    },
    {
      "address_link": "https://maps.app.goo.gl/msggfsEVPmdfjK3SA",
      "address_text": "Rue de la Chapelle 3",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:47:13.119Z",
      "description": "",
      "email": "cindy.pilorget@diets-hin.ch",
      "faq": null,
      "first_name": "Cindy",
      "hidden": false,
      "id": "97mk2cx6zbwojpx",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Pilorget",
      "phone_number": "077 442 58 97",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Diététicienne ASDD",
      "updated": "2024-12-04 09:48:53.358Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/3BXXaBVnrMGvqdEx9",
      "address_text": "Rue de la Paix 71",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:47:11.417Z",
      "description": "",
      "email": "valentine.mora@croix-rouge-ne.ch",
      "faq": null,
      "first_name": "Valentine",
      "hidden": false,
      "id": "7nti1maqxy9cksy",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Mora",
      "phone_number": "079 746 38 12",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Diététicienne ASDD",
      "updated": "2024-12-04 09:48:52.197Z",
      "website": ""
    },
    {
      "address_link": "",
      "address_text": "Conscience Nutrition, Rue St-Honoré 12",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:47:09.772Z",
      "description": "",
      "email": "marie.marchand@conscience-nutrition.ch",
      "faq": null,
      "first_name": "Marie",
      "hidden": false,
      "id": "03qwt3mahieaxgy",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Marchand-Lopez",
      "phone_number": "079 569 79 41",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Diététicienne ASDD",
      "updated": "2024-12-04 09:48:50.963Z",
      "website": "http://www.conscience-nutrition.ch"
    },
    {
      "address_link": "https://maps.app.goo.gl/F5Ba5kX6H3Y7iFV19",
      "address_text": "CoeurNutrition / Services et Conseils Nutrition, Rue du Rocher 52",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:47:08.222Z",
      "description": "",
      "email": "info@coeurnutrition.com",
      "faq": null,
      "first_name": "Silvia",
      "hidden": false,
      "id": "02zfw52x6xe749c",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Gardiol Zosso",
      "phone_number": "079 718 09 03",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Diététicienne ASDD",
      "updated": "2024-12-04 09:48:49.915Z",
      "website": "https://www.coeurnutrition.com"
    },
    {
      "address_link": "https://maps.app.goo.gl/Na6JRBbQWry4hZW16",
      "address_text": "Espace Nutrition, Fausses-Brayes 1",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:47:06.603Z",
      "description": "",
      "email": "severine@espace-nutrition.ch",
      "faq": null,
      "first_name": "Séverine",
      "hidden": false,
      "id": "ru8b98tvgz1p5g1",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Chédel-Hirt",
      "phone_number": "032 725 55 40",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Diététicienne ASDD",
      "updated": "2024-12-04 09:48:48.920Z",
      "website": "http://www.espace-nutrition.ch/"
    },
    {
      "address_link": "https://maps.app.goo.gl/vLHYPW1vb3EfGuYk6",
      "address_text": "Léopold-Robert 21",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:47:05.001Z",
      "description": "",
      "email": "secretariat.centrepod@hin.ch",
      "faq": null,
      "first_name": "Sophie",
      "hidden": false,
      "id": "1mj8x14b1bshlum",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Bole-du-Chomont",
      "phone_number": "032 914 04 52",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Diététicienne ASDD",
      "updated": "2024-12-04 09:48:47.781Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/dbHxVNTr43Eww5ds6",
      "address_text": "Espace Nutrition, Fausses-Brayes 1",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:47:03.253Z",
      "description": "",
      "email": "florence@espace-nutrition.ch",
      "faq": null,
      "first_name": "Florence",
      "hidden": false,
      "id": "5d0r6gny7j8k95h",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Authier",
      "phone_number": "032 725 55 40",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Diététicienne ASDD",
      "updated": "2024-12-04 09:48:46.695Z",
      "website": "http://www.espace-nutrition.ch/"
    },
    {
      "address_link": "",
      "address_text": "Route de Châtillon 10",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:47:01.601Z",
      "description": "",
      "email": "ariane.lachat@diets-hin.ch",
      "faq": null,
      "first_name": "Ariane",
      "hidden": false,
      "id": "o7drurti8s54hfa",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Lachat",
      "phone_number": "079 402 72 13",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Diététicienne ASDD",
      "updated": "2024-12-04 09:48:45.640Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/CPbRouM1x4DTdLD1A",
      "address_text": "Rte de Déridez 24",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:46:59.876Z",
      "description": "",
      "email": "anita.kradolfer@diets-hin.ch",
      "faq": null,
      "first_name": "Anita",
      "hidden": false,
      "id": "l51uian1whhypla",
      "languages": ["g8reptg5cwnhvor", "egbe5ll1g9bq31l"],
      "last_name": "Kradolfer",
      "phone_number": "079 831 86 85",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Diététicienne ASDD",
      "updated": "2024-12-04 09:48:44.537Z",
      "website": ""
    },
    {
      "address_link": "",
      "address_text": "Centre de Consultations Nutrition et Psychothérapie, Rue du Vieux-Marché 8",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:46:58.189Z",
      "description": "",
      "email": "magali.volery@ccnp-ge.ch",
      "faq": null,
      "first_name": "Magali",
      "hidden": false,
      "id": "rqdptieej2ff45u",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Volery",
      "phone_number": "022 329 67 10",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Diététicienne ASDD",
      "updated": "2024-12-04 09:48:43.422Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/2Z3R2Zr9KTMVUEHc6",
      "address_text": "Médicentre de Balexert, Av. Louis-Casaï 27",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:46:56.596Z",
      "description": "",
      "email": "francoisetanner@gmail.com",
      "faq": null,
      "first_name": "Françoise",
      "hidden": false,
      "id": "gdezmqkbozad1nc",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Tanner",
      "phone_number": "022 748 49 70",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Diététicienne ASDD",
      "updated": "2024-12-04 09:49:25.666Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/e9Z65vRPc2hVpGhB7",
      "address_text": "Groupe Médical des Avanchets, rue du Grand-Bay 15",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:46:55.029Z",
      "description": "",
      "email": "diet.gma@gmx.de",
      "faq": null,
      "first_name": "Sandra",
      "hidden": false,
      "id": "qvkkgcvpxq2ddvj",
      "languages": ["g8reptg5cwnhvor", "egbe5ll1g9bq31l"],
      "last_name": "Rüegsegger-Tanner",
      "phone_number": "022 979 09 10",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Diététicienne ASDD",
      "updated": "2024-12-04 09:48:40.962Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/nyd9TBRkGbR8coxW8",
      "address_text": "Centre de médecine interdisciplinaire, Av. Industrielle 1",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:46:53.288Z",
      "description": "",
      "email": "mireillepeclard@gmail.com",
      "faq": null,
      "first_name": "Mireille",
      "hidden": false,
      "id": "ps1io77ict1eyzh",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Péclard",
      "phone_number": "022 327 20 30",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Diététicienne ASDD",
      "updated": "2024-12-04 09:48:39.823Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/nhPKvhUTRTUckpiG9",
      "address_text": "HELVETICARE Malagnou, Rue 8, route de Malagnou",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:46:51.674Z",
      "description": "",
      "email": "v.mckenzie@helvetic-care.ch",
      "faq": null,
      "first_name": "Valérie",
      "hidden": false,
      "id": "u58ie8q1350ei1h",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "McKenzie",
      "phone_number": "022 788 11 11",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Diététicienne ASDD",
      "updated": "2024-12-04 09:48:38.713Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/y5u7fUuY2SSmLvgg6",
      "address_text": "Clinique des Grangettes, Chemin des Grangettes 7",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:46:50.005Z",
      "description": "",
      "email": "sarah.marmillod@gmail.com",
      "faq": null,
      "first_name": "Sarah",
      "hidden": false,
      "id": "aox1gmtg310ikgy",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Marmillod",
      "phone_number": "079 525 97 03",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Diététicienne ASDD",
      "updated": "2024-12-04 09:48:37.546Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/wA2LuMFwwrb8Sc9f6",
      "address_text": "GGHA - Cabinet de gastroentérologie, Rue Giovanni-Gambini 8",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:46:48.361Z",
      "description": "",
      "email": "raffi.maghdessian@ggha-sa.ch",
      "faq": null,
      "first_name": "Raffi",
      "hidden": false,
      "id": "bprf0rimag5o2jd",
      "languages": ["g8reptg5cwnhvor", "ciwr918a5o8h84g", "09wr6j2qj6lsu6a"],
      "last_name": "Maghdessian",
      "phone_number": "022 908 10 01",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Diététicienne ASDD",
      "updated": "2024-12-04 09:48:36.514Z",
      "website": "https://www.geneve-nutrition.ch/"
    },
    {
      "address_link": "https://maps.app.goo.gl/VU9B451AePNdkGws9",
      "address_text": "Centre médical Carteret, Rue Antoine Carteret 6",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:46:46.121Z",
      "description": "",
      "email": "saskia.hebrard@hnsa.ch",
      "faq": null,
      "first_name": "Saskia",
      "hidden": false,
      "id": "vpjjkfh6xnxr00j",
      "languages": ["g8reptg5cwnhvor", "ciwr918a5o8h84g"],
      "last_name": "Hebrard",
      "phone_number": "022 345 66 66",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Diététicienne ASDD",
      "updated": "2024-12-04 09:48:34.284Z",
      "website": "http://www.plaisirdietetique.com"
    },
    {
      "address_link": "https://maps.app.goo.gl/NbXrKrL9LVs4zeLL9",
      "address_text": "Rue de Genève 109",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:46:44.525Z",
      "description": "",
      "email": "st.favre.diet@bluewin.ch",
      "faq": null,
      "first_name": "Stéphanie",
      "hidden": false,
      "id": "zbk5i9a1239uogt",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Favre",
      "phone_number": "022 349 21 24",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Diététicienne ASDD",
      "updated": "2024-12-04 09:48:33.186Z",
      "website": ""
    },
    {
      "address_link": "",
      "address_text": "Ch. de Grand-Donzel 16",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:46:42.848Z",
      "description": "",
      "email": "maude.bessat@gmail.com",
      "faq": null,
      "first_name": "Maude",
      "hidden": false,
      "id": "6ycgopvoj88ernp",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Bessat-Macchi",
      "phone_number": "022 879 50 48",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Diététicienne ASDD",
      "updated": "2024-12-04 09:48:32.133Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/v8RghPUxSXCUdejz9",
      "address_text": "Cabinet Médical Frontenex, 100 route de Frontenex",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:46:41.230Z",
      "description": "",
      "email": "dietetique.frontenex@gmail.com",
      "faq": null,
      "first_name": "Joëlle",
      "hidden": false,
      "id": "oocy3vmy3vz7c78",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Belaieff",
      "phone_number": "077 521 82 74",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Diététicienne ASDD",
      "updated": "2024-12-04 09:48:31.027Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/fXicDvWTi9Tyg6757",
      "address_text": "Green Soul, Rue Hans Geiler 2",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:46:39.467Z",
      "description": "",
      "email": "mverschuren@diets-hin.ch",
      "faq": null,
      "first_name": "Margje",
      "hidden": false,
      "id": "k2ohyct08p85inw",
      "languages": ["g8reptg5cwnhvor", "egbe5ll1g9bq31l"],
      "last_name": "Verschuren",
      "phone_number": "079 425 79 58",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Diététicienne ASDD",
      "updated": "2024-12-04 09:48:29.815Z",
      "website": "http://www.greensouldiets.com"
    },
    {
      "address_link": "https://maps.app.goo.gl/578zdYy6DwLm8uNE8",
      "address_text": "Rte de la Joretta 23",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:46:37.746Z",
      "description": "",
      "email": "nutripassion@diets-hin.ch",
      "faq": null,
      "first_name": "Marilyne",
      "hidden": false,
      "id": "g1fhw6wz2g57p50",
      "languages": ["g8reptg5cwnhvor", "egbe5ll1g9bq31l"],
      "last_name": "Seydoux-Perroud",
      "phone_number": "026 323 29 27",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Diététicienne ASDD",
      "updated": "2024-12-04 09:48:28.748Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/nkniX4kHTqzoEtZG8",
      "address_text": "Bd de Pérolles 6",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:46:36.003Z",
      "description": "",
      "email": "c.roulindumont@diets-hin.ch",
      "faq": null,
      "first_name": "Caroline",
      "hidden": false,
      "id": "zqkk5e3l3dstb2d",
      "languages": ["g8reptg5cwnhvor", "egbe5ll1g9bq31l"],
      "last_name": "Roulin Dumont",
      "phone_number": "079 456 77 47",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Diététicienne ASDD",
      "updated": "2024-12-04 09:48:27.679Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/62KuyPCuV7EoH54o9",
      "address_text": "Route du Peage 16b",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:46:34.178Z",
      "description": "",
      "email": "info@srk-nutrition.ch",
      "faq": null,
      "first_name": "Sophie",
      "hidden": false,
      "id": "x2qc4xnh6rl8h8q",
      "languages": ["g8reptg5cwnhvor", "ciwr918a5o8h84g", "egbe5ll1g9bq31l"],
      "last_name": "Rohrer-Kaufmann",
      "phone_number": "078 232 57 73",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Diététicienne ASDD",
      "updated": "2024-12-04 09:48:26.511Z",
      "website": "https://www.srk-nutrition.ch/"
    },
    {
      "address_link": "https://maps.app.goo.gl/qFpVuX44jygJksNP8",
      "address_text": "Diet'autrement, Rue du Camus 2",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:46:32.403Z",
      "description": "",
      "email": "dietautrement@gmail.com",
      "faq": null,
      "first_name": "Audrey",
      "hidden": false,
      "id": "fu4dxvf4wsdvc04",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Perseghini",
      "phone_number": "079 535 87 28",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Diététicienne ASDD",
      "updated": "2024-12-04 09:48:25.403Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/fp63F4EzUEYE3ShU8",
      "address_text": "Rte du Jura 12A",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:46:30.772Z",
      "description": "",
      "email": "nathalie.pasquier@matrix-nutrition.ch",
      "faq": null,
      "first_name": "Nathalie",
      "hidden": false,
      "id": "ukjc7b489iwsulu",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Pasquier",
      "phone_number": "079 754 98 22",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Diététicienne ASDD",
      "updated": "2024-12-04 09:48:24.291Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/DXrkgwX2WNQnwBRFA",
      "address_text": "Route de Fribourg 139",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:46:29.137Z",
      "description": "",
      "email": "monnard.nutrition@gmail.com",
      "faq": null,
      "first_name": "Monia",
      "hidden": false,
      "id": "ih6218j4b5r1p5e",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Monnard",
      "phone_number": "079 794 69 47",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Diététicienne ASDD",
      "updated": "2024-12-04 09:48:23.189Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/9GYUc1mJF1gU8C4k8",
      "address_text": "Route de Riaz",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:46:27.525Z",
      "description": "",
      "email": "sylvie.kientz@gmail.com",
      "faq": null,
      "first_name": "Sylvie",
      "hidden": false,
      "id": "fmap258tfiy18zm",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Kientz Meier",
      "phone_number": "079 908 98 69",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Diététicienne ASDD",
      "updated": "2024-12-04 09:49:08.480Z",
      "website": ""
    },
    {
      "address_link": "",
      "address_text": "Route de l'lntyamon 113",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:46:25.832Z",
      "description": "",
      "email": "veroisa@gmail.com",
      "faq": null,
      "first_name": "Veronica Isabel",
      "hidden": false,
      "id": "yzui53kpzkk88ec",
      "languages": ["g8reptg5cwnhvor", "ciwr918a5o8h84g"],
      "last_name": "Heuer",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Diététicienne ASDD",
      "updated": "2024-12-04 09:48:20.829Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/oADGb6RpeFVPPJv49",
      "address_text": "Ch. de la Grotte 1",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:46:24.225Z",
      "description": "",
      "email": "sonutrifresh@gmail.com",
      "faq": null,
      "first_name": "Melanie",
      "hidden": false,
      "id": "4b7omp52ggrtvty",
      "languages": ["g8reptg5cwnhvor", "ciwr918a5o8h84g", "egbe5ll1g9bq31l"],
      "last_name": "Berger",
      "phone_number": "079 748 42 04",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Diététicienne ASDD",
      "updated": "2024-12-04 09:48:19.555Z",
      "website": "http://www.sonutrifresh.com"
    },
    {
      "address_link": "https://maps.app.goo.gl/7rq6S9j2vwSSksw3A",
      "address_text": "Rue du Rhône 29, 1204 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:46:22.609Z",
      "description": "",
      "email": "yz@diets-hin.ch",
      "faq": null,
      "first_name": "Yasmina",
      "hidden": false,
      "id": "i6pzue8bsewyp19",
      "languages": ["ciwr918a5o8h84g", "g8reptg5cwnhvor"],
      "last_name": "Zgoulli",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:48:18.442Z",
      "website": "https://www.onedoc.ch/en/dietitian/geneva/pcla6/yasmina-zgoulli"
    },
    {
      "address_link": "https://maps.app.goo.gl/oG6LfdPRN5R1dKNV9",
      "address_text": "Rue du Vuache 9, 1201 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:46:20.961Z",
      "description": "",
      "email": "info@naturopsycho.ch",
      "faq": null,
      "first_name": "Marie",
      "hidden": false,
      "id": "a6tw3otvrkh2cg7",
      "languages": [],
      "last_name": "Zesiger",
      "phone_number": "766 934 715",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:46:20.961Z",
      "website": "https://naturopsycho.ch"
    },
    {
      "address_link": "https://maps.app.goo.gl/YN3oj59v8rkBq62y8",
      "address_text": "15, Av. des Tilleuls, 1203 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:46:19.192Z",
      "description": "",
      "email": "g.zahnd@therapiesnaturelles.ch",
      "faq": null,
      "first_name": "Georges",
      "hidden": false,
      "id": "48d9ki0q88jngg0",
      "languages": [],
      "last_name": "Zahnd",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:46:19.192Z",
      "website": "www.therapiesnaturelles.ch"
    },
    {
      "address_link": "",
      "address_text": "Place de Pont-Rouge 5, 1212 Grand-Lancy",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:46:17.493Z",
      "description": "",
      "email": "lancy-pont-rouge@medbase.ch",
      "faq": null,
      "first_name": "Marc",
      "hidden": false,
      "id": "my70b6i51sksh3o",
      "languages": [],
      "last_name": "Yacoub Maroun",
      "phone_number": "227 090 400",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:46:17.493Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/nNbXZnYSB4rhwE8v8",
      "address_text": "Rte de Jussy 4 Bis, 1226 Thônex",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:46:15.942Z",
      "description": "",
      "email": "joanna_witkowska@yahoo.com",
      "faq": null,
      "first_name": "Joanna",
      "hidden": false,
      "id": "p0czclml6wkbkf2",
      "languages": [],
      "last_name": "Witkowska",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:46:15.942Z",
      "website": ""
    },
    {
      "address_link": "",
      "address_text": "Ch. de la Seymaz, 21, 1253 Vandoeuvres",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:46:14.369Z",
      "description": "",
      "email": "anawhitaker@bluewin.ch",
      "faq": null,
      "first_name": "Ana Paula",
      "hidden": false,
      "id": "4r8wvsr1t3h9t1l",
      "languages": ["ciwr918a5o8h84g", "g8reptg5cwnhvor"],
      "last_name": "Whitaker",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:48:14.845Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/MVN1q7RUV2rJbSFH9",
      "address_text": "14, Rue Giuseppe Motta, 1202 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:46:12.747Z",
      "description": "",
      "email": "cabinetnaturogeneve@gmail.com",
      "faq": null,
      "first_name": "Katia",
      "hidden": false,
      "id": "tpxidbtzxkd7m62",
      "languages": ["ciwr918a5o8h84g", "g8reptg5cwnhvor"],
      "last_name": "Walther",
      "phone_number": "766 155 696",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:48:13.747Z",
      "website": "https://www.cabinetnaturogeneve.com"
    },
    {
      "address_link": "https://maps.app.goo.gl/5eAFL4SaZKDpoAtv5",
      "address_text": "Av. Vibert 27, 1227 Carouge GE",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:46:11.118Z",
      "description": "",
      "email": "",
      "faq": null,
      "first_name": "Caroline",
      "hidden": false,
      "id": "5gq313i5t7yz0jt",
      "languages": [],
      "last_name": "Vuille",
      "phone_number": "223 423 118",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:46:11.118Z",
      "website": "https://www.psychonutrition.ch"
    },
    {
      "address_link": "",
      "address_text": "8a, Chemin Babel - Bardonnex, 1257 La Croix-de-Rozon",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:46:10.108Z",
      "description": "",
      "email": "",
      "faq": null,
      "first_name": "Rémy",
      "hidden": false,
      "id": "x87ycpgrz4bwipm",
      "languages": [],
      "last_name": "Von Tobel",
      "phone_number": "227 715 008",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:46:10.108Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/aoWYCGhMVWu7pCBf6",
      "address_text": "Route de Choulex, 142, 1244 Choulex",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:46:08.961Z",
      "description": "",
      "email": "info@cvsnutrition.ch",
      "faq": null,
      "first_name": "Cornelia",
      "hidden": false,
      "id": "9b04euy1bjsjm80",
      "languages": [],
      "last_name": "Von Siebenthal",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:46:08.961Z",
      "website": "https://www.cvsnutrition.ch"
    },
    {
      "address_link": "",
      "address_text": "Route de Gy 22, 1252 Meinier",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:46:07.343Z",
      "description": "",
      "email": "caroline@cvnutrition.ch",
      "faq": null,
      "first_name": "Caroline",
      "hidden": false,
      "id": "q2tklhm1uamyehg",
      "languages": [],
      "last_name": "Vogt",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:46:07.343Z",
      "website": "http://www.cvnutriton.ch"
    },
    {
      "address_link": "https://maps.app.goo.gl/8bmjuFqC7uNrHSJs5",
      "address_text": "Rue St.Léger 24-26, 1204 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:46:05.462Z",
      "description": "",
      "email": "vivo.naturopathie@gmail.com",
      "faq": null,
      "first_name": "Alizée",
      "hidden": false,
      "id": "70l7vu3ror1t8vn",
      "languages": [],
      "last_name": "Vivard",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:46:05.462Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/kChjiyeF1CbZouAJ8",
      "address_text": "6 avenue de Frontenex, 1207 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:46:03.849Z",
      "description": "",
      "email": "alterapie@gmail.com",
      "faq": null,
      "first_name": "Julie",
      "hidden": false,
      "id": "sva6k40ndag8ki6",
      "languages": [],
      "last_name": "Vinson",
      "phone_number": "774 039 894",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:46:03.849Z",
      "website": "https://www.naturopathe-ge.ch"
    },
    {
      "address_link": "",
      "address_text": "14 Chemin des Clochettes, 1206 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:46:02.219Z",
      "description": "",
      "email": "rachelvillard@stelior.com",
      "faq": null,
      "first_name": "Rachel",
      "hidden": false,
      "id": "1djz8k5ij5grgcs",
      "languages": [],
      "last_name": "Villard",
      "phone_number": "227 513 636",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:46:02.219Z",
      "website": "https://stelior.com"
    },
    {
      "address_link": "",
      "address_text": "6, Av. Pictet de Rochemont, 1207 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:46:00.658Z",
      "description": "",
      "email": "",
      "faq": null,
      "first_name": "Nadia",
      "hidden": false,
      "id": "udkv69erxhrazzq",
      "languages": [],
      "last_name": "Viatte",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:46:00.658Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/Jzh5xaTmbW148HdUA",
      "address_text": "boîte postale 1208, Rue Henri-Mussard, 8, 1208 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:45:59.574Z",
      "description": "",
      "email": "vsev1@hotmail.com",
      "faq": null,
      "first_name": "Séverine",
      "hidden": false,
      "id": "c8fukmemxhf2abf",
      "languages": [],
      "last_name": "Vian",
      "phone_number": "789 518 221",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:45:59.574Z",
      "website": "http://www.svian.ch"
    },
    {
      "address_link": "https://maps.app.goo.gl/naS7TUVtvxsRGAUTA",
      "address_text": "Rue de la Cité 11, 1204 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:45:57.963Z",
      "description": "",
      "email": "s.vernier.naturo@bluewin.ch",
      "faq": null,
      "first_name": "Sophie",
      "hidden": false,
      "id": "mciciol7axfcf83",
      "languages": [],
      "last_name": "Vernier Prillard",
      "phone_number": "223 101 553",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:45:57.963Z",
      "website": ""
    },
    {
      "address_link": "",
      "address_text": "Route de Florissant 177, 1231 Conches",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:45:56.414Z",
      "description": "",
      "email": "contact.nelcyfavard@gmail.com",
      "faq": null,
      "first_name": "Nelcy",
      "hidden": false,
      "id": "vw4c4jfojolvzrh",
      "languages": [],
      "last_name": "Vernay-Favard",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:45:56.414Z",
      "website": "http://nelcyfavard.com"
    },
    {
      "address_link": "",
      "address_text": "c/o Espace Santé Esclarmonde, Avenue Soret 39, 1203 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:45:54.756Z",
      "description": "",
      "email": "colombe.verges@gmail.com",
      "faq": null,
      "first_name": "Colombe",
      "hidden": false,
      "id": "5hxoygbq7t4chmf",
      "languages": [],
      "last_name": "Vergès Holloway",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:45:54.756Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/cX7PcZV5Pjrehfdv7",
      "address_text": "21 Av. de Choiseul, 1290 Versoix",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:45:53.149Z",
      "description": "",
      "email": "luisafernandacely@hotmail.com",
      "faq": null,
      "first_name": "Luisa",
      "hidden": false,
      "id": "vsodhmjddban7f5",
      "languages": [],
      "last_name": "Velasquez",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:45:53.149Z",
      "website": "https://nutrihomeo.com"
    },
    {
      "address_link": "",
      "address_text": "Boulevard Carl-Vogt 9, 1205 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:45:51.649Z",
      "description": "",
      "email": "laureveenendaal@gmail.com",
      "faq": null,
      "first_name": "Laure",
      "hidden": false,
      "id": "d1uecu78pya550w",
      "languages": [],
      "last_name": "Veenendaal",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:45:51.649Z",
      "website": ""
    },
    {
      "address_link": "",
      "address_text": "Ch. des Hauts-Crêts 100A, 1253 Vandoeuvres",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:45:50.012Z",
      "description": "",
      "email": "nutrition.vives@gmail.com",
      "faq": null,
      "first_name": "Irina",
      "hidden": false,
      "id": "j0e3upce0lypd4y",
      "languages": [],
      "last_name": "Vazzoler-Konoshenko",
      "phone_number": "223 400 000",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:45:50.012Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/2gjhMQ899HEn1mFb9",
      "address_text": "La Voie-de-Moëns 3, 1218 Le Grand-Saconnex",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:45:48.325Z",
      "description": "",
      "email": "laurie.todeschini@gmail.com",
      "faq": null,
      "first_name": "Laurie",
      "hidden": false,
      "id": "wcygflwk9e230ch",
      "languages": [],
      "last_name": "Todeschini",
      "phone_number": "764 121 982",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:45:48.325Z",
      "website": "https://nutristep.ch"
    },
    {
      "address_link": "https://maps.app.goo.gl/6fAWV8r4reYY36FM7",
      "address_text": "Chemin des Mollies 36, 1293 Bellevue",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:45:46.630Z",
      "description": "",
      "email": "vterrier1223@gmail.com",
      "faq": null,
      "first_name": "Virginie",
      "hidden": false,
      "id": "nqbhz81zmzbhgwf",
      "languages": [],
      "last_name": "Terrier",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:45:46.630Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/FRJQpNHqVCdZVMCn9",
      "address_text": "Ch. Rieu 20, 1208 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:45:44.882Z",
      "description": "",
      "email": "info@nadiatanner.ch",
      "faq": null,
      "first_name": "Nadia",
      "hidden": false,
      "id": "tkktr0cezndlzqj",
      "languages": [],
      "last_name": "Tanner",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:45:44.882Z",
      "website": "www.nadiatanner.ch"
    },
    {
      "address_link": "",
      "address_text": "Avenue Louis-Pictet 10, 1214 Vernier",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:45:43.226Z",
      "description": "",
      "email": "lilla82.ls@gmail.com",
      "faq": null,
      "first_name": "Lilla",
      "hidden": false,
      "id": "v1w0bt71doy43yr",
      "languages": [],
      "last_name": "Szekely",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:45:43.226Z",
      "website": "www.ptlgeneva.ch"
    },
    {
      "address_link": "https://maps.app.goo.gl/tegZsMgzcLLGsEeeA",
      "address_text": "Cours de Rive 14, 1204 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:45:41.529Z",
      "description": "",
      "email": "deborah@steiner-christin.ch",
      "faq": null,
      "first_name": "Déborah",
      "hidden": false,
      "id": "07b8sdjx45h5pi9",
      "languages": [],
      "last_name": "Steiner",
      "phone_number": "227 365 696",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:45:41.529Z",
      "website": "https://steiner-christin.ch"
    },
    {
      "address_link": "",
      "address_text": "Av. de Tilleuls 15, 1203 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:45:39.883Z",
      "description": "",
      "email": "info@therapiesnaturelles.ch",
      "faq": null,
      "first_name": "DoaaLilian",
      "hidden": false,
      "id": "aocrr1qtwevccbd",
      "languages": ["ciwr918a5o8h84g", "g8reptg5cwnhvor", "egbe5ll1g9bq31l"],
      "last_name": "Spichtig",
      "phone_number": "223 444 207",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:48:00.905Z",
      "website": "https://www.therapiesnaturelles-geneve.ch"
    },
    {
      "address_link": "https://maps.app.goo.gl/ypZ8ntkNTdoVXKFk7",
      "address_text": "c/o Centre Aliotis, Bd Helvétique 30, 1207 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:45:38.283Z",
      "description": "",
      "email": "info@lecentredumicrobiote.ch",
      "faq": null,
      "first_name": "Jennifer",
      "hidden": false,
      "id": "2yba6aldlooov9j",
      "languages": [],
      "last_name": "Snopek",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:45:38.283Z",
      "website": "https://www.lecentredumicrobiote.ch"
    },
    {
      "address_link": "",
      "address_text": "Rue de Saint Jean 11, 1203 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:45:36.531Z",
      "description": "",
      "email": "julsimoni@gmail.com",
      "faq": null,
      "first_name": "Julien",
      "hidden": false,
      "id": "iwwtj3x8n216g2l",
      "languages": [],
      "last_name": "Simoni",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:45:36.531Z",
      "website": ""
    },
    {
      "address_link": "",
      "address_text": "10, rue de Richemont, 1202 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:45:34.670Z",
      "description": "",
      "email": "santenaturelle@mail.ch",
      "faq": null,
      "first_name": "Zaineb",
      "hidden": false,
      "id": "kkll04yz32yospw",
      "languages": [],
      "last_name": "Sghairi",
      "phone_number": "799 577 118",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:45:34.670Z",
      "website": "https://www.santenaturelle1.com"
    },
    {
      "address_link": "",
      "address_text": "boîte postale 1290, 21 Avenue de Choiseul, 1290 Versoix",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:45:32.920Z",
      "description": "",
      "email": "lida_serrano@me.com",
      "faq": null,
      "first_name": "Lida Maria",
      "hidden": false,
      "id": "ofcp87el7pt5qv4",
      "languages": ["ciwr918a5o8h84g", "g8reptg5cwnhvor"],
      "last_name": "Serrano Florez",
      "phone_number": "787 983 530",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:47:57.856Z",
      "website": ""
    },
    {
      "address_link": "",
      "address_text": "c/o c/o Centre Stelior, Quai du Seujet 16-18, 1201 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:45:31.246Z",
      "description": "",
      "email": "fabienne.selz@bluewin.ch",
      "faq": null,
      "first_name": "Fabienne",
      "hidden": false,
      "id": "iuvqmtxuj9zaz05",
      "languages": [],
      "last_name": "Selz",
      "phone_number": "227 523 877",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:45:31.246Z",
      "website": "http://www.stelior.org"
    },
    {
      "address_link": "",
      "address_text": "c/o C/O Mme Céline Gili, Rue du Lac 10, 1207 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:45:29.605Z",
      "description": "",
      "email": "roger.schenkel@gmx.net",
      "faq": null,
      "first_name": "Roger",
      "hidden": false,
      "id": "xivub8x6xjg3hkv",
      "languages": [],
      "last_name": "Schenkel",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:45:29.605Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/pg8KRQuuHUJhPRXp7",
      "address_text": "c/o CDR, boîte postale 1204, 6 cours de Rive, 1204 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:45:27.929Z",
      "description": "",
      "email": "ssazegari@me.com",
      "faq": null,
      "first_name": "Shima",
      "hidden": false,
      "id": "bbeqcogee5sf72b",
      "languages": ["ciwr918a5o8h84g", "g8reptg5cwnhvor"],
      "last_name": "Sazegari",
      "phone_number": "786 637 887",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:47:55.689Z",
      "website": "https://www.swiss-altermed.ch"
    },
    {
      "address_link": "https://maps.app.goo.gl/hyXWyFjDsSymrpfw6",
      "address_text": "Route de Colovrex 39, 1218 Le Grand-Saconnex",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:45:26.278Z",
      "description": "",
      "email": "ingridsavoy40@gmail.com",
      "faq": null,
      "first_name": "Ingrid",
      "hidden": false,
      "id": "22wewqj6mnm97nx",
      "languages": [],
      "last_name": "Savoy-Ayer",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:45:26.278Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/RUktxxMBNaJChWCA9",
      "address_text": "Rue Saint-Léger 24-26, 1204 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:45:24.706Z",
      "description": "",
      "email": "info@hygianatura.com",
      "faq": null,
      "first_name": "Ivone",
      "hidden": false,
      "id": "ql9hazzyt0ue1pb",
      "languages": [],
      "last_name": "Saraiva",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:45:24.706Z",
      "website": "http://www.hygianatura.com"
    },
    {
      "address_link": "",
      "address_text": "c/o Cabinet de Naturopathie, boîte postale 1290, 1 Route de Sauverny, 1290 Versoix",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:45:22.519Z",
      "description": "",
      "email": "synergiewellness@orange.fr",
      "faq": null,
      "first_name": "Luc",
      "hidden": false,
      "id": "d6mtcwj74py1fov",
      "languages": [],
      "last_name": "Sanchez",
      "phone_number": "793 509 427",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:45:22.519Z",
      "website": "https://www.synergiewellness.com"
    },
    {
      "address_link": "",
      "address_text": "Av. François-Adolphe Grison 3, 1225 Chêne-Bourg",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:45:20.858Z",
      "description": "",
      "email": "eruh.naturopathe@gmail.com",
      "faq": null,
      "first_name": "Ella",
      "hidden": false,
      "id": "dr8qass6lvdg142",
      "languages": [],
      "last_name": "Ruh",
      "phone_number": "796 736 596",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:45:20.858Z",
      "website": ""
    },
    {
      "address_link": "",
      "address_text": "18, chemin de Pomone, 1228 Plan-les-Ouates",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:45:19.100Z",
      "description": "",
      "email": "dordje@bluewin.ch",
      "faq": null,
      "first_name": "Sébastien",
      "hidden": false,
      "id": "llf725rpd9yr4qm",
      "languages": [],
      "last_name": "Rosset",
      "phone_number": "227 710 442",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:45:19.100Z",
      "website": ""
    },
    {
      "address_link": "",
      "address_text": "boîte postale CP 1391, Av. Wendt 56, 1203 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:45:17.332Z",
      "description": "",
      "email": "",
      "faq": null,
      "first_name": "Jorcelene",
      "hidden": false,
      "id": "3rfavuxr5brfe4r",
      "languages": [],
      "last_name": "Aparecida Rocon",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:45:17.332Z",
      "website": ""
    },
    {
      "address_link": "",
      "address_text": "Rue Alphonse Large 17, 1217 Meyrin",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:45:16.119Z",
      "description": "",
      "email": "asyrahsoins@hotmail.com",
      "faq": null,
      "first_name": "Michaël",
      "hidden": false,
      "id": "j8tv8tv7fsiknvc",
      "languages": [],
      "last_name": "Rochedix",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:45:16.119Z",
      "website": "www.asyrahsoins.ch"
    },
    {
      "address_link": "https://maps.app.goo.gl/3mANeyK8gRng6uTWA",
      "address_text": "boîte postale 1212, route du grand Lancy 81, 1212 Grand-Lancy",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:45:14.356Z",
      "description": "",
      "email": "rippoz.estelle@orange.fr",
      "faq": null,
      "first_name": "Estelle",
      "hidden": false,
      "id": "ahgwbb8dwo2n2ck",
      "languages": [],
      "last_name": "Rippoz",
      "phone_number": "765 027 028",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:45:14.356Z",
      "website": "https://soins-evasion.com"
    },
    {
      "address_link": "https://maps.app.goo.gl/8R2o9UXNBrvnxY9VA",
      "address_text": "Route de Malagnou 31, 1208 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:45:12.565Z",
      "description": "",
      "email": "contact@oriolomte.ch",
      "faq": null,
      "first_name": "Rémi",
      "hidden": false,
      "id": "6e74v05s3q9aw7x",
      "languages": [],
      "last_name": "Riolo",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:45:12.565Z",
      "website": "https://oriolomte.ch/"
    },
    {
      "address_link": "https://maps.app.goo.gl/yTmUGjDbddFUCtk26",
      "address_text": "Rue des Horlogers 7, 1227 Carouge GE",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:45:10.888Z",
      "description": "",
      "email": "rachel@sentraimer.com",
      "faq": null,
      "first_name": "Rachel",
      "hidden": false,
      "id": "8niq17m1g8wxw3a",
      "languages": [],
      "last_name": "Richterich",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:45:10.888Z",
      "website": "https://www.sentraimer.com"
    },
    {
      "address_link": "https://maps.app.goo.gl/ZDeq21r4w5GQzJei9",
      "address_text": "c/o Centre médical Artémis, Chemin Antoine- Verchère 6, 1217 Meyrin",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:45:09.275Z",
      "description": "",
      "email": "sriche@sante-femme.ch",
      "faq": null,
      "first_name": "Sarah",
      "hidden": false,
      "id": "w9fm8qibsw1xakq",
      "languages": [],
      "last_name": "Riche",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:45:09.275Z",
      "website": "http://www.sante-femme.ch"
    },
    {
      "address_link": "",
      "address_text": "boîte postale 1208, Route de Frontenex 118, 1208 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:45:07.680Z",
      "description": "",
      "email": "mitiaribeiro@gmail.com",
      "faq": null,
      "first_name": "Mitia",
      "hidden": false,
      "id": "0v9wym3tnava6eh",
      "languages": ["ciwr918a5o8h84g", "g8reptg5cwnhvor"],
      "last_name": "Ribeiro De Oliveira",
      "phone_number": "794 643 898",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:47:48.189Z",
      "website": "https://mitiaribeironutritionist.com"
    },
    {
      "address_link": "https://maps.app.goo.gl/7PTrSWiJGE8waVqM9",
      "address_text": "Av. Henri-Dunant 2, 1205 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:45:06.041Z",
      "description": "",
      "email": "info@melanierefine.com",
      "faq": null,
      "first_name": "Mélanie",
      "hidden": false,
      "id": "eb6gly5uzvi2ujt",
      "languages": [],
      "last_name": "Refine",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:45:06.041Z",
      "website": "www.melanierefine.ch"
    },
    {
      "address_link": "https://maps.app.goo.gl/tz54qMDY5172sWT56",
      "address_text": "Ruelle du Couchant, 11, 1207 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:45:04.457Z",
      "description": "",
      "email": "contact@virginie-raisin.ch",
      "faq": null,
      "first_name": "Virginie",
      "hidden": false,
      "id": "t4354obb0irc2en",
      "languages": [],
      "last_name": "Raisin",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:45:04.457Z",
      "website": "https://www.virginie-raisin.ch"
    },
    {
      "address_link": "",
      "address_text": "Ch. Sur-Rang 9, 1234 Vessy",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:45:02.716Z",
      "description": "",
      "email": "niloofarr@hotmail.com",
      "faq": null,
      "first_name": "Niloufar",
      "hidden": false,
      "id": "dtbulj8pxk97iep",
      "languages": [],
      "last_name": "Rahbari",
      "phone_number": "794 131 425",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:45:02.716Z",
      "website": "https://www.graindevie.org"
    },
    {
      "address_link": "",
      "address_text": "c/o C/O Centre Stelior, Quai du Seujet 16-18, 1201 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:45:01.091Z",
      "description": "",
      "email": "c.providenti@stelior.com",
      "faq": null,
      "first_name": "Carmela",
      "hidden": false,
      "id": "nimi5wh1scnpffg",
      "languages": [],
      "last_name": "Providenti",
      "phone_number": "227 512 636",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:45:01.091Z",
      "website": "http://www.stelior.org"
    },
    {
      "address_link": "https://maps.app.goo.gl/xMg3m3kf4J4smrAf9",
      "address_text": "Rue Cornavin 11, 1201 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:44:59.535Z",
      "description": "",
      "email": "a.procacci@atmos.ch",
      "faq": null,
      "first_name": "Andreas",
      "hidden": false,
      "id": "hlawhjclmu7sf2m",
      "languages": [],
      "last_name": "Procacci",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:44:59.535Z",
      "website": "https://www.atmos.ch"
    },
    {
      "address_link": "https://maps.app.goo.gl/PNqyV7oFkNte3avF6",
      "address_text": "boîte postale 1225, Chemin de la gravière 3, 1225 Chêne-Bourg",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:44:57.950Z",
      "description": "",
      "email": "fponsar@efficium.ch",
      "faq": null,
      "first_name": "Flora",
      "hidden": false,
      "id": "gb9k0ewd7hjwi7y",
      "languages": [],
      "last_name": "Ponsar",
      "phone_number": "764 669 888",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:44:57.950Z",
      "website": "https://www.efficium.cenas.ch/"
    },
    {
      "address_link": "https://maps.app.goo.gl/E1JFRWJVunJk1UJu9",
      "address_text": "c/o c/o Centre Aliotis, boîte postale 1207, Bd Helvétique 30, 1207 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:44:56.325Z",
      "description": "",
      "email": "eponcey@gmail.com",
      "faq": null,
      "first_name": "Elise",
      "hidden": false,
      "id": "rp1qvekm2t5z494",
      "languages": [],
      "last_name": "Poncey",
      "phone_number": "782 308 857",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:44:56.325Z",
      "website": "http://www.elisenaturopathie.com"
    },
    {
      "address_link": "",
      "address_text": "13, chemin des Coquelicots, 1214 Vernier",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:44:54.695Z",
      "description": "",
      "email": "cabinetcphomeo@gmail.com",
      "faq": null,
      "first_name": "Carine",
      "hidden": false,
      "id": "jpj36bykvrxvet7",
      "languages": [],
      "last_name": "Poncet",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:44:54.695Z",
      "website": "homeofamille.ch"
    },
    {
      "address_link": "",
      "address_text": "Grand-Montfleury, 14, 1290 Versoix",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:44:52.919Z",
      "description": "",
      "email": "ewelina.pisarskayui@gmail.com",
      "faq": null,
      "first_name": "Ewelina",
      "hidden": false,
      "id": "njlj5t81s1ojgn6",
      "languages": [],
      "last_name": "Pisarska-Yui",
      "phone_number": "215 522 424",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:44:52.919Z",
      "website": "http://www.seisaki.ch"
    },
    {
      "address_link": "",
      "address_text": "c/o Thérapies Naturelles, Avenue des Tilleuls 15, 1203 Saint-Jean-Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:44:51.181Z",
      "description": "",
      "email": "info@lagrainedevie.ch",
      "faq": null,
      "first_name": "Vanessa",
      "hidden": false,
      "id": "bcpinc6fglenbe7",
      "languages": [],
      "last_name": "Pini",
      "phone_number": "763 834 519",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:44:51.181Z",
      "website": "https://www.lagrainedevie.ch"
    },
    {
      "address_link": "https://maps.app.goo.gl/2NYqv9DsWv1Zc9HWA",
      "address_text": "Route de chêne 5, 1207 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:44:49.584Z",
      "description": "",
      "email": "be.eat.retreat@gmail.com",
      "faq": null,
      "first_name": "Mary-Laure",
      "hidden": false,
      "id": "fks68936u6rx0du",
      "languages": [],
      "last_name": "Pilet",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:44:49.584Z",
      "website": "http://be-eat.retreat.ch"
    },
    {
      "address_link": "",
      "address_text": "19, Rue Charles-Giron, 1203 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:44:47.961Z",
      "description": "",
      "email": "petrilora@gmail.com",
      "faq": null,
      "first_name": "Laura",
      "hidden": false,
      "id": "tqu6smgkahw45d6",
      "languages": [],
      "last_name": "Petrillo",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:44:47.961Z",
      "website": ""
    },
    {
      "address_link": "",
      "address_text": "c/o C/O Dr. Grigorios Notaridis, Place Natascha-de-Senger, 1245 Collonge-Bellerive",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:44:46.362Z",
      "description": "",
      "email": "angeliki.persynaki@hin.ch",
      "faq": null,
      "first_name": "Angeliki",
      "hidden": false,
      "id": "3ox09a9o1pykiur",
      "languages": ["ciwr918a5o8h84g", "g8reptg5cwnhvor"],
      "last_name": "Persynaki",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:47:40.324Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/SWGutKNkwpvg3cub9",
      "address_text": "Rue du Village 21, 1214 Vernier",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:44:44.635Z",
      "description": "",
      "email": "douceurdevivre@bluewin.ch",
      "faq": null,
      "first_name": "Françoise",
      "hidden": false,
      "id": "vkikwgu2r9d5dk4",
      "languages": [],
      "last_name": "Perret",
      "phone_number": "227 984 232",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:44:44.635Z",
      "website": "https://www.douceurdevivre.ch"
    },
    {
      "address_link": "",
      "address_text": "boîte postale Case postale 371, 16, Rue Dancet, 1211 Genève 4",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:44:43.096Z",
      "description": "",
      "email": "francoisperret@hotmail.com",
      "faq": null,
      "first_name": "François",
      "hidden": false,
      "id": "2bf246jwx1ucrp7",
      "languages": ["ciwr918a5o8h84g", "g8reptg5cwnhvor", "egbe5ll1g9bq31l"],
      "last_name": "Perret",
      "phone_number": "223 425 001",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:47:38.495Z",
      "website": "https://www.francoisperret.ch"
    },
    {
      "address_link": "https://maps.app.goo.gl/pSAyNNYCAUpbhKN4A",
      "address_text": "c/o Groupe Médical d'Onex, Rte de Loëx 3, 1213 Onex",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:44:41.533Z",
      "description": "",
      "email": "pernet@gmo.ch",
      "faq": null,
      "first_name": "Gaëlle",
      "hidden": false,
      "id": "1odgpyfrdz6qt62",
      "languages": [],
      "last_name": "Pernet Girard",
      "phone_number": "794 076 562",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:44:41.533Z",
      "website": ""
    },
    {
      "address_link": "",
      "address_text": "c/o Paola Pavarelli Bonvini, Chemin de Sous Caran 13C, 1222 Vésenaz",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:44:39.826Z",
      "description": "",
      "email": "paolarobert@wanadoo.fr",
      "faq": null,
      "first_name": "Paola",
      "hidden": false,
      "id": "79omuxnyd8vcvy8",
      "languages": ["g8reptg5cwnhvor", "09wr6j2qj6lsu6a"],
      "last_name": "Pavarelli Bonvini",
      "phone_number": "227 520 081",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:47:36.911Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/ypZ8ntkNTdoVXKFk7",
      "address_text": "c/o c/o Centre Aliotis, Bd Helvétique 30, 1207 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:44:38.032Z",
      "description": "",
      "email": "consultation@lunaturo.com",
      "faq": null,
      "first_name": "Ysaline",
      "hidden": false,
      "id": "w6dmiwf7zejaoxp",
      "languages": [],
      "last_name": "Pamingle",
      "phone_number": "225 476 169",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:44:38.032Z",
      "website": "http://www.lunaturo.com"
    },
    {
      "address_link": "https://maps.app.goo.gl/qYa3bdH98WDWP5w89",
      "address_text": "Ch. du Daru, 5, 1228 Plan-les-Ouates",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:44:36.526Z",
      "description": "",
      "email": "for-sante@bluewin.ch",
      "faq": null,
      "first_name": "Fabienne",
      "hidden": false,
      "id": "y2rejqxhjfjufb3",
      "languages": [],
      "last_name": "Okoekpen-Ryser",
      "phone_number": "227 710 570",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:44:36.526Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/ZbAxC1SoHEKPv92y6",
      "address_text": "c/o Tour Opale, Ch. de la Gravière 3, 1225 Chêne-Bourg",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:44:34.684Z",
      "description": "",
      "email": "ella@nutriella.ch",
      "faq": null,
      "first_name": "Ella",
      "hidden": false,
      "id": "bytm3fwmbxq7icb",
      "languages": ["ciwr918a5o8h84g", "g8reptg5cwnhvor"],
      "last_name": "Odman",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:47:34.787Z",
      "website": "http://www.nutriella.ch"
    },
    {
      "address_link": "https://maps.app.goo.gl/MeT3nqmpSbhctzQc9",
      "address_text": "c/o C/O Dr. Monnier, Bd. Helvétique 16, 1207 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:44:33.099Z",
      "description": "",
      "email": "nutrition.tatiana@gmail.com",
      "faq": null,
      "first_name": "Tatiana",
      "hidden": false,
      "id": "3uwivmqe4a2z6p1",
      "languages": [],
      "last_name": "Nourisson",
      "phone_number": "227 861 710",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:44:33.099Z",
      "website": "https://www.nutritatiana.ch"
    },
    {
      "address_link": "",
      "address_text": "Rue de Hesse 1, 1204 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:44:31.487Z",
      "description": "",
      "email": "perlesdephenix@bluewin.ch",
      "faq": null,
      "first_name": "Belen",
      "hidden": false,
      "id": "7n3pw8g0ttlc0a3",
      "languages": [],
      "last_name": "Nion Eklu",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:44:31.487Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/vCQMms18WWgDZ2ma8",
      "address_text": "Rte de Covéry 21, 1252 Meinier",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:44:29.675Z",
      "description": "",
      "email": "charlotte.niklaus@gmail.com",
      "faq": null,
      "first_name": "Charlotte",
      "hidden": false,
      "id": "3tor3l82hhewyrf",
      "languages": [],
      "last_name": "Niklaus",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:44:29.675Z",
      "website": "http://www.niklaus-nutrition.ch"
    },
    {
      "address_link": "https://maps.app.goo.gl/CAsmv1qzFe6ZpV7M8",
      "address_text": "Rue des Vollandes 40, 1207 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:44:27.972Z",
      "description": "",
      "email": "i.nicollier@icloud.com",
      "faq": null,
      "first_name": "Ingrid",
      "hidden": false,
      "id": "nzel67jmng7ubb1",
      "languages": [],
      "last_name": "Nicollier",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:44:27.972Z",
      "website": "https://in-studio.ch"
    },
    {
      "address_link": "https://maps.app.goo.gl/d6xZWtLnQxV7GSPG6",
      "address_text": "Rue du Mont-Blanc 20, 1201 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:44:26.345Z",
      "description": "",
      "email": "contact@corpsains.ch",
      "faq": null,
      "first_name": "Stefania",
      "hidden": false,
      "id": "wqwr051le19qgyc",
      "languages": [],
      "last_name": "Neves",
      "phone_number": "227 317 380",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:44:26.345Z",
      "website": "www.corpsains.ch"
    },
    {
      "address_link": "",
      "address_text": "Bd des Philosophes 14, 1205 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:44:24.590Z",
      "description": "",
      "email": "s.neri@nutrition-forme.ch",
      "faq": null,
      "first_name": "Alfonsa-Sandra",
      "hidden": false,
      "id": "luq14xmv0uze431",
      "languages": [],
      "last_name": "Neri",
      "phone_number": "227 431 416",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:44:24.590Z",
      "website": ""
    },
    {
      "address_link": "",
      "address_text": "Avenue de Gallatin 4, 1203 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:44:23.057Z",
      "description": "",
      "email": "info@nutritionbypavlina.com",
      "faq": null,
      "first_name": "Pavlina",
      "hidden": false,
      "id": "zdg5jjm2igyvgxd",
      "languages": [],
      "last_name": "Mysliveckova",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:44:23.057Z",
      "website": "https://www.nutritionbypavlina.com"
    },
    {
      "address_link": "",
      "address_text": "Rue Marignac 9, 1206 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:44:21.461Z",
      "description": "",
      "email": "n.myaz@nutrition-forme.ch",
      "faq": null,
      "first_name": "Nathalie",
      "hidden": false,
      "id": "8efrpm0kr0sls3o",
      "languages": [],
      "last_name": "Myaz",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:44:21.461Z",
      "website": "http://www.nutrition-forme.ch"
    },
    {
      "address_link": "",
      "address_text": "Route de Sous-Moulin 29, 1225 Chêne-Bourg",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:44:19.863Z",
      "description": "",
      "email": "valeriemoser.naturopathe@gmail.com",
      "faq": null,
      "first_name": "Valérie",
      "hidden": false,
      "id": "k6k072k4x7mgmkq",
      "languages": [],
      "last_name": "Moser",
      "phone_number": "799 578 262",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:44:19.863Z",
      "website": "http://www.naturopathe-reflexogue.ch"
    },
    {
      "address_link": "https://maps.app.goo.gl/8k21tTebEZtB48ycA",
      "address_text": "Rue François-Meunier 9b, 1227 Carouge GE",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:44:18.288Z",
      "description": "",
      "email": "karenmorand@naturofemme.ch",
      "faq": null,
      "first_name": "Karen",
      "hidden": false,
      "id": "w7don2ynreenvlk",
      "languages": [],
      "last_name": "Morand",
      "phone_number": "223 017 465",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:44:18.288Z",
      "website": "www.naturofemme.ch"
    },
    {
      "address_link": "https://maps.app.goo.gl/q1cU1ecZa3irdBhk9",
      "address_text": "Bld Helvétique, 16, 1207 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:44:16.654Z",
      "description": "",
      "email": "phm@ri-sante.ch",
      "faq": null,
      "first_name": "Philippe",
      "hidden": false,
      "id": "r0recs8zurede3x",
      "languages": [],
      "last_name": "Monnier",
      "phone_number": "227 861 710",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:44:16.654Z",
      "website": "https://www.pmonnier-osteo-acu-naturo-geneve.ch/"
    },
    {
      "address_link": "https://maps.app.goo.gl/GNMpRgMuKypegLmq9",
      "address_text": "c/o Centre Otium, Place des Philosophes 18, 1205 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:44:15.076Z",
      "description": "",
      "email": "aml-hypno-coaching@bluewin.ch",
      "faq": null,
      "first_name": "Aldina",
      "hidden": false,
      "id": "l0x1yp4wga8btx2",
      "languages": [],
      "last_name": "Monnat-Lanfranchi",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:44:15.076Z",
      "website": "https://www.aml-hypno-coaching.ch"
    },
    {
      "address_link": "https://maps.app.goo.gl/a6h7mcMWRDQxWbnL7",
      "address_text": "Rue Marignac, 6, 1206 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:44:13.484Z",
      "description": "",
      "email": "",
      "faq": null,
      "first_name": "Marie",
      "hidden": false,
      "id": "rcd4k8omiww3xit",
      "languages": [],
      "last_name": "Moatti",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:44:13.484Z",
      "website": "https://www.mariemoatti.com"
    },
    {
      "address_link": "https://maps.app.goo.gl/G8dD1ZQwS6RQJgvh9",
      "address_text": "Chemin du Pré-Colomb 10, 1290 Versoix",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:44:12.438Z",
      "description": "",
      "email": "Contact@nutrima.ch",
      "faq": null,
      "first_name": "Naima",
      "hidden": false,
      "id": "ykllacmcqp6u2ai",
      "languages": [],
      "last_name": "Miserocchi",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:44:12.438Z",
      "website": "https://www.nutrima.ch"
    },
    {
      "address_link": "https://maps.app.goo.gl/qmVJc6eJKZpRmV2m8",
      "address_text": "c/o Trajectoire 10, Rue de la Filature 13, 1227 Carouge GE",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:44:10.918Z",
      "description": "",
      "email": "info@nathuresens.ch",
      "faq": null,
      "first_name": "Nathalie",
      "hidden": false,
      "id": "pnd2hgbji7koopy",
      "languages": [],
      "last_name": "Milhem",
      "phone_number": "786 050 278",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:44:10.918Z",
      "website": "http://www.nathuresens.ch"
    },
    {
      "address_link": "https://maps.app.goo.gl/H8g42w7ct7UPYZCD8",
      "address_text": "Ch. des Cornillons-30, 1292 Chambésy",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:44:09.266Z",
      "description": "",
      "email": "sfmichon@yahoo.fr",
      "faq": null,
      "first_name": "Solange",
      "hidden": false,
      "id": "broxqgz51b9d1o7",
      "languages": [],
      "last_name": "Michon",
      "phone_number": "763 750 347",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:44:09.266Z",
      "website": ""
    },
    {
      "address_link": "",
      "address_text": "c/o Centre de Thérapies Naturelles, Chemin du Vieux Vesenaz, 8, 1222 Vésenaz",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:44:07.601Z",
      "description": "",
      "email": "",
      "faq": null,
      "first_name": "Carole",
      "hidden": false,
      "id": "ypa7ruef35t0yav",
      "languages": [],
      "last_name": "Michit",
      "phone_number": "764 749 526",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:44:07.601Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/d6Tiyozg7hX2j67x9",
      "address_text": "Av. Dumas 17, 1206 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:44:06.513Z",
      "description": "",
      "email": "",
      "faq": null,
      "first_name": "Alexandre",
      "hidden": false,
      "id": "fqewj3s204fnzc6",
      "languages": [],
      "last_name": "Mezzorana",
      "phone_number": "227 002 440",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:44:06.513Z",
      "website": ""
    },
    {
      "address_link": "",
      "address_text": "Chemin de Néry 40, 1285 Avusy",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:44:05.311Z",
      "description": "",
      "email": "contact@koha-soins.ch",
      "faq": null,
      "first_name": "Camille",
      "hidden": false,
      "id": "qqj3gvekxkyrwln",
      "languages": ["ciwr918a5o8h84g", "g8reptg5cwnhvor"],
      "last_name": "Meyer",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:47:22.962Z",
      "website": "https://www.koha-soins.ch"
    },
    {
      "address_link": "",
      "address_text": "Ch du Domaine-patry 8b, 1224 Chêne-Bougeries",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:44:03.699Z",
      "description": "",
      "email": "anoukmey@hotmail.com",
      "faq": null,
      "first_name": "Anouk",
      "hidden": false,
      "id": "6pryl7tcjalhr87",
      "languages": [],
      "last_name": "Meyenberg",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:44:03.699Z",
      "website": "http://www.pilatesmotion.ch"
    },
    {
      "address_link": "https://maps.app.goo.gl/2NQi13DdgWG8LXn5A",
      "address_text": "c/o Espace Pretty View, Chemin Rilliet 6, 1293 Bellevue",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:44:02.055Z",
      "description": "",
      "email": "contact@angelamerleau.com",
      "faq": null,
      "first_name": "Angèla",
      "hidden": false,
      "id": "aan1xtnf8hfjhjx",
      "languages": [],
      "last_name": "Merleau-Bernard",
      "phone_number": "795 002 611",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:44:02.055Z",
      "website": "http://www.angelamerleau.com"
    },
    {
      "address_link": "",
      "address_text": "c/o C/O Mme Doris Merkly, Rue François Jacquier 9, 1225 Chêne-Bourg",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:44:00.383Z",
      "description": "",
      "email": "m@merkly.ch",
      "faq": null,
      "first_name": "Michael",
      "hidden": false,
      "id": "ja6vcx64uwr38g1",
      "languages": [],
      "last_name": "Merkly",
      "phone_number": "223 489 016",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:44:00.383Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/FF2R5FiYvkFpwBnk7",
      "address_text": "c/o Centre Aliotis, boîte postale 1207, Boulevard Helvétique 30, 1207 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:43:58.537Z",
      "description": "",
      "email": "contact@louisameriah.com",
      "faq": null,
      "first_name": "Louisa",
      "hidden": false,
      "id": "df84vblzhqpssud",
      "languages": ["ciwr918a5o8h84g", "g8reptg5cwnhvor"],
      "last_name": "Meriah",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:47:20.263Z",
      "website": ""
    },
    {
      "address_link": "",
      "address_text": "c/o Institut Maharishi, Av. Wendt 56, 1203 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:43:56.876Z",
      "description": "",
      "email": "info@institut-maharishi.ch",
      "faq": null,
      "first_name": "Philippe",
      "hidden": false,
      "id": "ihvjtw82oj2bvgy",
      "languages": ["ciwr918a5o8h84g", "g8reptg5cwnhvor"],
      "last_name": "Mercanton",
      "phone_number": "229 402 304",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:47:19.106Z",
      "website": "https://www.onedoc.ch/fr/naturopathe-en-medecine-ayurvedique/geneve/pclcf/philippe-mercanton"
    },
    {
      "address_link": "",
      "address_text": "Rue Firmin-Massot 1, 1205 Champel GE",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:43:55.326Z",
      "description": "",
      "email": "Andreamenna.nutri@gmail.com",
      "faq": null,
      "first_name": "Andrea",
      "hidden": false,
      "id": "n2tvy1fayeihtg5",
      "languages": ["g8reptg5cwnhvor", "09wr6j2qj6lsu6a"],
      "last_name": "Menna",
      "phone_number": "768 224 595",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:47:17.984Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/RQJXuCALSJixhjHb8",
      "address_text": "c/o TAC, Chemin des Pâquerettes 25, 1213 Petit-Lancy",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:43:53.684Z",
      "description": "",
      "email": "marisa.medici@infomaniak.ch",
      "faq": null,
      "first_name": "Marisa",
      "hidden": false,
      "id": "457orvpxa0179le",
      "languages": [],
      "last_name": "Medici",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:43:53.684Z",
      "website": "https://www.naturamedici.ch"
    },
    {
      "address_link": "",
      "address_text": "Chemin des Serres 9, 1234 Vessy",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:43:52.023Z",
      "description": "",
      "email": "scotti@investigative-health.ch",
      "faq": null,
      "first_name": "Alexandra",
      "hidden": false,
      "id": "ue33mt3fd0fwh7w",
      "languages": [],
      "last_name": "Scott Mclaren",
      "phone_number": "767 712 553",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:43:52.023Z",
      "website": "https://www.investigative-health.ch"
    },
    {
      "address_link": "https://maps.app.goo.gl/Nq99Z62egvHB3PTY8",
      "address_text": "c/o Sarah Maurer - naturaSoi, Route des Acacias 6, 1227 Les Acacias",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:43:50.416Z",
      "description": "",
      "email": "info@naturasoi.ch",
      "faq": null,
      "first_name": "Sarah",
      "hidden": false,
      "id": "in8ecsog9hduwwi",
      "languages": [],
      "last_name": "Maurer",
      "phone_number": "794 898 690",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:43:50.416Z",
      "website": "https://www.naturasoi.ch/"
    },
    {
      "address_link": "https://maps.app.goo.gl/KpqTXyY3up67ASM97",
      "address_text": "c/o Dr. f. Riedlinger, 5 ch Malombré, 1206 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:43:48.751Z",
      "description": "",
      "email": "info@astrid-nutrition.ch",
      "faq": null,
      "first_name": "Astrid",
      "hidden": false,
      "id": "tj9h3js1g30hfn8",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Matute-Huttenlocher",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:48:35.493Z",
      "website": "www.nutrition.ch"
    },
    {
      "address_link": "https://maps.app.goo.gl/8STgGoi5JMJf96fVA",
      "address_text": "c/o c/o Medh Sa, Chemin Bizot 4, 1208 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:43:47.162Z",
      "description": "",
      "email": "mmartin.naturo@gmail.com",
      "faq": null,
      "first_name": "Mélanie",
      "hidden": false,
      "id": "oeub0e21wki060o",
      "languages": [],
      "last_name": "Martin",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:43:47.162Z",
      "website": ""
    },
    {
      "address_link": "",
      "address_text": "Rte de Thonon 87A, 1222 Vésenaz",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:43:45.539Z",
      "description": "",
      "email": "contact@christinemarello.ch",
      "faq": null,
      "first_name": "Christine",
      "hidden": false,
      "id": "jqx1ozyxtgudhq7",
      "languages": [],
      "last_name": "Marello",
      "phone_number": "227 525 268",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:43:45.539Z",
      "website": "www.christinemarello.ch"
    },
    {
      "address_link": "https://maps.app.goo.gl/PHhQwxgvtcsf6FE79",
      "address_text": "c/o Aliotis, Boulevard Helvétique 30, 1207 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:43:43.827Z",
      "description": "",
      "email": "info@nourritures.ch",
      "faq": null,
      "first_name": "Marie-Hélène",
      "hidden": false,
      "id": "tzm1knv94jugjrj",
      "languages": [],
      "last_name": "Marcassin",
      "phone_number": "782 402 803",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:43:43.827Z",
      "website": "https://www.nourritures.ch"
    },
    {
      "address_link": "https://maps.app.goo.gl/7o6SELaYdRqivz2v7",
      "address_text": "boîte postale 1227, Rue de la Fontenette 23, 1227 Carouge GE",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:43:42.232Z",
      "description": "",
      "email": "cmc.naturo@gmail.com",
      "faq": null,
      "first_name": "Christine",
      "hidden": false,
      "id": "u7y3ba229lvt1lv",
      "languages": [],
      "last_name": "Marcais",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:43:42.232Z",
      "website": "http://www.phyto-naturo.ch"
    },
    {
      "address_link": "",
      "address_text": "c/o TAC, Chemin des Pâquerettes 25, 1213 Petit-Lancy",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:43:40.473Z",
      "description": "",
      "email": "",
      "faq": null,
      "first_name": "Noémie",
      "hidden": false,
      "id": "67qq3denkpy3bo6",
      "languages": [],
      "last_name": "Magnin",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:43:40.473Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/GZPXcuxtBLs8s8rE7",
      "address_text": "c/o Eveil des sens, boîte postale 1293, 18 rue des Caroubiers, 1227 Carouge GE",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:43:39.389Z",
      "description": "",
      "email": "laviesaineavecsara@gmail.com",
      "faq": null,
      "first_name": "Sara",
      "hidden": false,
      "id": "swesk4gcx3onjcy",
      "languages": [],
      "last_name": "Luna",
      "phone_number": "786 690 426",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:43:39.389Z",
      "website": "https://laviesaineavecsara.ch"
    },
    {
      "address_link": "",
      "address_text": "c/o c/o Regus 2ème étage, Rue de la Croix-du-Levant 11, 1220 Les Avanchets",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:43:37.623Z",
      "description": "",
      "email": "contact@zenutrition.ch",
      "faq": null,
      "first_name": "Clarence",
      "hidden": false,
      "id": "aoofahqzhatii5i",
      "languages": [],
      "last_name": "Loosli",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:43:37.623Z",
      "website": "https://www.zenutrition.ch"
    },
    {
      "address_link": "",
      "address_text": "Rue du Grand-Bureau 33, 1227 Les Acacias",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:43:35.963Z",
      "description": "",
      "email": "arik.levy@sunrise.ch",
      "faq": null,
      "first_name": "Arik",
      "hidden": false,
      "id": "w46c5bvjw0hozc1",
      "languages": [],
      "last_name": "Levy",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:43:35.963Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/tCsAxavVUH8ina2N6",
      "address_text": "Avenue de Champel 24, 1206 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:43:32.333Z",
      "description": "",
      "email": "dcohenaloro@gmail.com",
      "faq": null,
      "first_name": "Delphine",
      "hidden": false,
      "id": "ua6g87ktgbjnp1n",
      "languages": [],
      "last_name": "Leven",
      "phone_number": "223 477 070",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:43:32.333Z",
      "website": "https://www.myfoodbalance.com"
    },
    {
      "address_link": "https://maps.app.goo.gl/zZ1YUp6A2EphzkF16",
      "address_text": "c/o Bodystudio, 12, Route d'Hermance, 1222 Vésenaz",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:43:30.594Z",
      "description": "",
      "email": "contact@body-studio.ch",
      "faq": null,
      "first_name": "Véronique",
      "hidden": false,
      "id": "1ul485ccgn5wa0q",
      "languages": ["ciwr918a5o8h84g", "g8reptg5cwnhvor", "egbe5ll1g9bq31l"],
      "last_name": "Leu",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:47:09.571Z",
      "website": "https://www.body-studio.ch"
    },
    {
      "address_link": "",
      "address_text": "Rue Carqueon 5, 1220 Les Avanchets",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:43:28.925Z",
      "description": "",
      "email": "corinne-akerib@orange.fr",
      "faq": null,
      "first_name": "Corinne",
      "hidden": false,
      "id": "68e0dbk9yeeaypq",
      "languages": [],
      "last_name": "Lequint Akerib",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:43:28.925Z",
      "website": ""
    },
    {
      "address_link": "",
      "address_text": "Quai du Cheval-Blanc 2, 1227 Carouge GE",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:43:27.206Z",
      "description": "",
      "email": "jessica.leocata@bluewin.ch",
      "faq": null,
      "first_name": "Jessica",
      "hidden": false,
      "id": "8gtalfcn91hutoc",
      "languages": [],
      "last_name": "Leocata",
      "phone_number": "223 420 212",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:43:27.206Z",
      "website": ""
    },
    {
      "address_link": "",
      "address_text": "Av. de Riant-Parc 18, 1209 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:43:25.474Z",
      "description": "",
      "email": "nutritionfutur@gmail.com",
      "faq": null,
      "first_name": "Elena",
      "hidden": false,
      "id": "y9huy1o5r8qjkkg",
      "languages": ["ciwr918a5o8h84g", "g8reptg5cwnhvor"],
      "last_name": "Leguizamon Acosta",
      "phone_number": "783 005 021",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:47:07.322Z",
      "website": "https://www.el-nutrition.ch"
    },
    {
      "address_link": "https://g.co/kgs/ZGQKf9J",
      "address_text": "Bd des Philosophes 14, 1205 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:43:23.816Z",
      "description": "",
      "email": "carole@ledroit.ch",
      "faq": null,
      "first_name": "Carole",
      "hidden": false,
      "id": "zxyjua34xo5skae",
      "languages": [],
      "last_name": "Ledroit",
      "phone_number": "789 298 175",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:43:23.816Z",
      "website": ""
    },
    {
      "address_link": "https://g.co/kgs/ERupJZx",
      "address_text": "11, Rue de Cornavin, 1201 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:43:22.270Z",
      "description": "",
      "email": "geneve@suisse-nutritionniste.ch",
      "faq": null,
      "first_name": "Patrick",
      "hidden": false,
      "id": "guymdo5vgkpi7ab",
      "languages": [],
      "last_name": "Leconte",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:43:22.270Z",
      "website": "www.suisse-nutritionniste.ch"
    },
    {
      "address_link": "https://maps.app.goo.gl/ypZ8ntkNTdoVXKFk7",
      "address_text": "c/o c/o Centre Aliotis, Bd Helvétique 30, 1207 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:43:20.605Z",
      "description": "",
      "email": "info@gaia-naturopathie.ch",
      "faq": null,
      "first_name": "Stéphanie",
      "hidden": false,
      "id": "jdnf3h65y6sp0px",
      "languages": [],
      "last_name": "Le Jossec Hatzl",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:43:20.605Z",
      "website": "http://www.gaia-naturopathie.ch"
    },
    {
      "address_link": "https://maps.app.goo.gl/D2NkTcehtDHVxSdb7",
      "address_text": "chemin de la petite Boissière 38, 1208 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:43:19.009Z",
      "description": "",
      "email": "",
      "faq": null,
      "first_name": "Philippe",
      "hidden": false,
      "id": "r4m651wti7qiivw",
      "languages": [],
      "last_name": "Lavigne",
      "phone_number": "787 929 076",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:43:19.009Z",
      "website": "https://o2chemins.ch/"
    },
    {
      "address_link": "https://maps.app.goo.gl/U5jmbQwDH59Hu8xg9",
      "address_text": "c/o c/o Kiu Caracani, Rue Caroline, 1227 Les Acacias",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:43:17.906Z",
      "description": "",
      "email": "ml@therapeuteholistique.ch",
      "faq": null,
      "first_name": "Melissa",
      "hidden": false,
      "id": "anzsyyng8ixs79i",
      "languages": [],
      "last_name": "Laurin",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:43:17.906Z",
      "website": "www.therapeuteholistique.ch"
    },
    {
      "address_link": "",
      "address_text": "Rue des Rois 3, 1204 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:43:16.211Z",
      "description": "",
      "email": "",
      "faq": null,
      "first_name": "Myriam",
      "hidden": false,
      "id": "ec4m2vkxdhgs4re",
      "languages": [],
      "last_name": "Lambert",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:43:16.211Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/km6cmNAorrm8cMpC9",
      "address_text": "3 Rue Cavour, 1203 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:43:15.186Z",
      "description": "",
      "email": "terramarmonia@gmail.com",
      "faq": null,
      "first_name": "Alina",
      "hidden": false,
      "id": "el582z4rbpt5cgz",
      "languages": [],
      "last_name": "Lafferte",
      "phone_number": "787 441 158",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:43:15.186Z",
      "website": "https://www.terram-armonia.com"
    },
    {
      "address_link": "https://maps.app.goo.gl/2VDbkMdeueCJbozF6",
      "address_text": "Boulevard Helvétique 30, 1207 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:43:13.463Z",
      "description": "",
      "email": "cl-nutrition@outlook.com",
      "faq": null,
      "first_name": "Christèle",
      "hidden": false,
      "id": "t65qsna5csv14at",
      "languages": ["ciwr918a5o8h84g", "g8reptg5cwnhvor"],
      "last_name": "Lacher",
      "phone_number": "797 820 453",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:47:02.256Z",
      "website": "https://cl-nutrition.ch/"
    },
    {
      "address_link": "https://g.co/kgs/TtRx6PT",
      "address_text": "Chemin de la Seymaz 43, 1253 Vandoeuvres",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:43:11.623Z",
      "description": "",
      "email": "virginie@virginienutritionniste.com",
      "faq": null,
      "first_name": "Virginie",
      "hidden": false,
      "id": "v8o4zxldaucvgki",
      "languages": ["ciwr918a5o8h84g", "g8reptg5cwnhvor"],
      "last_name": "La Forgia",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:47:01.162Z",
      "website": "https://www.virginienutritionniste.com"
    },
    {
      "address_link": "",
      "address_text": "Ch. de Trémessaz 10, 1222 Vésenaz",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:43:09.988Z",
      "description": "",
      "email": "adrauser@bluewin.ch",
      "faq": null,
      "first_name": "Anne",
      "hidden": false,
      "id": "vqnidfg88a8ekvr",
      "languages": [],
      "last_name": "Krauser",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:43:09.988Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/gqNTnAXm3WjH9R5h8",
      "address_text": "c/o Cabinet Equilibre Genève, 12-14 Rue du Cendrier, 1201 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:43:08.190Z",
      "description": "",
      "email": "manuelakone66@outlook.com",
      "faq": null,
      "first_name": "Manuela",
      "hidden": false,
      "id": "j4ssh128kuukafw",
      "languages": [],
      "last_name": "Koné-Trifoglio",
      "phone_number": "788 890 581",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:43:08.190Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/MtHH3z5VQbAv4r8N7",
      "address_text": "22, Av. François-Besson, 1217 Meyrin",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:43:06.564Z",
      "description": "",
      "email": "caro.ka@bluewin.ch",
      "faq": null,
      "first_name": "Caroline",
      "hidden": false,
      "id": "wh981rbd7vcm64e",
      "languages": [],
      "last_name": "Känel",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:43:06.564Z",
      "website": "www.care-o-line.ch"
    },
    {
      "address_link": "",
      "address_text": "Rue de Lausanne 20, 1201 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:43:04.743Z",
      "description": "",
      "email": "cjinphi@gmail.com",
      "faq": null,
      "first_name": "Carlos",
      "hidden": false,
      "id": "l7gz9l1o5f9eos4",
      "languages": [],
      "last_name": "Jones",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:43:04.743Z",
      "website": ""
    },
    {
      "address_link": "",
      "address_text": "Rue François-Vessonex 15, 1207 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:43:02.988Z",
      "description": "",
      "email": "jolliet.n@gmail.com",
      "faq": null,
      "first_name": "Natallia",
      "hidden": false,
      "id": "qa1m7r8p8rj8284",
      "languages": [],
      "last_name": "Jolliet",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:43:02.988Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/ypZ8ntkNTdoVXKFk7",
      "address_text": "c/o Centre Aliotis, boîte postale 1207, Boulevard helvétique, 30, 1200 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:43:01.254Z",
      "description": "",
      "email": "busquetspauline@gmail.com",
      "faq": null,
      "first_name": "Pauline",
      "hidden": false,
      "id": "1oggv3yn9kwywie",
      "languages": ["ciwr918a5o8h84g", "g8reptg5cwnhvor"],
      "last_name": "Jesupret",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:46:57.325Z",
      "website": "https://paulinenaturo.ch"
    },
    {
      "address_link": "",
      "address_text": "Route de Forestial 67, 1285 Athenaz (Avusy)",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:42:59.701Z",
      "description": "",
      "email": "rdv@kh-nutrition.ch",
      "faq": null,
      "first_name": "Katie",
      "hidden": false,
      "id": "w6e4h9ma5jw52q6",
      "languages": [],
      "last_name": "Hutley",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:42:59.701Z",
      "website": "www.kh-nutrition.ch"
    },
    {
      "address_link": "",
      "address_text": "15, Av. du Simplon, 1225 Chêne-Bourg",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:42:57.969Z",
      "description": "",
      "email": "annick.hug@bluewin.ch",
      "faq": null,
      "first_name": "Annick Sandrine",
      "hidden": false,
      "id": "jp1xgorv86kyj70",
      "languages": [],
      "last_name": "Hug",
      "phone_number": "223 480 636",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:42:57.969Z",
      "website": ""
    },
    {
      "address_link": "",
      "address_text": "Henry Dunant, 1201 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:42:56.288Z",
      "description": "",
      "email": "s_hsv@icloud.com",
      "faq": null,
      "first_name": "Sonia",
      "hidden": false,
      "id": "k8aqxszbxoww2cw",
      "languages": ["ciwr918a5o8h84g", "g8reptg5cwnhvor"],
      "last_name": "Huezo Payet-Labonne",
      "phone_number": "768 189 050",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:46:55.209Z",
      "website": ""
    },
    {
      "address_link": "",
      "address_text": "Ave. Du Bois De La Chapelle 105, 1213 Onex",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:42:54.592Z",
      "description": "",
      "email": "ulahopkins@yahoo.fr",
      "faq": null,
      "first_name": "Urszula",
      "hidden": false,
      "id": "x3quvstwn4520t1",
      "languages": [],
      "last_name": "Hopkins",
      "phone_number": "223 622 974",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:42:54.592Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/tQu2yAxdZHrBYdED7",
      "address_text": "Rue de l'Athénée 35, 1206 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:42:53.036Z",
      "description": "",
      "email": "alexia@bloomtherapies.ch",
      "faq": null,
      "first_name": "Alexia",
      "hidden": false,
      "id": "0aqevi89kujbtf4",
      "languages": ["ciwr918a5o8h84g", "g8reptg5cwnhvor"],
      "last_name": "Holstenson",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:46:53.335Z",
      "website": "https://www.bloomtherapies.ch"
    },
    {
      "address_link": "https://maps.app.goo.gl/6hiiXu9G7vtgNsHF8",
      "address_text": "27 Rue Prevost-Martin, 1205 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:42:51.326Z",
      "description": "",
      "email": "naturoxalis@gmail.com",
      "faq": null,
      "first_name": "Charline",
      "hidden": false,
      "id": "v2cdcwq4t3oqnu5",
      "languages": [],
      "last_name": "Higelin",
      "phone_number": "225 366 070",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:42:51.326Z",
      "website": "www.naturoxalis.com"
    },
    {
      "address_link": "",
      "address_text": "Ch. de la Tulette 2, 1223 Cologny",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:42:49.561Z",
      "description": "",
      "email": "s_heritier@hotmail.com",
      "faq": null,
      "first_name": "Sylvie",
      "hidden": false,
      "id": "z8d7ak6u1t8mcl9",
      "languages": [],
      "last_name": "Heritier",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:42:49.561Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/Xb1mt5Dy8GanbDv37",
      "address_text": "15, Avenue des Tilleuls, 1203 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:42:47.852Z",
      "description": "",
      "email": "helarymaeva@hotmail.com",
      "faq": null,
      "first_name": "Maeva",
      "hidden": false,
      "id": "gypulw1kxw19ata",
      "languages": [],
      "last_name": "Helary",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:42:47.852Z",
      "website": ""
    },
    {
      "address_link": "",
      "address_text": "c/o C/O Curves, 39 Rue de Genève, 1226 Thônex",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:42:46.192Z",
      "description": "",
      "email": "tyffanie@eqilibrenutrition.com",
      "faq": null,
      "first_name": "Tyffanie",
      "hidden": false,
      "id": "0ngm1vggwzti89v",
      "languages": [],
      "last_name": "Hegg",
      "phone_number": "764 350 810",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:42:46.192Z",
      "website": "http://www.eqilibrenutrition.com"
    },
    {
      "address_link": "https://maps.app.goo.gl/B8LJUVC4nV9Vn46S6",
      "address_text": "Rte de Frontenex 100, 1208 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:42:44.541Z",
      "description": "",
      "email": "contact@virginiehedan.com",
      "faq": null,
      "first_name": "Virginie",
      "hidden": false,
      "id": "7lzp2aaa2m046bo",
      "languages": [],
      "last_name": "Hédan",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:42:44.541Z",
      "website": "https://www.virginiehedan.com"
    },
    {
      "address_link": "https://maps.app.goo.gl/YWz3Uvrvuh32sqYF8",
      "address_text": "Blanche 7, 1205 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:42:42.821Z",
      "description": "",
      "email": "hello@souliciouslyhanna.com",
      "faq": null,
      "first_name": "Hanna",
      "hidden": false,
      "id": "q44pl7a4jfq9f5y",
      "languages": ["ciwr918a5o8h84g", "g8reptg5cwnhvor"],
      "last_name": "Hanula",
      "phone_number": "782 028 888",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:46:49.106Z",
      "website": "http://www.souliciouslyhanna.com"
    },
    {
      "address_link": "",
      "address_text": "Ch. de Compostelle 5, 1212 Grand-Lancy",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:42:41.054Z",
      "description": "",
      "email": "",
      "faq": null,
      "first_name": "Karen",
      "hidden": false,
      "id": "elopc46wfus7odw",
      "languages": [],
      "last_name": "Guzman Del Rio",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:42:41.054Z",
      "website": ""
    },
    {
      "address_link": "",
      "address_text": "c/o C/O SJ Thérapies, Rue de Lyon 42 Bis, 1203 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:42:39.968Z",
      "description": "",
      "email": "contact@prenaya.com",
      "faq": null,
      "first_name": "Marjorie",
      "hidden": false,
      "id": "tkx1y7pzebtmtna",
      "languages": [],
      "last_name": "Guerrier",
      "phone_number": "766 082 775",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:42:39.968Z",
      "website": "https://www.prenaya.com"
    },
    {
      "address_link": "https://maps.app.goo.gl/Qt91M7VGJSu4Tsye9",
      "address_text": "c/o C/O Centre Aliotis, Boulevard Helvétique 30, 1207 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:42:38.354Z",
      "description": "",
      "email": "grosmaire.ainoa@gmail.com",
      "faq": null,
      "first_name": "Aïnoa",
      "hidden": false,
      "id": "8is598wpe84twm4",
      "languages": [],
      "last_name": "Grosmaire",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:42:38.354Z",
      "website": "https://www.onedoc.ch/fr/therapeute-en-nutrition/geneve/pcfq8/ainoa-grosmaire"
    },
    {
      "address_link": "",
      "address_text": "3, Rue Richard-Wagner, 1202 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:42:36.598Z",
      "description": "",
      "email": "meliusonia@gmail.com",
      "faq": null,
      "first_name": "Sonia",
      "hidden": false,
      "id": "jnj6wuh0cbw3zih",
      "languages": [],
      "last_name": "Groppi",
      "phone_number": "762 015 654",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:42:36.598Z",
      "website": "https://www.meliusonia.ch"
    },
    {
      "address_link": "",
      "address_text": "12 chemin Nant de creve-coeur, 1290 Versoix",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:42:34.735Z",
      "description": "",
      "email": "Miriam.grissi@yahoo.com",
      "faq": null,
      "first_name": "Miriam",
      "hidden": false,
      "id": "mnfu19j2gdy9pck",
      "languages": [],
      "last_name": "Grissi",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:42:34.735Z",
      "website": ""
    },
    {
      "address_link": "",
      "address_text": "Route de Saint Julien 144, 1228 Plan-les-Ouates",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:42:32.886Z",
      "description": "",
      "email": "",
      "faq": null,
      "first_name": "Beniamino",
      "hidden": false,
      "id": "etvi3nfpk6l8cdu",
      "languages": [],
      "last_name": "Greco",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:42:32.886Z",
      "website": ""
    },
    {
      "address_link": "",
      "address_text": "rue des Peupliers 26, 1205 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:42:31.707Z",
      "description": "",
      "email": "fannygrand@gmail.com",
      "faq": null,
      "first_name": "Fanny",
      "hidden": false,
      "id": "kp2xcq815o50mq4",
      "languages": [],
      "last_name": "Grand",
      "phone_number": "789 007 410",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:42:31.707Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/3KeaU4K5V75P1wa86",
      "address_text": "Chemin du Daru 11, 1228 Plan-les-Ouates",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:42:30.005Z",
      "description": "",
      "email": "emmanger@emmanger.fr",
      "faq": null,
      "first_name": "Emma",
      "hidden": false,
      "id": "80rjn9nshkmtdb1",
      "languages": [],
      "last_name": "Gourgaud",
      "phone_number": "782 540 213",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:42:30.005Z",
      "website": "https://www.emmanger.fr"
    },
    {
      "address_link": "https://maps.app.goo.gl/FvhCeKxC7GGqjMAv8",
      "address_text": "Rte des Jeunes 5, 1227 Les Acacias",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:42:27.958Z",
      "description": "",
      "email": "valnutrition2020@gmail.com",
      "faq": null,
      "first_name": "Valérie",
      "hidden": false,
      "id": "xdb47sjhnoktzc5",
      "languages": [],
      "last_name": "Gouem-Sottas",
      "phone_number": "799 329 378",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:42:27.958Z",
      "website": "https://www.valnutrition.ch"
    },
    {
      "address_link": "https://maps.app.goo.gl/TFYh19ZVYWCQFnSp6",
      "address_text": "15, Rue Peillonnex, 1225 Chêne-Bourg",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:42:11.630Z",
      "description": "",
      "email": "laurence.gordien@laureade.ch",
      "faq": null,
      "first_name": "Laurence",
      "hidden": false,
      "id": "yy7gnn3n836klu0",
      "languages": [],
      "last_name": "Gordien",
      "phone_number": "764 307 678",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:42:11.630Z",
      "website": "https://www.laureade.ch"
    },
    {
      "address_link": "",
      "address_text": "boîte postale 74160, 14, chemin de Bardonnex, 1234 Pinchat",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:42:09.786Z",
      "description": "",
      "email": "sgh@hypnoses.ch",
      "faq": null,
      "first_name": "Sylvie",
      "hidden": false,
      "id": "cluq8555q9ngv0w",
      "languages": [],
      "last_name": "Gillioz Hottelier",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:42:09.786Z",
      "website": "http://www.hypnoses.ch"
    },
    {
      "address_link": "",
      "address_text": "Rte de Cara 40, 1243 Presinge",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:42:08.120Z",
      "description": "",
      "email": "naturopathe@gmail.com",
      "faq": null,
      "first_name": "Jenny",
      "hidden": false,
      "id": "hiwhai7vtuvrb6v",
      "languages": [],
      "last_name": "Gillieron",
      "phone_number": "227 867 953",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:42:08.120Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/zhLXMAAtk8iW8wQU9",
      "address_text": "c/o Céline GILI, boîte postale 1207, Du Lac 10, 1207 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:42:06.391Z",
      "description": "",
      "email": "contact@synervie.ch",
      "faq": null,
      "first_name": "Céline",
      "hidden": false,
      "id": "m8rq5fci42ngzak",
      "languages": [],
      "last_name": "Gili",
      "phone_number": "786 292 071",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:42:06.391Z",
      "website": "http://www.synervie.ch"
    },
    {
      "address_link": "https://maps.app.goo.gl/eztSpgf9aMcxd6wFA",
      "address_text": "Rue Micheli-Du-Crest 4, 1205 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:42:04.723Z",
      "description": "",
      "email": "celine@nutritionniste-geneve.ch",
      "faq": null,
      "first_name": "Céline",
      "hidden": false,
      "id": "z1i39uqe8j76rsv",
      "languages": [],
      "last_name": "Gianfrancesco",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:42:04.723Z",
      "website": "www.nutritionniste-geneve.ch"
    },
    {
      "address_link": "",
      "address_text": "c/o Huguette GENETTI, 50, Rue de la Tambourine, 1227 Carouge GE",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:42:02.966Z",
      "description": "",
      "email": "info@sos-nutrition.ch",
      "faq": null,
      "first_name": "Huguette",
      "hidden": false,
      "id": "0diltrzyc298n35",
      "languages": [],
      "last_name": "Genetti",
      "phone_number": "223 005 191",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:42:02.966Z",
      "website": "https://www.sos-nutrition.ch"
    },
    {
      "address_link": "https://maps.app.goo.gl/cBQm2Rap9WZsPvar6",
      "address_text": "Rue Jean Sénebier 20, 1205 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:41:58.831Z",
      "description": "",
      "email": "contact@la-clinique-naturelle.ch",
      "faq": null,
      "first_name": "Marie-Anne",
      "hidden": false,
      "id": "0lqqxsvp40sbpyr",
      "languages": [],
      "last_name": "Geiser",
      "phone_number": "225 087 350",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:41:58.831Z",
      "website": "www.la-clinique-naturelle.ch"
    },
    {
      "address_link": "https://maps.app.goo.gl/BNYaj4UYDzexCYFb8",
      "address_text": "11, Rue Sautter, 1205 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:41:57.276Z",
      "description": "",
      "email": "dominique.dulac@homeo-bach.ch",
      "faq": null,
      "first_name": "Dominique",
      "hidden": false,
      "id": "owemoy99kascayq",
      "languages": [],
      "last_name": "Gehring Dulac",
      "phone_number": "227 893 900",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:41:57.276Z",
      "website": "www.homeo-bach.ch"
    },
    {
      "address_link": "",
      "address_text": "Route de Bellegarde 72, 1284 Chancy",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:41:55.677Z",
      "description": "",
      "email": "ivany.daddario@gmail.com",
      "faq": null,
      "first_name": "Ivany",
      "hidden": false,
      "id": "ywdsorqimx6gr0g",
      "languages": [],
      "last_name": "Gay",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:41:55.677Z",
      "website": ""
    },
    {
      "address_link": "",
      "address_text": "Rue Henri Mussard 12, 1208 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:41:53.973Z",
      "description": "",
      "email": "",
      "faq": null,
      "first_name": "Catherine",
      "hidden": false,
      "id": "3dfzibzgbwjijdq",
      "languages": [],
      "last_name": "Gay",
      "phone_number": "798 911 896",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:41:53.973Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/dcC56RXqS1hp6PMDA",
      "address_text": "39, Av. du Lignon, 1219 Le Lignon",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:41:52.952Z",
      "description": "",
      "email": "info@sanature.ch",
      "faq": null,
      "first_name": "Martine",
      "hidden": false,
      "id": "skcylu9xfz0narv",
      "languages": [],
      "last_name": "Gaillard",
      "phone_number": "227 430 082",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:41:52.952Z",
      "website": "https://www.sanature.ch"
    },
    {
      "address_link": "",
      "address_text": "Rue du Clos 6, 1207 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:41:51.286Z",
      "description": "",
      "email": "info@hetc.ch",
      "faq": null,
      "first_name": "Manuel",
      "hidden": false,
      "id": "lwc1stnnxc32vzw",
      "languages": [],
      "last_name": "Fux",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:41:51.286Z",
      "website": "http://www.hetc.ch"
    },
    {
      "address_link": "",
      "address_text": "Rue de Montbrillant 33, 1201 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:41:49.311Z",
      "description": "",
      "email": "celinea.froidevaux@gmail.com",
      "faq": null,
      "first_name": "Céline",
      "hidden": false,
      "id": "sql20frj1pd9p0d",
      "languages": [],
      "last_name": "Froidevaux",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:41:49.311Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/65N6i7jYymZjFBNTA",
      "address_text": "129 Route de Saint-Julien, 1228 Plan-les-Ouates",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:41:47.629Z",
      "description": "",
      "email": "",
      "faq": null,
      "first_name": "Roselyne",
      "hidden": false,
      "id": "uexwjjka277vwhp",
      "languages": [],
      "last_name": "Fluckiger",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:41:47.629Z",
      "website": "https://roselynefluckiger.ch"
    },
    {
      "address_link": "https://maps.app.goo.gl/Lsm3JN37ooXrMgcSA",
      "address_text": "Rue de Saint-Jean 73, 1201 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:41:46.422Z",
      "description": "",
      "email": "contact@sarahfinci.ch",
      "faq": null,
      "first_name": "Sarah",
      "hidden": false,
      "id": "jzw6dtqib7m101t",
      "languages": ["ciwr918a5o8h84g", "g8reptg5cwnhvor", "09wr6j2qj6lsu6a"],
      "last_name": "Finci",
      "phone_number": "789 213 815",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:46:34.997Z",
      "website": "https://www.sarahfinci.ch"
    },
    {
      "address_link": "https://maps.app.goo.gl/3GNs4mC51HZgvoKL7",
      "address_text": "Avenue Jules Crosnier 6, 1206 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:41:44.665Z",
      "description": "",
      "email": "info@ludovic-favre-naturopathe.ch",
      "faq": null,
      "first_name": "Ludovic",
      "hidden": false,
      "id": "vnexoqxw0tg5mr8",
      "languages": [],
      "last_name": "Favre",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:41:44.665Z",
      "website": "http://www.ludovic-favre-naturopathe.ch"
    },
    {
      "address_link": "",
      "address_text": "Rue Muzy 9, 1207 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:41:42.938Z",
      "description": "",
      "email": "info@medi-concept.ch",
      "faq": null,
      "first_name": "Virginie",
      "hidden": false,
      "id": "odkrbgn3sl851fq",
      "languages": [],
      "last_name": "Faria De Moura Serra-Sechaud",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:41:42.938Z",
      "website": "http://www.medi-concept.ch"
    },
    {
      "address_link": "https://maps.app.goo.gl/ZeifQqaVuixaikhG6",
      "address_text": "Chemin du daru 11, 1228 Plan-les-Ouates",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:41:41.145Z",
      "description": "",
      "email": "flore.echinard@gmail.com",
      "faq": null,
      "first_name": "Flore",
      "hidden": false,
      "id": "f0fgygobmf9220o",
      "languages": [],
      "last_name": "Echinard",
      "phone_number": "789 491 342",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:41:41.145Z",
      "website": "https://Www.floreechinard.com"
    },
    {
      "address_link": "",
      "address_text": "c/o Cabinet Passiflore, Av. Giuseppe-Motta 14, 1202 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:41:39.532Z",
      "description": "",
      "email": "nduverney@bluewin.ch",
      "faq": null,
      "first_name": "Natasha",
      "hidden": false,
      "id": "1896x0x14gxjc2h",
      "languages": [],
      "last_name": "Duverney",
      "phone_number": "792 992 349",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:41:39.532Z",
      "website": "https://www.nutrition-passiflore.com"
    },
    {
      "address_link": "https://maps.app.goo.gl/Yt6HkptjeNmNNiMR8",
      "address_text": "Rue Pré-Jérôme 15, 1205 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:41:37.814Z",
      "description": "",
      "email": "contact@equinoxs.ch",
      "faq": null,
      "first_name": "Anne-Christine",
      "hidden": false,
      "id": "diguvv4f9cc5qlv",
      "languages": [],
      "last_name": "Duss",
      "phone_number": "228 001 539",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:41:37.814Z",
      "website": "www.equinoxs.ch"
    },
    {
      "address_link": "https://maps.app.goo.gl/ZQnFrBSUvpr28t3JA",
      "address_text": "Route de la Capite 246, 1222 Vésenaz",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:41:36.190Z",
      "description": "",
      "email": "dupraz@gmail.com",
      "faq": null,
      "first_name": "Nathalie",
      "hidden": false,
      "id": "0gdt64nnyipwy4w",
      "languages": [],
      "last_name": "Dupraz-Dange",
      "phone_number": "227 525 076",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:41:36.190Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/em6rGvhsmMZinvhs6",
      "address_text": "Rue Voltaire 16, 1201 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:41:34.603Z",
      "description": "",
      "email": "info@oreka-coaching.ch",
      "faq": null,
      "first_name": "Patricia",
      "hidden": false,
      "id": "d75c8hf0jobgqzu",
      "languages": [],
      "last_name": "Düggeli",
      "phone_number": "782 321 624",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:41:34.603Z",
      "website": "https://www.oreka-coaching.ch"
    },
    {
      "address_link": "",
      "address_text": "2, Cours de Rive, 1204 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:41:32.862Z",
      "description": "",
      "email": "anne-carole.dufour@cidge.ch",
      "faq": null,
      "first_name": "Anne-Carole",
      "hidden": false,
      "id": "681qb1wqb16xg6z",
      "languages": ["ciwr918a5o8h84g", "g8reptg5cwnhvor"],
      "last_name": "Dufour",
      "phone_number": "225 666 801",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:46:29.661Z",
      "website": ""
    },
    {
      "address_link": "",
      "address_text": "Route de Frontenex 37, 1207 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:41:31.251Z",
      "description": "",
      "email": "aurelie.dubuis@gmail.com",
      "faq": null,
      "first_name": "Aurelie",
      "hidden": false,
      "id": "4mtj4ulzz0xa939",
      "languages": [],
      "last_name": "Dubuis Beykirch",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:41:31.251Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/6tkMeveSVtzGCPQi9",
      "address_text": "Place de la Diversité 1, 1217 Meyrin",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:41:29.536Z",
      "description": "",
      "email": "Contact@juliedosling.ch",
      "faq": null,
      "first_name": "Julie",
      "hidden": false,
      "id": "ye2a59854npbsoz",
      "languages": ["ciwr918a5o8h84g", "g8reptg5cwnhvor"],
      "last_name": "Dowling",
      "phone_number": "789 487 775",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:46:27.975Z",
      "website": "https://juliedowling.ch"
    },
    {
      "address_link": "",
      "address_text": "58 Chemin de Belle Cour, 1213 Onex",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:41:27.676Z",
      "description": "",
      "email": "adriana.dipizzo@gmail.com",
      "faq": null,
      "first_name": "Adriana",
      "hidden": false,
      "id": "58jim5t27ewfr1h",
      "languages": ["ciwr918a5o8h84g", "g8reptg5cwnhvor"],
      "last_name": "Di Pizzo",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:46:26.818Z",
      "website": "https://www.identitealimentaire.ch"
    },
    {
      "address_link": "https://maps.app.goo.gl/xWX7wUgYScHWW1sh8",
      "address_text": "c/o Clinique des Acacias, Route des Acacias 2, 1227 Les Acacias",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:41:26.012Z",
      "description": "",
      "email": "contact@sarahdimario.ch",
      "faq": null,
      "first_name": "Sarah",
      "hidden": false,
      "id": "3jf8qoqwaq34ii1",
      "languages": [],
      "last_name": "Di Mario",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:41:26.012Z",
      "website": "http://www.sarahdimario.ch"
    },
    {
      "address_link": "",
      "address_text": "Rue des Evaux 1, 1213 Onex",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:41:24.467Z",
      "description": "",
      "email": "info@esprit-nutri.ch",
      "faq": null,
      "first_name": "Jessica",
      "hidden": false,
      "id": "7ya9nb5g3e8gl9u",
      "languages": [],
      "last_name": "Di Blasio",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:41:24.467Z",
      "website": "www.esprit-nutri.ch"
    },
    {
      "address_link": "",
      "address_text": "c/o c/o Association Stelior, Ch. des Clochettes 14, 1206 Champel",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:41:22.731Z",
      "description": "",
      "email": "",
      "faq": null,
      "first_name": "Diane",
      "hidden": false,
      "id": "vh3e2f2ka94jrzh",
      "languages": [],
      "last_name": "Demarest",
      "phone_number": "782 210 315",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:41:22.731Z",
      "website": "diane.demarest@stelior.com"
    },
    {
      "address_link": "",
      "address_text": "Quai Gustave Ador 62, 1207 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:41:21.418Z",
      "description": "",
      "email": "marianne@delporte.ch",
      "faq": null,
      "first_name": "Marianne",
      "hidden": false,
      "id": "tomo0iagr43j8nm",
      "languages": [],
      "last_name": "Delporte-Rochin",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:41:21.418Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/GbJjkLNy6KRWo3RW7",
      "address_text": "Chemin de la bise 14, 1222 Vésenaz",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:41:19.586Z",
      "description": "",
      "email": "isabelle.delecroix@hotmail.ch",
      "faq": null,
      "first_name": "Isabelle",
      "hidden": false,
      "id": "yetaq5fo0kry9ur",
      "languages": [],
      "last_name": "Delecroix",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:41:19.586Z",
      "website": "www.mapositiveattitude.ch"
    },
    {
      "address_link": "https://maps.app.goo.gl/P19gTo97qcTEH8pNA",
      "address_text": "Rue du Beulet 1, 1203 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:41:17.869Z",
      "description": "",
      "email": "michel@detente.ch",
      "faq": null,
      "first_name": "Michel",
      "hidden": false,
      "id": "owan9ixrg4u9wp7",
      "languages": ["ciwr918a5o8h84g", "g8reptg5cwnhvor", "09wr6j2qj6lsu6a"],
      "last_name": "Del Amor",
      "phone_number": "764 370 763",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:46:22.790Z",
      "website": "https://www.detente.ch"
    },
    {
      "address_link": "",
      "address_text": "Bd Helvétique 30, 1207 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:41:16.307Z",
      "description": "",
      "email": "",
      "faq": null,
      "first_name": "Marina",
      "hidden": false,
      "id": "nivx21jrz7kuz4q",
      "languages": [],
      "last_name": "Défago",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:41:16.307Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/8akiVuEsKigEz2ix8",
      "address_text": "c/o Centre Agape, Chemin Rieu 20, 1208 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:41:15.140Z",
      "description": "",
      "email": "",
      "faq": null,
      "first_name": "Sidonie",
      "hidden": false,
      "id": "5gsk429maxrk65d",
      "languages": [],
      "last_name": "Decourt",
      "phone_number": "762 166 677",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:41:15.140Z",
      "website": "https://www.nutrition-geneve.ch"
    },
    {
      "address_link": "",
      "address_text": "16, ch. des Tornaletetes, 1231 Conches",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:41:14.040Z",
      "description": "",
      "email": "info@aluine.ch",
      "faq": null,
      "first_name": "Aline",
      "hidden": false,
      "id": "a2g3xg7r45wtfo1",
      "languages": [],
      "last_name": "De Siebenthal",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:41:14.040Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/qenMHxN2wPHh45Bu5",
      "address_text": "Chemin de la Tulette 3A, 1223 Cologny",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:41:12.402Z",
      "description": "",
      "email": "cedemestral@gmail.com",
      "faq": null,
      "first_name": "Celia",
      "hidden": false,
      "id": "ur4xr7t05xd6nnr",
      "languages": ["ciwr918a5o8h84g", "g8reptg5cwnhvor"],
      "last_name": "De Mestral",
      "phone_number": "764 809 510",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:46:19.249Z",
      "website": "https://celiademestral.com"
    },
    {
      "address_link": "",
      "address_text": "c/o UNITAS, boîte postale 1207, Ruelle de la Vinaigrerie 5, 1207 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:41:10.637Z",
      "description": "",
      "email": "rdc@proensa.ch",
      "faq": null,
      "first_name": "Rhéa",
      "hidden": false,
      "id": "gjhg5wm33lgaik7",
      "languages": ["ciwr918a5o8h84g", "g8reptg5cwnhvor"],
      "last_name": "De Candolle",
      "phone_number": "791 295 215",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:46:18.052Z",
      "website": ""
    },
    {
      "address_link": "",
      "address_text": "Rue Saint Laurent 17, 1207 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:41:08.869Z",
      "description": "",
      "email": "mariondavidstudio@gmail.com",
      "faq": null,
      "first_name": "Marion",
      "hidden": false,
      "id": "1us90j9l4ghjqfy",
      "languages": ["ciwr918a5o8h84g", "g8reptg5cwnhvor"],
      "last_name": "David",
      "phone_number": "774 711 702",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:46:16.909Z",
      "website": ""
    },
    {
      "address_link": "",
      "address_text": "Rue Michel-Chauvet 10, 1208 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:41:07.211Z",
      "description": "",
      "email": "aida.darouichi@gmail.com",
      "faq": null,
      "first_name": "Aida",
      "hidden": false,
      "id": "bmp8amgku91sr11",
      "languages": [],
      "last_name": "Darouichi",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:41:07.211Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/ZA42cAXKBSjzAHpf9",
      "address_text": "Rue des Caroubiers 18, 1227 Carouge GE",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:41:05.543Z",
      "description": "",
      "email": "",
      "faq": null,
      "first_name": "Noémie",
      "hidden": false,
      "id": "373mp0r0awi46az",
      "languages": [],
      "last_name": "Darlix",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:41:05.543Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/Kkcn5cCk6kRcvMy2A",
      "address_text": "61, Rue Prévost-Martin, 1205 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:41:04.433Z",
      "description": "",
      "email": "noemie.darlix@outlook.com",
      "faq": null,
      "first_name": "Christian",
      "hidden": false,
      "id": "ooxggyqypdq1trr",
      "languages": [],
      "last_name": "Dammron",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:41:04.433Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/rPyTovTMV16nJEVi9",
      "address_text": "Rue François Meunier 9, 1227 Carouge GE",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:41:02.662Z",
      "description": "",
      "email": "info@laureencullati.ch",
      "faq": null,
      "first_name": "Laureen",
      "hidden": false,
      "id": "qje1cdlnfzh7c25",
      "languages": [],
      "last_name": "Cullati",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:41:02.662Z",
      "website": "https://www.laureencullati.ch"
    },
    {
      "address_link": "https://maps.app.goo.gl/ZonyhS7Vvh5JES5R7",
      "address_text": "Chemin des Semailles 47, 1212 Grand-Lancy",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:41:00.946Z",
      "description": "",
      "email": "naturopathiegeneve@gmail.com",
      "faq": null,
      "first_name": "Corinne",
      "hidden": false,
      "id": "jbgb29gu99daq14",
      "languages": [
        "ciwr918a5o8h84g",
        "g8reptg5cwnhvor",
        "09wr6j2qj6lsu6a",
        "egbe5ll1g9bq31l"
      ],
      "last_name": "Crouilbois",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:46:13.382Z",
      "website": "http://www.hydrotherapiegeneve.ch"
    },
    {
      "address_link": "",
      "address_text": "Rue Jean Gutenberg 10, 1201 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:40:59.394Z",
      "description": "",
      "email": "elodiemiambanzila@yahoo.com",
      "faq": null,
      "first_name": "Elodie",
      "hidden": false,
      "id": "wq6bjaj9f3hlfqd",
      "languages": [],
      "last_name": "Couture-Miambanzila",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:40:59.394Z",
      "website": ""
    },
    {
      "address_link": "",
      "address_text": "c/o c/o Pharmacie Amavita Métro-Shopping, Rue du Mont-Blanc 30, 1201 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:40:57.724Z",
      "description": "",
      "email": "melanie-costa@sapo.pt",
      "faq": null,
      "first_name": "Mélanie",
      "hidden": false,
      "id": "7qc3uuhlss8u2h4",
      "languages": [],
      "last_name": "Costa Silva",
      "phone_number": "588 781 730",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:40:57.724Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/zhPfHy6vkaGtVucm7",
      "address_text": "c/o Espace Esclarmonde, Av. Soret 39, 1203 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:40:55.935Z",
      "description": "",
      "email": "pronutrisan@gmail.com",
      "faq": null,
      "first_name": "Elzbieta",
      "hidden": false,
      "id": "xfs8cla71rf4yll",
      "languages": ["ciwr918a5o8h84g", "g8reptg5cwnhvor"],
      "last_name": "Corthay",
      "phone_number": "791 376 716",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:46:11.265Z",
      "website": "https://www.pronutrisan.com"
    },
    {
      "address_link": "https://maps.app.goo.gl/XsyzeA4Vzww2sFNFA",
      "address_text": "9 bis, Rue François-Meunier, 1227 Carouge GE",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:40:54.295Z",
      "description": "",
      "email": "info@pilates-movements.ch",
      "faq": null,
      "first_name": "Andréa",
      "hidden": false,
      "id": "0pbebgemgbkj5jn",
      "languages": [],
      "last_name": "Cornioley",
      "phone_number": "223 004 071",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:40:54.295Z",
      "website": "www.pilates-movements.com"
    },
    {
      "address_link": "",
      "address_text": "Chemin de Compostelle 16, 1212 Grand-Lancy",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:40:52.755Z",
      "description": "",
      "email": "tatianaconstantin@hotmail.com",
      "faq": null,
      "first_name": "Tatiana",
      "hidden": false,
      "id": "n9ar9bb8ume1rf7",
      "languages": [],
      "last_name": "Constantin",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:40:52.755Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/zEs6xq5RfztNgQHcA",
      "address_text": "Ch. François-Lehmann, 20, 1218 Le Grand-Saconnex",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:40:51.165Z",
      "description": "",
      "email": "mconchatre@bluewin.ch",
      "faq": null,
      "first_name": "Marcel",
      "hidden": false,
      "id": "ofumvc0hb78g4un",
      "languages": [],
      "last_name": "Conchatre",
      "phone_number": "797 304 899",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:40:51.165Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/7ZiKA4cwmVjYuFAeA",
      "address_text": "Michel-Chauvet 15, 1208 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:40:49.534Z",
      "description": "",
      "email": "karim.chubin@gmail.com",
      "faq": null,
      "first_name": "Karim",
      "hidden": false,
      "id": "ogj3f33lgf5v1g1",
      "languages": [],
      "last_name": "Chubin",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:40:49.534Z",
      "website": "http://www.karimchubin.com"
    },
    {
      "address_link": "https://maps.app.goo.gl/DUwxu36bQzJMWyzq5",
      "address_text": "c/o familyacupuncture, 11, rue du Roveray, 1207 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:40:47.763Z",
      "description": "",
      "email": "mclnutrition@gmail.com",
      "faq": null,
      "first_name": "Myriam",
      "hidden": false,
      "id": "qsn9ny1n7vr8qfu",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Christin",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:46:07.967Z",
      "website": "https://www.mclnaturo.ch"
    },
    {
      "address_link": "https://maps.app.goo.gl/mkx1irLav5GqNkFc6",
      "address_text": "c/o C/O Joy4Spirit, Place de la Diversité 1-3, 1217 Meyrin",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:40:45.900Z",
      "description": "",
      "email": "contact@nadegechevassus.com",
      "faq": null,
      "first_name": "Nadège",
      "hidden": false,
      "id": "y0o7mwn3cdlhctk",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Chevassus",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:46:06.887Z",
      "website": "http://www.nedegchevassus.com"
    },
    {
      "address_link": "https://maps.app.goo.gl/4DxptqsruhNS1Mgj6",
      "address_text": "boîte postale 1234, Chemin de la place verte 21A, 1234 Vessy",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:40:43.996Z",
      "description": "",
      "email": "yohanna.chanel@gmail.com",
      "faq": null,
      "first_name": "Yohanna",
      "hidden": false,
      "id": "caszy56qchnv788",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Chanel",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:46:05.818Z",
      "website": "https://www.pure-motivation.ch"
    },
    {
      "address_link": "",
      "address_text": "Rue Cherbuliez 2, 1207 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:40:42.183Z",
      "description": "",
      "email": "icelardin@bluewin.ch",
      "faq": null,
      "first_name": "Isabelle",
      "hidden": false,
      "id": "9yn2t7kattnt9jb",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Celardin",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:46:04.731Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/URLPU7ww3yynXPUU9",
      "address_text": "Ch. des Crêts de Champel 12, 1206 Champel",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:40:40.626Z",
      "description": "",
      "email": "info@cmchampel.ch",
      "faq": null,
      "first_name": "Marina",
      "hidden": false,
      "id": "dl353uyrspyuodv",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Cecchi",
      "phone_number": "229 990 999",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:46:03.708Z",
      "website": "www.marinacecchi.com"
    },
    {
      "address_link": "",
      "address_text": "c/o c/o MHP Centrum Genève, Rue de la Scie 4, 1207 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:40:38.906Z",
      "description": "",
      "email": "s.castillonutrition@gmail.com",
      "faq": null,
      "first_name": "Silvia",
      "hidden": false,
      "id": "wp4bcnmpqufvqap",
      "languages": [],
      "last_name": "Castillo",
      "phone_number": "227 004 817",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:40:38.906Z",
      "website": "scnutritions.ch"
    },
    {
      "address_link": "",
      "address_text": "Av. de Bois de la Chapelle 85, 1213 Onex",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:40:37.276Z",
      "description": "",
      "email": "lacarini@sunrise.ch",
      "faq": null,
      "first_name": "Laura",
      "hidden": false,
      "id": "mhoutkfqnimggke",
      "languages": [],
      "last_name": "Carini",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:40:37.276Z",
      "website": ""
    },
    {
      "address_link": "",
      "address_text": "Bd Helvétique 30, 1207 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:40:35.557Z",
      "description": "",
      "email": "mariaisa_cg@hotmail.com",
      "faq": null,
      "first_name": "Maria Isabel",
      "hidden": false,
      "id": "aoguviyk680hqug",
      "languages": ["ciwr918a5o8h84g", "g8reptg5cwnhvor"],
      "last_name": "Cardona Gómez",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:46:01.487Z",
      "website": ""
    },
    {
      "address_link": "",
      "address_text": "boîte postale Case postale 665, 1213 Petit-Lancy",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:40:31.612Z",
      "description": "",
      "email": "capelayasmine@gmail.com",
      "faq": null,
      "first_name": "Yasmine",
      "hidden": false,
      "id": "07mama3uhx4a2tn",
      "languages": [],
      "last_name": "Capela",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:40:31.612Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/Bt2fTUsEjsDTzawp7",
      "address_text": "c/o Centre de Thérapies Naturelles DI-M Sàrl, boîte postale 1201, Place De-Grenus, 10, 1201 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:40:17.635Z",
      "description": "",
      "email": "tatiana.campeggi@di-m.ch",
      "faq": null,
      "first_name": "Tatiana",
      "hidden": false,
      "id": "phpxwag3czmvzyx",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Campeggi",
      "phone_number": "229 000 075",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:45:59.659Z",
      "website": "https://www.di-m.ch"
    },
    {
      "address_link": "",
      "address_text": "c/o Anciennement clinique Dr. Claude Illi, boîte postale déménagé à Versoix , septembre 2023, Grand-Montfleury 14, 1290 Versoix",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:40:15.854Z",
      "description": "",
      "email": "evitale21@hotmail.com",
      "faq": null,
      "first_name": "Evelyne",
      "hidden": false,
      "id": "wf4uh6vej9djbn9",
      "languages": ["ciwr918a5o8h84g", "g8reptg5cwnhvor"],
      "last_name": "Campbell-Tiech",
      "phone_number": "799 403 852",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:45:58.412Z",
      "website": ""
    },
    {
      "address_link": "",
      "address_text": "c/o c/o MEDICAL CENTER, Rue du Rhône 118, 1er étage, 1204 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:40:14.145Z",
      "description": "",
      "email": "info@nutritionholistique.ch",
      "faq": null,
      "first_name": "Sophie",
      "hidden": false,
      "id": "t4yt0sjk5whrxr3",
      "languages": ["ciwr918a5o8h84g", "g8reptg5cwnhvor"],
      "last_name": "Calvignac De Bonneval",
      "phone_number": "228 096 688",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:45:57.274Z",
      "website": "https://www.nutritionholistique.ch/"
    },
    {
      "address_link": "https://maps.app.goo.gl/yhDqdexccRenvcUA9",
      "address_text": "c/o c/o Delphine Hug, Rue Pédro-Meylan 1, 1208 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:40:12.422Z",
      "description": "",
      "email": "rachel.cagnon@human4human.ch",
      "faq": null,
      "first_name": "Rachel",
      "hidden": false,
      "id": "japrutb9k2ghyqg",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Cagnon",
      "phone_number": "796 191 909",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:45:56.075Z",
      "website": "https://www.human4human.ch"
    },
    {
      "address_link": "",
      "address_text": "Avenue des Communes Réunis 15, 1212 Grand-Lancy",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:40:10.801Z",
      "description": "",
      "email": "pamela.cadieux@gmail.com",
      "faq": null,
      "first_name": "Pamela",
      "hidden": false,
      "id": "4qey2l0odvi7czy",
      "languages": [],
      "last_name": "Cadieux",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:40:10.801Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/DppNCAT3q36AKW9t6",
      "address_text": "123 Route de Chêne, 1224 Chêne-Bougeries",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:40:09.105Z",
      "description": "",
      "email": "ambre@ambreburgin.ch",
      "faq": null,
      "first_name": "Ambre",
      "hidden": false,
      "id": "vbcyw35syx6wqpd",
      "languages": ["ciwr918a5o8h84g", "g8reptg5cwnhvor"],
      "last_name": "Bürgin",
      "phone_number": "227 770 909",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:45:54.347Z",
      "website": "https://ambreburgin.ch"
    },
    {
      "address_link": "",
      "address_text": "Ch. de Compostelle 7, 1212 Grand-Lancy",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:40:07.455Z",
      "description": "",
      "email": "pediatre.nathalie.burger.ch@gmail.com",
      "faq": null,
      "first_name": "Nathalie",
      "hidden": false,
      "id": "jmt1uk15uoufule",
      "languages": [],
      "last_name": "Burger",
      "phone_number": "228 272 525",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:40:07.455Z",
      "website": ""
    },
    {
      "address_link": "",
      "address_text": "Rue des Marbriers 2, 1204 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:40:05.886Z",
      "description": "",
      "email": "sonia.brutsch@bluewin.ch",
      "faq": null,
      "first_name": "Sonia",
      "hidden": false,
      "id": "rzwv2i56cmzn54k",
      "languages": [],
      "last_name": "Brutsch",
      "phone_number": "787 225 066",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:40:05.886Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/FwNSFmWW9e4tMssm9",
      "address_text": "Av. Prévost-Martin 4, 1205 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:40:03.990Z",
      "description": "",
      "email": "yan.braisaz@chifaa-sante.net",
      "faq": null,
      "first_name": "Yan",
      "hidden": false,
      "id": "wg3z3jr2bix3jic",
      "languages": [],
      "last_name": "Braisaz",
      "phone_number": "227 864 900",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:40:03.990Z",
      "website": "https://www.chifaa-sante.net"
    },
    {
      "address_link": "https://maps.app.goo.gl/VPdVDh8PPC28iMdw8",
      "address_text": "Rte d'Aire la Vile 215, 1242 Satigny",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:40:02.375Z",
      "description": "",
      "email": "nutritiphnutrition@gmail.com",
      "faq": null,
      "first_name": "Tiphaine",
      "hidden": false,
      "id": "v9mxf4df0fz9x71",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Boutier",
      "phone_number": "227 538 019",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:45:51.571Z",
      "website": "http://www.sante-elementterre.ch"
    },
    {
      "address_link": "",
      "address_text": "10-C Rte de Rennex, 1294 Genthod",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:40:00.788Z",
      "description": "",
      "email": "ivonnebd@bluewin.ch",
      "faq": null,
      "first_name": "Ivonne",
      "hidden": false,
      "id": "m8elnj07uebgiun",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Bornet",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:45:50.472Z",
      "website": ""
    },
    {
      "address_link": "",
      "address_text": "Route des Jeunes 43, 1227 Carouge GE",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:39:59.122Z",
      "description": "",
      "email": "borgeaud.jo@gmail.com",
      "faq": null,
      "first_name": "Jonas",
      "hidden": false,
      "id": "r58gulextew7syn",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Borgeaud",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:45:49.032Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/iLNsdYb6nk8omDaY8",
      "address_text": "Avenue Cardinal-Mermillod 36, 1227 Carouge GE",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:39:56.740Z",
      "description": "",
      "email": "info@holivie.ch",
      "faq": null,
      "first_name": "Valeska",
      "hidden": false,
      "id": "76902rsivkz6638",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Borga",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:45:41.930Z",
      "website": "https://www.holivie.ch"
    },
    {
      "address_link": "https://maps.app.goo.gl/jFaQLZhUdEkjMRSV7",
      "address_text": "8, Rue Rodolphe-Toepffer, 1206 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:39:55.073Z",
      "description": "",
      "email": "lucrezia.lbnutrition@gmail.com",
      "faq": null,
      "first_name": "Lucrezia",
      "hidden": false,
      "id": "q55swa1n4nup97n",
      "languages": ["ciwr918a5o8h84g", "g8reptg5cwnhvor", "09wr6j2qj6lsu6a"],
      "last_name": "Bordi",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:45:40.882Z",
      "website": "http://www.lbnutrition.ch"
    },
    {
      "address_link": "https://maps.app.goo.gl/Rf8URn7Z5Gq4Qku58",
      "address_text": "Rue des Cordiers 19A, 1207 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:39:53.349Z",
      "description": "",
      "email": "info@hananeboesch.com",
      "faq": null,
      "first_name": "Hanane",
      "hidden": false,
      "id": "zuujbosekxjckcs",
      "languages": ["ciwr918a5o8h84g", "g8reptg5cwnhvor"],
      "last_name": "Boesch",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:45:39.709Z",
      "website": "https://www.hananeboesch.com"
    },
    {
      "address_link": "https://maps.app.goo.gl/6ccevXmmPxQ8Z3e8A",
      "address_text": "Rue des Marbriers 4, 1204 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:39:51.545Z",
      "description": "",
      "email": "sofia.bocchino@medinvita.ch",
      "faq": null,
      "first_name": "Sofia",
      "hidden": false,
      "id": "s5zavw4i4x3exaq",
      "languages": ["g8reptg5cwnhvor", "09wr6j2qj6lsu6a"],
      "last_name": "Bocchino",
      "phone_number": "227 814 801",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:45:38.629Z",
      "website": "https://www.medinvita.ch"
    },
    {
      "address_link": "https://maps.app.goo.gl/XtTE6ZYqXjS5fJaL7",
      "address_text": "c/o Espace Mieux Vivre, Rue des Voisins 15, 1205 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:39:49.909Z",
      "description": "",
      "email": "info@naturopathiemaya.com",
      "faq": null,
      "first_name": "Maya",
      "hidden": false,
      "id": "8oon9yne9twfm5s",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Blanco",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:45:37.574Z",
      "website": "https://www.naturopathiemaya.com"
    },
    {
      "address_link": "https://maps.app.goo.gl/xsD4MJYRm2wuZ2vx8",
      "address_text": "Boulevard des Philosophes 14, 1205 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:39:48.344Z",
      "description": "",
      "email": "contact@lbsante.ch",
      "faq": null,
      "first_name": "Lidia",
      "hidden": false,
      "id": "spqf1jqj6sxx4ze",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Bisquerra",
      "phone_number": "793 836 244",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:45:36.517Z",
      "website": "https://www.lidia-bisquerra.ch"
    },
    {
      "address_link": "https://maps.app.goo.gl/MTZMPsqeQtNKSz566",
      "address_text": "c/o C/O Cabinet de therapies alternatives, Rue du Clos 6, 1207 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:39:46.658Z",
      "description": "",
      "email": "cristina.bertollini@feedmentis.com",
      "faq": null,
      "first_name": "Cristina",
      "hidden": false,
      "id": "etvhu0uhxmuu29d",
      "languages": ["g8reptg5cwnhvor", "09wr6j2qj6lsu6a"],
      "last_name": "Bertollini",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:45:35.362Z",
      "website": "https://www.feedmentis.com"
    },
    {
      "address_link": "https://maps.app.goo.gl/Bs4vNScFQ5EmM5d1A",
      "address_text": "Chemin des Esserts 11b, 1213 Petit-Lancy",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:39:45.026Z",
      "description": "",
      "email": "nutrition@valentinabersan.com",
      "faq": null,
      "first_name": "Valentina",
      "hidden": false,
      "id": "tan2l7s6038rsl6",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Bersan",
      "phone_number": "764 208 608",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:45:34.281Z",
      "website": "http://www.valentinabersan.com"
    },
    {
      "address_link": "",
      "address_text": "Chemin de la Mère-Jeanne 8, 1242 Satigny",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:39:43.307Z",
      "description": "",
      "email": "",
      "faq": null,
      "first_name": "Patrizia",
      "hidden": false,
      "id": "s1774rcdewmz31v",
      "languages": [],
      "last_name": "Bernardi",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:39:43.307Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/2KTRejyM4VhntCmi9",
      "address_text": "c/o c/o Dr. Van Meir, Rte de la Fontenette 23, 1227 Carouge GE",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:39:42.121Z",
      "description": "",
      "email": "karine.bernard@immunonaturo.com",
      "faq": null,
      "first_name": "Karine",
      "hidden": false,
      "id": "u1uiqden6j2weey",
      "languages": ["ciwr918a5o8h84g", "g8reptg5cwnhvor"],
      "last_name": "Bernard",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:45:32.169Z",
      "website": "http://www.immunonaturo.com"
    },
    {
      "address_link": "https://maps.app.goo.gl/TRpbGAqRg2f84uT97",
      "address_text": "Ch. de la Petite-Boissière 44, 1208 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:39:40.519Z",
      "description": "",
      "email": "hadas@hadasrenaissance.ch",
      "faq": null,
      "first_name": "Hadas",
      "hidden": false,
      "id": "mp2lm45kbl4n76o",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Benamran",
      "phone_number": "225 355 595",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:45:31.093Z",
      "website": "www.centre-renaissance.ch"
    },
    {
      "address_link": "",
      "address_text": "c/o C/O Mme Nadia Hagner, Rue de Lausanne 67 b, 1202 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:39:38.696Z",
      "description": "",
      "email": "",
      "faq": null,
      "first_name": "Marc",
      "hidden": false,
      "id": "ink35pnybsqcogd",
      "languages": [],
      "last_name": "Benainous",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:39:38.696Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/VUbnWoab4zWusui89",
      "address_text": "Bd Helvétique 28, 1207 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:39:37.513Z",
      "description": "",
      "email": "adelaide.bellenot@gmail.com",
      "faq": null,
      "first_name": "Adélaïde",
      "hidden": false,
      "id": "gihsv0dfctzhrqb",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Bellenot",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:45:28.911Z",
      "website": "http://www.abnutrition.ch"
    },
    {
      "address_link": "",
      "address_text": "12.chemin Daniel-ihly, 1213 Petit-Lancy",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:39:35.925Z",
      "description": "",
      "email": "kammajda13@gmail.com",
      "faq": null,
      "first_name": "Majida",
      "hidden": false,
      "id": "63zewpacm9sdiyf",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Bélarabi",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:45:27.865Z",
      "website": "https://www.stagedetox.com"
    },
    {
      "address_link": "https://maps.app.goo.gl/pB5VdFWR4uUvRwEaA",
      "address_text": "Rue de Berne 3, 1201 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:39:34.274Z",
      "description": "",
      "email": "",
      "faq": null,
      "first_name": "Mirzeta",
      "hidden": false,
      "id": "0y84brascgyeevq",
      "languages": [],
      "last_name": "Becirevic",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:39:34.274Z",
      "website": ""
    },
    {
      "address_link": "",
      "address_text": "c/o c/o Centre Aliotis, Boulevard Helvetique 30, 1207 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:39:33.241Z",
      "description": "",
      "email": "julia.mellanger@gmail.com",
      "faq": null,
      "first_name": "Julia",
      "hidden": false,
      "id": "2819bw50ekoivtc",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Beaucourt-Mellanger",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:45:25.617Z",
      "website": "http://www.juliamellanger-nutrition.com"
    },
    {
      "address_link": "https://maps.app.goo.gl/FuEFigQ111HKfphc8",
      "address_text": "c/o RéflexNutriSanté, Rue de Bern 3, 1201 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:39:31.651Z",
      "description": "",
      "email": "reflexnutrisante@gmail.com",
      "faq": null,
      "first_name": "Cyrine",
      "hidden": false,
      "id": "1mp8f12108p91r1",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Bazarbachi Mazenauer",
      "phone_number": "786 202 028",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:45:24.434Z",
      "website": "http://www.reflexnutrisante.ch"
    },
    {
      "address_link": "https://maps.app.goo.gl/pKQ5yeyfaHuDYXGS7",
      "address_text": "Grand- Montfleury 54, 1290 Versoix",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:39:30.093Z",
      "description": "",
      "email": "frederique.baudois@gmail.com",
      "faq": null,
      "first_name": "Frédérique",
      "hidden": false,
      "id": "r99op4ch660xadh",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Baudois",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:45:23.222Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/gHJhkrmoXJ4ddhE87",
      "address_text": "23 chemin de la Vi-Longe, 1213 Onex",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:39:27.862Z",
      "description": "",
      "email": "info@baronesante.com",
      "faq": null,
      "first_name": "Marina",
      "hidden": false,
      "id": "c0jkk6evh26topv",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "BARONE",
      "phone_number": "227 936 077",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:45:22.006Z",
      "website": "https://www.baronesante.com"
    },
    {
      "address_link": "https://maps.app.goo.gl/rkCRdqnSzaKVAvtt9",
      "address_text": "Blvd des Tranchées 6, 1205 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:39:26.307Z",
      "description": "",
      "email": "sara@blossom-nutrition-yoga.ch",
      "faq": null,
      "first_name": "Sara",
      "hidden": false,
      "id": "8y3amcvxp46tgkc",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Baraldi Stutz",
      "phone_number": "782 271 917",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:45:19.849Z",
      "website": "http://www.blossom-nutrition-yoga.ch"
    },
    {
      "address_link": "https://maps.app.goo.gl/1YrPnh4JFB5Qfwcf7",
      "address_text": "Rue des Coopéatives 9, 1217 Meyrin",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:39:24.780Z",
      "description": "",
      "email": "hello@nutribiote.ch",
      "faq": null,
      "first_name": "Sophie",
      "hidden": false,
      "id": "sud2bto0nth97ne",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Balestra",
      "phone_number": "794 657 383",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:45:18.737Z",
      "website": "http://www.nutribiote.ch"
    },
    {
      "address_link": "https://maps.app.goo.gl/eQrjKT9MMRw426ZC8",
      "address_text": "Rue de Cornavin 11, 1201 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:39:23.236Z",
      "description": "",
      "email": "acuswiss@gmail.com",
      "faq": null,
      "first_name": "Christine Gisèle Paule",
      "hidden": false,
      "id": "m6zldgsv2nh3fkk",
      "languages": ["ciwr918a5o8h84g", "g8reptg5cwnhvor"],
      "last_name": "Baldacchino",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:45:17.648Z",
      "website": "http://www.holistictherapies.ch"
    },
    {
      "address_link": "https://maps.app.goo.gl/TAKjp139rURr7rFs6",
      "address_text": "Rue de la Dôle 2, 1203 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:39:21.659Z",
      "description": "",
      "email": "obaiolla@gmail.com",
      "faq": null,
      "first_name": "Olivier",
      "hidden": false,
      "id": "pfycd17cbjgcrxc",
      "languages": ["ciwr918a5o8h84g", "g8reptg5cwnhvor", "09wr6j2qj6lsu6a"],
      "last_name": "Baiolla",
      "phone_number": "792 781 389",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:45:16.476Z",
      "website": "https://www.globaltherapie.ch"
    },
    {
      "address_link": "https://maps.app.goo.gl/PcTrpeCPnDmvHFPn9",
      "address_text": "Rue Ami-Lullin 9, 1207 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:39:20.108Z",
      "description": "",
      "email": "baena.naturopathe@bluewin.ch",
      "faq": null,
      "first_name": "Samanta",
      "hidden": false,
      "id": "8hchh5cp8rjpn60",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Baena-Perrotta",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:45:15.356Z",
      "website": "https://www.familynaturopathie.ch"
    },
    {
      "address_link": "https://maps.app.goo.gl/vKNLNd1HLK3dzNNa9",
      "address_text": "Avenue Blanc 46, 1202 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:39:18.378Z",
      "description": "",
      "email": "odilebachelin@netscape.net",
      "faq": null,
      "first_name": "Odile",
      "hidden": false,
      "id": "ai5wqqjbbb3egaj",
      "languages": ["ciwr918a5o8h84g", "g8reptg5cwnhvor"],
      "last_name": "Bachelin",
      "phone_number": "763 914 583",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:45:14.035Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/rV5LqryBR3MK1KDq9",
      "address_text": "Bd Helvétique 28, 1207 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:39:16.705Z",
      "description": "",
      "email": "naturo.sixtineaversano@gmail.com",
      "faq": null,
      "first_name": "Sixtine",
      "hidden": false,
      "id": "u4dlioauhs49p1u",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Aversano",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:45:12.921Z",
      "website": "http://www.sixtineaversano.com"
    },
    {
      "address_link": "https://maps.app.goo.gl/a7YbxMZFAHTvg5Vs6",
      "address_text": "Av. de Choiseul 23, 1290 Versoix",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:39:15.027Z",
      "description": "",
      "email": "julieaugustinnutrtion@gmail.com",
      "faq": null,
      "first_name": "Julie",
      "hidden": false,
      "id": "e86gkj4fe6w6wlo",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Augustin",
      "phone_number": "763 618 436",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:45:11.628Z",
      "website": "http://www.julieaugustinnutrition.com"
    },
    {
      "address_link": "https://maps.app.goo.gl/JGeK9fvQj1BLgReF6",
      "address_text": "Rue de Carouge 75, 1205 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:39:13.258Z",
      "description": "",
      "email": "contact@ingridaspe.com",
      "faq": null,
      "first_name": "Ingrid",
      "hidden": false,
      "id": "avx73fpoj56syw1",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Aspe Altieri",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:45:10.484Z",
      "website": "https://www.ingridaspe.com"
    },
    {
      "address_link": "https://maps.app.goo.gl/NgYDVE8C4XJE89eL8",
      "address_text": "16-18 Quai du Seujet, 1201 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:39:11.564Z",
      "description": "",
      "email": "elke@hyperactif.net",
      "faq": null,
      "first_name": "Olga",
      "hidden": false,
      "id": "lsd666fd6vk2wgp",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Elke Arod",
      "phone_number": "227 512 636",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:45:09.333Z",
      "website": "http://www.stelior.org"
    },
    {
      "address_link": "",
      "address_text": "Route de Florissant 123, 1231 Conches",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:39:09.949Z",
      "description": "",
      "email": "nutritiondaroca@gmail.com",
      "faq": null,
      "first_name": "Daniela",
      "hidden": false,
      "id": "o98ib328qcq6xb3",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Aroca",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:45:08.121Z",
      "website": ""
    },
    {
      "address_link": "",
      "address_text": "Av. François Adolphe Grison 5, 1225 Chêne-Bourg",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:39:08.359Z",
      "description": "",
      "email": "lorianne.naturopathie@gmail.com",
      "faq": null,
      "first_name": "Lorianne",
      "hidden": false,
      "id": "4epi8jr6ao3or24",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Anken",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:45:06.875Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/f23W2VPA7YpHrEdX9",
      "address_text": "Ch. de Sous-le-Crêt 28, 1256 Troinex",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:39:06.747Z",
      "description": "",
      "email": "tessaholigeneve@gmail.com",
      "faq": null,
      "first_name": "Tessa",
      "hidden": false,
      "id": "8wuvwxufkly4dzu",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Ang",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:45:05.590Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/cRBYiorjTpP1cdUH8",
      "address_text": "Ch. du Pré d'Orsat 9, 1245 Collonge-Bellerive",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:39:04.931Z",
      "description": "",
      "email": "marinaa@bluewin.ch",
      "faq": null,
      "first_name": "Marina",
      "hidden": false,
      "id": "z3j4gayk1qygg9s",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Anchisi",
      "phone_number": "227 524 421",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:45:04.524Z",
      "website": ""
    },
    {
      "address_link": "",
      "address_text": "Rte du Rougemont 32, 1286 Sora",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:39:03.163Z",
      "description": "",
      "email": "aurelie@amez-droz.me",
      "faq": null,
      "first_name": "Aurélie",
      "hidden": false,
      "id": "rusbs9jsei7l1zl",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Aurélie Amez-Droz",
      "phone_number": "077 4 462 883",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:45:03.428Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/Ck7CW6Ktqxp7KxEd7",
      "address_text": "Arcade Les Enfants Terribles, boîte postale 1225, Rue du Gothard 1, 1225 Chêne-Bourg",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:39:01.271Z",
      "description": "",
      "email": "amatteaudrey@gmail.com",
      "faq": null,
      "first_name": "Audrey",
      "hidden": false,
      "id": "9g3lxsw949k4jxj",
      "languages": ["ciwr918a5o8h84g", "g8reptg5cwnhvor", "egbe5ll1g9bq31l"],
      "last_name": "Amatte",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:45:02.355Z",
      "website": "https://naturohappylight.ch"
    },
    {
      "address_link": "https://maps.app.goo.gl/B4v4rwppYaqL36Rz8",
      "address_text": "Centre TAC, Chemin des Pâquerettes 25, 1213 Petit-Lancy",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:38:59.638Z",
      "description": "",
      "email": "info@nutrisynergie.ch",
      "faq": null,
      "first_name": "Katia",
      "hidden": false,
      "id": "0c8g69411b7qdjh",
      "languages": ["ciwr918a5o8h84g", "g8reptg5cwnhvor", "09wr6j2qj6lsu6a"],
      "last_name": "Aliberti",
      "phone_number": "078 7 389 451",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:45:01.118Z",
      "website": "http://www.nutrisynergie.ch"
    },
    {
      "address_link": "",
      "address_text": "39, Av. de Budé, 1202 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:38:57.993Z",
      "description": "",
      "email": "dhf.nutri@gmail.com",
      "faq": null,
      "first_name": "Deborah Cristina",
      "hidden": false,
      "id": "0j7y3usm9qv64ox",
      "languages": ["ciwr918a5o8h84g", "g8reptg5cwnhvor"],
      "last_name": "Albizu Farchadi",
      "phone_number": "076 4120697",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:44:59.923Z",
      "website": ""
    },
    {
      "address_link": "",
      "address_text": "Rue du Bourg de Crousaz 20, 1071 Chexbres",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:38:56.469Z",
      "description": "",
      "email": "anne.aeschlimann@gmail.com",
      "faq": null,
      "first_name": "Anne",
      "hidden": false,
      "id": "hgwi9qtp595yan7",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Aeschlimann",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:44:58.634Z",
      "website": ""
    },
    {
      "address_link": "",
      "address_text": "La Roseraie 5, 1530 Payerne",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:38:54.932Z",
      "description": "",
      "email": "info@cmn.ch",
      "faq": null,
      "first_name": "Jean-Marc",
      "hidden": false,
      "id": "57u6x92cb9let52",
      "languages": ["g8reptg5cwnhvor"],
      "last_name": "Aellen",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:44:57.499Z",
      "website": "http://www.cmn.ch"
    },
    {
      "address_link": "",
      "address_text": "Cabinets Aebischer - Boulevard des philosophe 7, 1205 Champel GE",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:38:53.258Z",
      "description": "",
      "email": "",
      "faq": null,
      "first_name": "Stéphanie",
      "hidden": false,
      "id": "2iizsrwcgaaf1yf",
      "languages": [],
      "last_name": "Aebischer-Hamida",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-02 12:38:53.259Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/NoqKF3kJRpAi2TrZ7",
      "address_text": "Centre de Thérapies Ricochet\nCh. François-Furet 61, 1203 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:38:52.103Z",
      "description": "",
      "email": "nutrition@centre-ricochet.ch",
      "faq": null,
      "first_name": "Bakil",
      "hidden": false,
      "id": "vws3i6poqrl4m58",
      "languages": ["ciwr918a5o8h84g", "g8reptg5cwnhvor"],
      "last_name": "Açik",
      "phone_number": "0225121202",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:44:55.908Z",
      "website": "https://bio-nutritherapie.ch"
    },
    {
      "address_link": "https://g.co/kgs/yVnsWNY",
      "address_text": "c/o c/o Centre Aliotis, Bd Helvétique 30, 1207 Genève",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:38:50.257Z",
      "description": "",
      "email": "contact@christinediet4life.com",
      "faq": null,
      "first_name": "Christine",
      "hidden": false,
      "id": "f86aapi88nxly2x",
      "languages": ["ciwr918a5o8h84g", "g8reptg5cwnhvor"],
      "last_name": "Aboussouan",
      "phone_number": "0797 839 974",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:44:54.889Z",
      "website": "https://www.christinediet4life.com"
    },
    {
      "address_link": "",
      "address_text": "Rue du Collège 1, 1814 La Tour-de-Peilz",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-12-02 12:38:48.688Z",
      "description": "",
      "email": "beatriz.abad.nutrition@gmail.com",
      "faq": null,
      "first_name": "Beatriz",
      "hidden": false,
      "id": "bo3tcuzh4pvw3y0",
      "languages": ["ciwr918a5o8h84g", "g8reptg5cwnhvor"],
      "last_name": "Abad",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Nutritionniste",
      "updated": "2024-12-04 09:44:53.758Z",
      "website": "https://www.beatrizabadnutrition.com"
    },
    {
      "address_link": "",
      "address_text": "",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-10-05 15:40:04.491Z",
      "description": "<div>Pharmacienne nutritionniste spécialisée en micronutrition, j'aide mes patient·es à mieux comprendre leur organisme et ses réactions afin de répondre à leur problématique du type fatigue chronique (troubles du sommeil, gestion du stress, etc.), troubles digestifs (ballonnements, constipation, diarrhées), ou accompagnement de perte de poids.<br><br>Avec bienveillance, je vous propose des solutions personnalisées pour corriger les déséquilibres avec l'aide de l'alimentation et différents outils (les compléments alimentaires, la phytothérapie, l'action sur le microbiote). Ensemble, nous travaillons à votre rythme pour retrouver une alimentation et une hygiène de vie équilibrée qui vous permettront d'améliorer votre qualité de vie.</div>",
      "email": "",
      "faq": null,
      "first_name": "Melanie",
      "hidden": false,
      "id": "slkfbi0mpar6b5h",
      "languages": [],
      "last_name": "Hennequin Levrier",
      "phone_number": "",
      "podcastId": "n390okupvnhjm0e",
      "profile_picture": "https://gutup.pockethost.io/api/files/profiles/slkfbi0mpar6b5h/podcast_portrait_melanie_dYcxDK7nfh.png",
      "specialty": [],
      "title": "Pharmacienne et Nutritionniste",
      "updated": "2024-11-26 16:02:00.694Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/UqGCVtdsaQD6oAyJ9",
      "address_text": " 7/19, rue de Carouge - 1205 Genève, Suisse",
      "buttonLink": "https://www.instagram.com/gutup_foodie/",
      "buttonText": "Suis-moi sur Instagram",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-10-05 15:40:01.980Z",
      "description": "<div>Je suis la fondatrice de GutUp et je me réjouis de te compter dans la communauté!<br><br>Si tu veux en apprendre plus sur mon quotidien, suis mes aventures sur Instagram <strong>@gutup_foodie</strong>.<br><br><div class=\"flex content-center items-center\">Prends soin de toi.<img class=\"pl-4\" alt=\"Signature d'Elise\" src=\"/gutup/podcast/podcast1/elise-signature.svg\"></div></div>",
      "email": "",
      "faq": [
        {
          "question": "Quelle est l'adresse de Elise Garcia?",
          "reponse": "Elise Garcia reçoit des patients à 7/19, rue de Carouge - 1205 Genève, Suisse."
        },
        {
          "question": "Quelles sont les langues parlées par Elise Garcia?",
          "reponse": "Les langues parlées par Elise Garcia sont: français et anglais."
        },
        {
          "question": "Quel est le site web de Elise Garcia?",
          "reponse": "Le site web de Elise Garcia est gutup.ch"
        },
        {
          "question": "Quel est le numéro de téléphone de Elise Garcia?",
          "reponse": "Le numéro de téléphone de Elise Garcia est 068 250 93 04."
        },
        {
          "question": "Quelles sont les spécialités de Elise Garcia?",
          "reponse": "Elise Garcia pratique des actes de nutritionniste à Genève."
        }
      ],
      "first_name": "Elise",
      "hidden": false,
      "id": "bmx5gbv9dbaapwo",
      "languages": ["ciwr918a5o8h84g", "g8reptg5cwnhvor"],
      "last_name": "Garcia",
      "phone_number": "+41 78 250 93 04",
      "podcastId": "ywgiyxxxjvsriii",
      "profile_picture": "https://gutup.pockethost.io/api/files/profiles/bmx5gbv9dbaapwo/podcast_portrait_elise_lfPTbMproK.png",
      "specialty": [],
      "title": "Épicurienne",
      "updated": "2024-12-04 10:42:48.339Z",
      "website": "https://gutup.ch"
    },
    {
      "address_link": "https://maps.app.goo.gl/czAHeW6LgDUaBNwo7",
      "address_text": "",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-08-08 10:00:03.030Z",
      "description": "Diplômée en 2008 de la Haute École de Santé (HES), je suis Diététicienne, membre de l’Association Suisse des Diététicien(ne)s diplômé(e)s (ASDD) au Centre Métabolique Nutrition et Mouvement à Carouge.\r\n\r\nMon approche est globale et personnalisée. Je prends en compte l'environnement familial, la culture, les expériences de vie, le cadre de travail, et les sensations digestives de mes patients pour leur offrir des conseils adaptés et durables. En utilisant des techniques d’entretien motivationnel, je soutiens les motivations de chacun pour les aider à persévérer. Mon approche cognitivo-comportementale permet d'identifier et de travailler sur les déclencheurs des comportements alimentaires. Je crois fermement que l'acte alimentaire reflète l'éducation, les principes, la culture et les expériences de vie de chacun.\r\n\r\nPassionnée de cuisine italienne, j'adore partager des repas conviviaux avec mes proches. Je pratique également la course à pied et le fitness. Je peux mener des consultations en français et en anglais.",
      "email": "deborah.haesslein@gmail.com",
      "faq": [
        {
          "question": "Quel est le site web de Deborah Haesslein?",
          "reponse": "Le site web de Deborah Haesslein est https://www.centremetabolique.ch/"
        },
        {
          "question": "Quel est le numéro de téléphone de Deborah Haesslein?",
          "reponse": "Le numéro de téléphone de Deborah Haesslein est 0223424419."
        }
      ],
      "first_name": "Deborah",
      "hidden": false,
      "id": "r5z21ddsmu62w45",
      "languages": [],
      "last_name": "Haesslein",
      "phone_number": "0223424419",
      "podcastId": "9elkaelu8d5x6ry",
      "profile_picture": "https://gutup.pockethost.io/api/files/profiles/r5z21ddsmu62w45/podcast_portrait_deb_nSndMNAT1Y.png",
      "specialty": [],
      "title": "Diététicienne ASDD",
      "updated": "2024-12-04 09:55:26.697Z",
      "website": "https://www.centremetabolique.ch/"
    }
  ],
  "articles": [
    {
      "Subtitle": "Salut subtitle",
      "Title": "Testing title",
      "collectionId": "0o8c76mnxmyqrny",
      "collectionName": "articles",
      "created": "2024-08-02 12:18:31.265Z",
      "description": "<p>Titre:&pound;<br><br><br><strong>Sakut</strong></p>",
      "id": "m8vw93exbkee5ye",
      "picture": "",
      "updated": "2024-08-02 12:18:31.265Z"
    }
  ],
  "languages": [
    {
      "collectionId": "55hhv6rh3mgi26d",
      "collectionName": "languages",
      "created": "2024-12-04 08:20:55.358Z",
      "id": "09wr6j2qj6lsu6a",
      "language": "Italien",
      "updated": "2024-12-04 08:20:55.358Z"
    },
    {
      "collectionId": "55hhv6rh3mgi26d",
      "collectionName": "languages",
      "created": "2024-12-04 08:20:50.669Z",
      "id": "egbe5ll1g9bq31l",
      "language": "Allemand",
      "updated": "2024-12-04 08:20:50.669Z"
    },
    {
      "collectionId": "55hhv6rh3mgi26d",
      "collectionName": "languages",
      "created": "2024-12-04 08:20:45.431Z",
      "id": "ciwr918a5o8h84g",
      "language": "Anglais",
      "updated": "2024-12-04 08:20:45.431Z"
    },
    {
      "collectionId": "55hhv6rh3mgi26d",
      "collectionName": "languages",
      "created": "2024-12-04 08:20:35.965Z",
      "id": "g8reptg5cwnhvor",
      "language": "Français",
      "updated": "2024-12-04 08:20:35.965Z"
    }
  ]
}
