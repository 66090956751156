import { Component, inject, OnInit, PLATFORM_ID } from '@angular/core';
import {
  NavigationEnd,
  Router,
  RouterLink,
  RouterOutlet,
} from '@angular/router';
import { GutupFoodieComponent } from '../components/gutup-foodie/gutup-foodie.component';
import { GutupFooterComponent } from '../components/gutup-footer/gutup-footer.component';
import { GutupHeaderDesktopComponent } from '../components/gutup-header-desktop/gutup-header-desktop.component';
import { GutupMenuComponent } from '../components/gutup-menu/gutup-menu.component';
import { isPlatformBrowser, NgOptimizedImage } from '@angular/common';
import { EbookModalComponent } from '../components/gutup-ebook-modal';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    GutupHeaderDesktopComponent,
    GutupFoodieComponent,
    GutupFooterComponent,
    RouterOutlet,
    RouterLink,
    GutupMenuComponent,
    NgOptimizedImage,
    EbookModalComponent,
  ],
  template: `
    <div class="mx-auto flex min-h-screen w-full max-w-[1920px] flex-col">
      @if (showModal) {
        <app-gutup-ebook-modal
          (closeEvent)="closeModal()"
        ></app-gutup-ebook-modal>
      }

      <app-gutup-menu
        class="md:hidden"
        [showMenu]="showMenu"
        (linkClicked)="onMenuLinkClick($event)"
        (menuClosed)="onMenuClose()"
      />

      <!-- Mobile -->
      <header
        class="gutup-container flex h-20 items-center justify-between md:hidden"
      >
        <div></div>
        <img
          (click)="menuClick()"
          src="/gutup/burger.png"
          alt="Menu"
          class="h-14 w-14"
        />
      </header>

      <app-gutup-header-desktop class="hidden md:block" />
      <div class="md:mx-[10vh] md:flex-grow">
        <router-outlet (activate)="onActivate()" />
      </div>

      @if (showFoodie) {
        <div>
          <app-gutup-foodie />
          <div class="bg-gutup-footer-light">
            <app-gutup-footer />
          </div>
        </div>
      } @else {
        <app-gutup-footer />
      }

      <!-- Sticky Footer -->
      <div class="sticky bottom-0 w-full bg-gutup-green py-2">
        <a (click)="openModal()">
          <div
            class="flex items-center justify-center space-x-2 bg-footer-text-mobile bg-center bg-no-repeat md:bg-footer-text"
          >
            <span
              class="ml-4 font-vinasans text-lg text-black md:ml-0 md:text-3xl"
            >
              Ton guide pour ne pas tomber malade en hiver!
            </span>
            <img
              class="w-8 md:w-12"
              alt="Un bras musclé"
              ngSrc="/gutup/mission/arm.png"
              height="656"
              width="498"
            />
          </div>
        </a>
      </div>
    </div>
  `,
})
export class AppComponent implements OnInit {
  readonly #router = inject(Router);
  readonly isBrowser = isPlatformBrowser(inject(PLATFORM_ID));
  protected document = inject(DOCUMENT);

  showFoodie = false;
  showMenu = false;
  showModal = true;
  showFoodieRoutes = ['/', '/podcast']; // Array storing valid routes for displaying GutupFoodieComponent

  ngOnInit() {
    this.#router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.showFoodie = this.#checkRoute(event.urlAfterRedirects);
      }
    });

    // if (this.isBrowser) {
    //   const modalSeen = localStorage.getItem('modalSeen');
    //   if (modalSeen !== 'true') {
    //     this.openModal();
    //   }
    // }
  }

  openModal() {
    this.showModal = true;
    this.document.body.classList.add('overflow-hidden');
  }

  closeModal() {
    this.showModal = false;
    this.document.body.classList.remove('overflow-hidden');
    if (this.isBrowser) {
      localStorage.setItem('modalSeen', 'true');
    }
  }

  #checkRoute(url: string): boolean {
    return this.showFoodieRoutes.some((route) => url.includes(route));
  }

  menuClick() {
    this.showMenu = !this.showMenu;
  }

  onMenuClose() {
    this.showMenu = false;
  }

  onMenuLinkClick(url: string) {
    this.showMenu = false;
    this.#router.navigate([url]);
  }

  onActivate() {
    if (this.isBrowser) {
      window.scrollTo({
        left: 0,
        top: 0,
        behavior: 'smooth',
      });
    }
  }
}
