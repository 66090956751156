import { catchError, lastValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { Data, DataProvider } from './data-provider';

export class DevDataProvider implements DataProvider {
  #http = inject(HttpClient);

  getData(): Promise<Data> {
    return lastValueFrom(
      this.#http.get<Data>('/api/get-data').pipe(
        catchError((error) => {
          console.error('Error fetching data:', error);
          throw error;
        }),
      ),
    );
  }
}
