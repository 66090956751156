import { inject, Injectable } from '@angular/core';
import { FoodVm } from './food-vm';
import { DataService } from '../../services/data.service';

@Injectable({
  providedIn: 'root',
})
export class FoodService {
  #dataService = inject(DataService);

  getFoods(foodId: string): FoodVm | undefined {
    const foods = this.#dataService.getFoods();
    const food = foods.find((i) => i.id === foodId);
    return food ? { id: food.id, name: food.name } : undefined;
  }
}
