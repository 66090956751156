import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { RouterLink } from '@angular/router';
import { SocialIconComponent } from '../social-icon/social-icon.component';

@Component({
  selector: 'app-gutup-menu',
  standalone: true,
  imports: [RouterLink, SocialIconComponent],
  template: ` <div
    #menuContainer
    [class.translate-x-full]="!showMenu"
    class="fixed left-[45%] top-[15%] z-10 flex h-124 w-96 transform bg-menu bg-contain bg-no-repeat transition-transform duration-500 ease-out"
  >
    <div
      class="mt-32 flex h-3/5 w-3/5 flex-col items-center justify-center space-y-10"
    >
      <img
        (click)="onLinkClicked('/')"
        src="/gutup/logo-simple.svg"
        alt="Logo GutUp"
        class="w-32"
      />
      <div class="space-y-6 text-center text-white">
        <div
          routerLink="/mission"
          (click)="onLinkClicked('/mission')"
          class="gutup-header-button"
        >
          MISSION
        </div>
        <div
          routerLink="/podcast"
          (click)="onLinkClicked('/podcast')"
          class="gutup-header-button"
        >
          PODCAST
        </div>
        <div
          routerLink="/contact"
          (click)="onLinkClicked('/contact')"
          class="gutup-header-button"
        >
          CONTACT
        </div>
        <!--                    - https://open.spotify.com/show/3oLhLQFNBUadCvciSze0PZ-->

        <div class="flex justify-center space-x-6">
          <app-social-icon
            [src]="'/social/insta.svg'"
            [link]="'https://www.instagram.com/gutup_foodie/'"
          />
          <app-social-icon
            [src]="'/social/youtube.svg'"
            [link]="'https://www.youtube.com/@gutup_foodie'"
          />
        </div>

        <div class="flex justify-center space-x-6">
          <!--          <app-social-icon-->
          <!--            [src]="'/social/lkdn.svg'"-->
          <!--            [link]="'https://www.linkedin.com/company/gutup/'"-->
          <!--          />-->
          <app-social-icon
            [src]="'/social/spotify.svg'"
            [link]="'https://open.spotify.com/show/3oLhLQFNBUadCvciSze0PZ'"
          />
        </div>
      </div>
    </div>
  </div>`,
})
export class GutupMenuComponent {
  @Input() showMenu = false;
  @Output() linkClicked = new EventEmitter<string>();
  @Output() menuClosed = new EventEmitter<void>();

  constructor(private eRef: ElementRef) {}

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    if (!this.eRef.nativeElement.contains(event.target) && this.showMenu) {
      this.showMenu = false;
      this.menuClosed.emit();
    }
  }

  onLinkClicked(url: string): void {
    this.linkClicked.emit(url);
  }
}
