import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { RecipeVm } from './recipe-vm';

@Component({
  standalone: true,
  imports: [CommonModule, RouterModule],
  template: `
    @if (recipe) {
      <h4 class="gutup-title">Recette:</h4>
      <br />
      <h3>{{ recipe.title }}</h3>
      <br />
      <h4 class="gutup-title">Aliments:</h4>
      <br />
      <ul>
        @for (food of recipe.foods; track food.id) {
          <li>
            <a [routerLink]="['/aliments', food.id]">{{ food.name }}</a>
          </li>
        }
      </ul>
    } @else {
      <p>Recipe not found.</p>
    }
  `,
})
export class RecipeComponent {
  recipe: RecipeVm = inject(ActivatedRoute).snapshot.data['recipe'];
}
