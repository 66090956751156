import { Component } from '@angular/core';
import { NgClass, NgOptimizedImage } from '@angular/common';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-gutup-podcast-bubble-card',
  standalone: true,
  imports: [NgClass, NgOptimizedImage, RouterLink],
  template: `
    <div class="relative flex min-h-[300px] min-w-[300px] items-center">
      <div
        class="absolute inset-0 -z-10 min-h-[300px] bg-podcast-bubble bg-no-repeat"
      ></div>
      <div class="flex max-w-[300px] flex-col px-12">
        <ng-content></ng-content>
      </div>
    </div>
  `,
})
export class GutupPodcastBubbleCardComponent {}
