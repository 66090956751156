import { SourceSection } from './nos-sources.component';

export const sourceData: SourceSection[] = [
  {
    title: "L'importance de ton intestin dans ton immunite",
    items: [
      {
        itemTitle: 'Gut Microbiota, Immune System, and Bone',
        author: "D'Amelio P, Sassi F",
        reference: 'PMID: 8965190',
      },
      {
        itemTitle:
          'Gut-Microbiota-Derived Metabolites Maintain Gut and Systemic Immune Homeostasis',
        author: 'Wang J, Zhu N, Su X, et al.',
        reference: 'PMID: 36899929',
      },
      {
        itemTitle:
          'Interaction between the Gut Microbiome and Mucosal Immune System',
        author: 'Shi N, Li N, Duan X, Niu H',
        reference: 'PMID: 28465831',
      },
      {
        itemTitle:
          'Nutritional Components in Western Diet Versus Mediterranean Diet at the Gut Microbiota-Immune System Interplay',
        author: 'García-Montero C, et al.',
        reference: 'PMID: 33671569',
      },
      {
        itemTitle:
          'The Interplay between the Gut Microbiome and the Immune System in the Context of Infectious Diseases throughout Life and the Role of Nutrition',
        author: 'Wiertsema SP, van Bergenhenegouwen J, et al.',
        reference: 'PMID: 33803407',
      },
      {
        itemTitle:
          'Exercise-induced stress behavior, gut-microbiota-brain axis and diet: a systematic review for athletes',
        author: 'Clark A, Mach N',
        reference: 'PMID: 27924137',
      },
      {
        itemTitle: 'The Microbiota-Gut-Brain Axis in Psychiatric Disorders',
        author: 'Góralczyk-Bińkowska A, Szmajda-Krygier D, Kozłowska E',
        reference: 'PMID: 36232548',
      },
      {
        itemTitle:
          'Brain-gut-microbiota axis in depression: A historical overview and future directions',
        author: 'Chang L, Wei Y, Hashimoto K',
        reference: 'PMID: 35151796',
      },
      {
        itemTitle: 'Stress and the gut microbiota-brain axis',
        author: 'Molina-Torres G, Rodriguez-Arrastia M, Roman P, et al.',
        reference: 'PMID: 30844962',
      },
      {
        itemTitle:
          'Nutrition, oxidative stress and intestinal dysbiosis: Influence of diet on gut microbiota in inflammatory bowel diseases',
        author: 'Tomasello G, Mazzola M, Leone A, et al.',
        reference: 'PMID: 27812084',
      },
    ],
  },
  {
    title: 'Adopte une alimentation anti-inflammatoire',
    items: [
      {
        itemTitle:
          'Marine omega-3 fatty acids and inflammatory processes: Effects, mechanisms and clinical relevance',
        author: 'Calder PC',
        reference: 'PMID: 25149823',
      },
      {
        itemTitle:
          'GPR120 is an omega-3 fatty acid receptor mediating potent anti-inflammatory and insulin-sensitizing effects',
        author: 'Oh DY, Talukdar S, et al.',
        reference: 'PMID: 20813258',
      },
      {
        itemTitle:
          'Omega-3 fatty acids in inflammation and autoimmune diseases',
        author: 'Simopoulos AP',
        reference: 'PMID: 12480795',
      },
      {
        itemTitle:
          'Anti-inflammatory effects of omega 3 and omega 6 polyunsaturated fatty acids in cardiovascular disease and metabolic syndrome',
        author: 'Tortosa-Caparrós E, Navas-Carrillo D, et al.',
        reference: 'PMID: 26745681',
      },
      {
        itemTitle: 'Anti-inflammatory Diets',
        author: 'Sears B',
        reference: 'PMID: 26400429',
      },
      {
        itemTitle:
          'The Impact of Dietary Fiber on Gut Microbiota in Host Health and Disease',
        author: 'Makki K, Deehan EC, Walter J, Bäckhed F',
        reference: 'PMID: 29902436',
      },
      {
        itemTitle: 'Role of the gut microbiota in nutrition and health',
        author: 'Valdes AM, Walter J, et al.',
        reference: 'PMID: 29899036',
      },
      {
        itemTitle:
          'High-fiber diet ameliorates gut microbiota, serum metabolism and emotional mood in type 2 diabetes patients',
        author: 'Chen L, Liu B, et al.',
        reference: 'PMID: 36794003',
      },
      {
        itemTitle:
          'The Role of the Gut Microbiota in the Relationship Between Diet and Human Health',
        author: 'Perler BK, Friedman ES, Wu GD',
        reference: 'PMID: 36375468',
      },
      {
        itemTitle:
          'Soluble Dietary Fiber, One of the Most Important Nutrients for the Gut Microbiota',
        author: 'Guan ZW, Yu EZ, Feng Q',
        reference: 'PMID: 34833893',
      },
      {
        itemTitle: 'Role of Antioxidants and Natural Products in Inflammation',
        author: 'Arulselvan P, Fard MT, Tan WS, et al.',
        reference: 'PMID: 27803762',
      },
      {
        itemTitle:
          'Curcumin, inflammation, and chronic diseases: how are they linked?',
        author: 'He Y, Yue Y, Zheng X, et al.',
        reference: 'PMID: 26007179',
      },
      {
        itemTitle:
          'Oxidative Stress and Inflammation: What Polyphenols Can Do for Us?',
        author: 'Hussain T, Tan B, Yin Y, et al.',
        reference: 'PMID: 27738491',
      },
    ],
  },
  {
    title: 'Optimise ton apport en vitamine D',
    items: [
      {
        itemTitle:
          'Influence of vitamin D status and vitamin D3 supplementation on genome wide expression of white blood cells',
        author: 'Hossein-nezhad A, Spira A, Holick MF',
        reference: 'PMID: 23527013',
      },
      {
        itemTitle:
          'Primary vitamin D target genes allow a categorization of possible benefits of vitamin D3 supplementation',
        author: 'Carlberg C, Seuter S, de Mello VD, et al.',
        reference: 'PMID: 23923049',
      },
      {
        itemTitle:
          'Thymus Activity, Vitamin D, and Respiratory Infections in Adolescent Swimmers',
        author: 'Mayan I, Somech R, Lev A, et al.',
        reference: 'PMID: 26625549',
      },
      {
        itemTitle:
          'Maintenance of wintertime vitamin D status with cholecalciferol supplementation is not associated with alterations in serum cytokine concentrations among apparently healthy younger or older adults',
        author: 'Barnes MS, Horigan G, Cashman KD, et al.',
        reference: 'PMID: 21270359',
      },
      {
        itemTitle:
          'Efficiency of Vitamin D Supplementation in Healthy Adults is Associated with Body Mass Index and Baseline Serum 25-Hydroxyvitamin D Level',
        author: 'Žmitek K, Hribar M, Hristov H, Pravst I',
        reference: 'PMID: 32365732',
      },
    ],
  },
  {
    title: 'Consomme des probiotiques naturels',
    items: [
      {
        itemTitle:
          'Yogurt and other fermented foods as sources of health-promoting bacteria',
        author: 'Kok CR, Hutkins R',
        reference: 'PMID: 30452699',
      },
      {
        itemTitle:
          'Clinical application of probiotics in type 2 diabetes mellitus: A randomized, double-blind, placebo-controlled study',
        author:
          'Tonucci LB, Olbrich Dos Santos KM, Licursi de Oliveira L, et al.',
        reference: 'PMID: 26732026',
      },
      {
        itemTitle:
          'Cancer-protective effect of a synbiotic combination between Lactobacillus gasseri 505 and a Cudrania tricuspidata leaf extract on colitis-associated colorectal cancer',
        author: 'Oh NS, Lee JY, Kim YT, et al.',
        reference: 'PMID: 32663105',
      },
      {
        itemTitle:
          'Relation Between Gut Microbiota Composition and Traditional Spontaneous Fermented Dairy Foods Among Kazakh Nomads in Xinjiang, China',
        author: 'Li R, Zheng X, Yang J, et al.',
        reference: 'PMID: 31750942',
      },
      {
        itemTitle:
          "Consumer's acceptability and health consciousness of probiotic and prebiotic of non-dairy products",
        author: 'Cosme F, Inês A, Vilela A',
        reference: 'PMID: 34980381',
      },
    ],
  },
  {
    title: 'Inclus des aliments riches en vitamine C',
    items: [
      {
        itemTitle: 'Vitamin C and Immune Function',
        author: 'Carr AC, Maggini S',
        reference: 'PMID: 29099763',
      },
      {
        itemTitle: 'Vitamin C: should we supplement?',
        author: 'Spoelstra-de Man AME, Elbers PWG, Oudemans-Van Straaten HM',
        reference: 'PMID: 29864039',
      },
      {
        itemTitle:
          'The Long History of Vitamin C: From Prevention of the Common Cold to Potential Aid in the Treatment of COVID-19',
        author: 'Cerullo G, Negro M, Parimbelli M, et al.',
        reference: 'PMID: 33193359',
      },
      {
        itemTitle:
          'Immune-enhancing role of vitamin C and zinc and effect on clinical conditions',
        author: 'Wintergerst ES, Maggini S, Hornig DH',
        reference: 'PMID: 16373990',
      },
      {
        itemTitle:
          'Vitamin C and immune cell function in inflammation and cancer',
        author: 'Ang A, Pullar JM, Currie MJ, Vissers MCM',
        reference: 'PMID: 30301842',
      },
    ],
  },
  {
    title: 'Utilise des herbes et Epices immunostimulantes',
    items: [
      {
        itemTitle: 'Curcumin, hemostasis, thrombosis, and coagulation',
        author: 'Keihanian F, Saeidinia A, Bagheri RK, Johnston TP, Sahebkar A',
        reference: 'PMID: 29052850',
      },
      {
        itemTitle:
          'Curcumin: a modulator of inflammatory signaling pathways in the immune system',
        author:
          'Kahkhaie KR, Mirhosseini A, Aliabadi A, Mohammadi A, Mousavi MJ, et al.',
        reference: 'PMID: 31140036',
      },
      {
        itemTitle:
          'A Cardiac-Targeted Nanozyme Interrupts the Inflammation-Free Radical Cycle in Myocardial Infarction',
        author: 'Liu X, Chen B, Chen J, et al.',
        reference: 'PMID: 37985164',
      },
      {
        itemTitle:
          'Prevalence, Beliefs, and the Practice of the Use of Herbal and Dietary Supplements Among Adults in Saudi Arabia: An Observational Study',
        author: 'Syed W, et al.',
        reference: 'PMID: 33435749',
      },
      {
        itemTitle:
          'Dietary supplements, vitamins and minerals as potential interventions against viruses: Perspectives for COVID-19',
        author: 'Islam MT, et al.',
        reference: 'PMID: 33435749',
      },
      {
        itemTitle:
          'Biological properties of garlic and garlic-derived organosulfur compounds',
        author: 'Iciek M, Kwiecień I, Włodek L',
        reference: 'PMID: 19253339',
      },
      {
        itemTitle:
          'Garlic and ginger extracts modulated broiler chicks innate immune responses and enhanced multidrug resistant Escherichia coli O78 clearance',
        author: 'Elmowalid GA, Abd El-Hamid MI, et al.',
        reference: 'PMID: 31437688',
      },
      {
        itemTitle: 'Antimicrobial effect of garlic (Allium sativum)',
        author: 'Goncagul G, Ayaz E',
        reference: 'PMID: 19929845',
      },
      {
        itemTitle:
          'Bio-efficacy of the essential oil of oregano (Origanum vulgare Lamiaceae. Ssp. Hirtum)',
        author: 'Grondona E, Gatti G, López AG, et al.',
        reference: 'PMID: 25266989',
      },
    ],
  },
  {
    title: 'Reduis ton stress',
    items: [
      {
        itemTitle:
          'International Society of Sports Nutrition Position Stand: Probiotics',
        author: 'Jäger R, Mohr AE, Carpenter KC, et al.',
        reference: 'PMID: 31864419',
      },
      {
        itemTitle: 'Brain-gut interactions in inflammatory bowel disease',
        author: 'Bonaz BL, Bernstein CN',
        reference: 'PMID: 23063970',
      },
      {
        itemTitle:
          'Signalling cognition: the gut microbiota and hypothalamic-pituitary-adrenal axis',
        author: 'Rusch JA, Layden BT, Dugas LR',
        reference: 'PMID: 37404311',
      },
      {
        itemTitle:
          'Gut microbiota diversity but not composition is related to saliva cortisol stress response at the age of 2.5 months',
        author: 'Keskitalo A, Aatsinki AK, Kortesluoma S, et al.',
        reference: 'PMID: 33729084',
      },
      {
        itemTitle:
          'Posttraumatic Stress Disorder: Does the Gut Microbiome Hold the Key?',
        author: 'Leclercq S, Forsythe P, Bienenstock J',
        reference: 'PMID: 27254412',
      },
    ],
  },
  {
    title: 'Dors suffisamment',
    items: [
      {
        itemTitle:
          'Circadian Clock, Glucocorticoids and NF-κB Signaling in Neuroinflammation - Implicating Glucocorticoid Induced Leucine Zipper as a Molecular Link',
        author: 'Srinivasan M, Walker C',
        reference: 'PMID: 36317290',
      },
      {
        itemTitle:
          'Thrombospondin-1 contributes to mortality in murine sepsis through effects on innate immunity',
        author: 'McMaken S, Exline MC, et al.',
        reference: 'PMID: 21573017',
      },
    ],
  },
  {
    title: "Fais de l'exercice régulièrement",
    items: [
      {
        itemTitle:
          'Exercise-induced immune system response: Anti-inflammatory status on peripheral and central organs',
        author: 'Scheffer DDL, Latini A',
        reference: 'PMID: 32360589',
      },
      {
        itemTitle:
          'Exercise and the immune system: taking steps to improve responses to cancer immunotherapy',
        author: 'Gustafson MP, Wheatley-Guy CM, et al.',
        reference: 'PMID: 34215686',
      },
      {
        itemTitle:
          'Exercise and immune system as modulators of intestinal microbiome: implications for the gut-muscle axis hypothesis',
        author: 'Ticinesi A, Lauretani F, et al.',
        reference: 'PMID: 30753131',
      },
      {
        itemTitle: 'Exercise Regulates the Immune System',
        author: 'Wang J, Liu S, Li G, Xiao J',
        reference: 'PMID: 32342473',
      },
    ],
  },
  {
    title: 'Optimise ton apport en zinc',
    items: [
      {
        itemTitle: 'Zinc as a Gatekeeper of Immune Function',
        author: 'Wessels I, Maywald M, Rink L',
        reference: 'PMID: 29186856',
      },
      {
        itemTitle:
          'Zinc: Dietary Intake and Impact of Supplementation on Immune Function in Elderly',
        author: 'Mocchegiani E, Romeo J, et al.',
        reference: 'PMID: 22222917',
      },
      {
        itemTitle: 'Zinc-Altered Immune Function',
        author: 'Ibs KH, Rink L',
        reference: 'PMID: 12730441',
      },
      {
        itemTitle: 'Zinc and Immune Function',
        author: 'Dardenne M',
        reference: 'PMID: 12142956',
      },
      {
        itemTitle: 'Zinc Signals and Immune Function',
        author: 'Haase H, Rink L',
        reference: 'PMID: 23804522',
      },
    ],
  },
  {
    title: 'Optimise ton apport en glutamine',
    items: [
      {
        itemTitle:
          'Glutamine: Metabolism and Immune Function, Supplementation and Clinical Translation',
        author: 'Cruzat V, Macedo Rogero M, et al.',
        reference: 'PMID: 30360490',
      },
      {
        itemTitle: 'Glutamine metabolism and optimal immune and CNS function',
        author: 'Newsholme P, Diniz VLS, et al.',
        reference: 'PMID: 36285520',
      },
      {
        itemTitle:
          'The effect of glutamine supplementation on athletic performance, body composition, and immune function: A systematic review and a meta-analysis of clinical trials',
        author: 'Ramezani Ahmadi A, Rayyani E, et al.',
        reference: 'PMID: 29784526',
      },
    ],
  },
  {
    title: 'Profite des proprietes du miel cru',
    items: [
      {
        itemTitle:
          'Evaluation of the Immunomodulatory and Anti-Inflammatory Activities of Honey Bee Larva Powder',
        author: 'Li K, Sun S, et al.',
        reference: 'PMID: 32456573',
      },
      {
        itemTitle:
          'Two Cases of Royal Jelly Allergy Provoked by the First Intake',
        author: 'Harada S, Moriyama T, Tanaka A.',
        reference: 'PMID: 21709438',
      },
    ],
  },
  {
    title: 'Fuis les exces de sucre',
    items: [
      {
        itemTitle:
          'Crosstalk between glucose metabolism, lactate production and immune response modulation',
        author: 'Ye L, Jiang Y, Zhang M',
        reference: 'PMID: 36376165',
      },
      {
        itemTitle:
          'Non-alcoholic fatty liver disease: the interplay between metabolism, microbes and immunity',
        author: 'Tilg H, Adolph TE, Dudek M, Knolle P',
        reference: 'PMID: 34931080',
      },
      {
        itemTitle:
          'Fibroblasts: Immunomodulatory factors in refractory diabetic wound healing',
        author: 'Liu Y, Liu Y, He W, et al.',
        reference: 'PMID: 35990622',
      },
      {
        itemTitle:
          'High sugar diet alters immune function and the gut microbiome in juvenile green iguanas',
        author: 'Ki KC, Lewis EL, Wu E, et al.',
        reference: 'PMID: 38804667',
      },
      {
        itemTitle:
          'Cellular and metabolic mechanisms of nutrient actions in immune function',
        author: 'Newsholme P',
        reference: 'PMID: 34163016',
      },
    ],
  },
  {
    title: 'Expose-toi à la lumière naturelle',
    items: [
      {
        itemTitle:
          'Natural light exposure and circadian rhythm: a potential therapeutic approach for disorders of consciousness',
        author: 'Wang J, Di H',
        reference: 'PMID: 35446433',
      },
      {
        itemTitle:
          'Consequences of Circadian Disruption in Shift Workers on Chrononutrition and their Psychosocial Well-Being',
        author: 'Mohd Azmi NAS, Juliana N, et al.',
        reference: 'PMID: 32204445',
      },
      {
        itemTitle:
          'Circadian Entrainment to the Natural Light-Dark Cycle across Seasons and the Weekend',
        author: 'Stothard ER, McHill AW, et al.',
        reference: 'PMID: 28162893',
      },
      {
        itemTitle: 'Coordination of circadian timing in mammals',
        author: 'Reppert SM, Weaver DR',
        reference: 'PMID: 12198538',
      },
    ],
  },
  {
    title: 'Incorpore des aliments riches en sélénium',
    items: [
      {
        itemTitle: 'Selenium, Selenoproteins, and Immunity',
        author: 'Avery JC, Hoffmann PR',
        reference: 'PMID: 30200430',
      },
      {
        itemTitle: 'Selenium and Selenoproteins in Health',
        author: 'Zhang F, Li X, Wei Y',
        reference: 'PMID: 37238669',
      },
      {
        itemTitle:
          'The Role of Minerals in the Optimal Functioning of the Immune System',
        author: 'Weyh C, Krüger K, Peeling P, Castell L',
        reference: 'PMID: 35277003',
      },
      {
        itemTitle:
          'Selenium in the Environment, Metabolism and Involvement in Body Functions',
        author: 'Mehdi Y, Hornick JL, Istasse L, Dufrasne I',
        reference: 'PMID: 23486107',
      },
    ],
  },
  {
    title: 'Utilise des huiles essentielles',
    items: [
      {
        itemTitle:
          'Immune-modifying and antimicrobial effects of Eucalyptus oil and simple inhalation devices',
        author: 'Sadlon AE, Lamson DW',
        reference: 'PMID: 20359267',
      },
      {
        itemTitle:
          'Olfactory influences on mood and autonomic, endocrine, and immune function',
        author: 'Kiecolt-Glaser JK, et al.',
        reference: 'PMID: 18178322',
      },
      {
        itemTitle:
          'Physiological effect of olfactory stimuli inhalation in humans: an overview',
        author: 'Angelucci FL, et al.',
        reference: 'PMID: 24117755',
      },
      {
        itemTitle: 'Characterisation of pollen allergens',
        author: 'Puc M',
        reference: 'PMID: 14677904',
      },
      {
        itemTitle:
          'Chitin-induced airway epithelial cell innate immune responses are inhibited by Carvacrol/Thymol',
        author: 'Khosravi AR, Erle DJ',
        reference: 'PMID: 27463381',
      },
    ],
  },
  {
    title: 'Consomme des aliments riches en flavonoides',
    items: [
      {
        itemTitle: 'Quercetin, Inflammation and Immunity',
        author: 'Li Y, Yao J, Han C, et al.',
        reference: 'PMID: 26999194',
      },
      {
        itemTitle: 'Potential Implications of Quercetin in Autoimmune Diseases',
        author: 'Shen P, Lin W, Deng X, et al.',
        reference: 'PMID: 34248976',
      },
      {
        itemTitle:
          'Astragalus membranaceus: A Review of its Protection Against Inflammation and Gastrointestinal Cancers',
        author: 'Auyeung KK, Han QB, Ko JK',
        reference: 'PMID: 26916911',
      },
    ],
  },
  {
    title: 'Augmente ta consommation de proteines vegetales',
    items: [
      {
        itemTitle:
          'Animal- and Plant-Based Protein Sources: A Scoping Review of Human Health Outcomes and Environmental Impacts',
        author: 'Ferrari L, Panaite SA, Bertazzo A, Visioli F',
        reference: 'PMID: 36501146',
      },
      {
        itemTitle: 'UEFA Expert Group Statement on Nutrition in Elite Football',
        author: 'Collins J, Maughan RJ, et al.',
        reference: 'PMID: 33097528',
      },
      {
        itemTitle: 'Fueling the Vegetarian (Vegan) Athlete',
        author: 'Fuhrman J, Ferreri DM',
        reference: 'PMID: 20622542',
      },
      {
        itemTitle:
          'Health Benefits of Plant-Based Nutrition: Focus on Beans in Cardiometabolic Diseases',
        author: 'Mullins AP, Arjmandi BH',
        reference: 'PMID: 33562498',
      },
    ],
  },
  {
    title: "Evite au maximum l'alcool",
    items: [
      {
        itemTitle: 'Anaphylaxis',
        author: 'Bilò MB, Martini M, Tontini C, et al.',
        reference: 'PMID: 32550734',
      },
      {
        itemTitle: 'Alcohol and melatonin',
        author: 'Kurhaluk N',
        reference: 'PMID: 33761823',
      },
      {
        itemTitle: 'Management of Anaphylaxis',
        author: 'Navalpakam A, Thanaputkaiporn N, Poowuttikul P',
        reference: 'PMID: 34823751',
      },
      {
        itemTitle: 'Nanoparticles and the immune system',
        author: 'Zolnik BS, González-Fernández A, et al.',
        reference: 'PMID: 20016026',
      },
    ],
  },
  {
    title: 'Aere ta maison quotidiennement',
    items: [
      {
        itemTitle:
          'Indoor Household Exposures and Associated Morbidity and Mortality Outcomes in Children and Adults in South Africa',
        author: 'Morakinyo OM, Mokgobu MI',
        reference: 'PMID: 35954827',
      },
      {
        itemTitle:
          'Reduction of Household Air Pollution Through Clean Fuel Intervention and Recovery of Cellular Immune Balance',
        author: 'Raqib R, et al.',
        reference: 'PMID: 37579572',
      },
      {
        itemTitle:
          'Association of Household Air Pollution with Cellular and Humoral Immune Responses Among Women in Rural Bangladesh',
        author: 'Raqib R, et al.',
        reference: 'PMID: 35077836',
      },
      {
        itemTitle:
          'Irritancy and Allergic Responses Induced by Exposure to the Indoor Air Chemical 4-Oxopentanal',
        author: 'Anderson SE, et al.',
        reference: 'PMID: 22403157',
      },
    ],
  },
];
