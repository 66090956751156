import { Component, input } from '@angular/core';
import { bookData } from './book-source.data';

export interface Book {
  title: string;
  author: string;
}

@Component({
  selector: 'app-book-source-list',
  standalone: true,
  template: `
    <div class="mb-8 space-y-4">
      <h3 class="mb-4 text-center font-vinasans text-3xl">
        Livres et publications
      </h3>
      <div class="mb-8 space-y-4">
        @for (item of sourceItems(); track item.title) {
          <div class="mb-4 flex flex-col items-center px-12 text-center">
            <span>"{{ item.title }}" - {{ item.author }}</span>
          </div>
        }
      </div>
    </div>
  `,
})
export class BookSourceListComponent {
  title = input<string>('');
  sourceItems = input<Book[]>([]);
  protected readonly bookSourceItems = bookData;
}
