import { AfterViewInit, Component, inject } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-instaflux',
  standalone: true,
  template: `
    <div class="instagram-preview">
      <iframe
        [src]="instagramEmbed"
        width="400"
        height="480"
        frameborder="0"
        scrolling="no"
        allowtransparency="true"
      ></iframe>
    </div>
    <div class="instagram-posts">
      <blockquote
        class="instagram-media"
        [attr.data-instgrm-permalink]="post1Url"
        data-instgrm-version="14"
      ></blockquote>
      <blockquote
        class="instagram-media"
        [attr.data-instgrm-permalink]="post2Url"
        data-instgrm-version="14"
      ></blockquote>
    </div>
  `,
  styles: [
    `
      .instagram-preview,
      .instagram-posts {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 20px;
        padding: 20px;
      }

      .instagram-media {
        max-width: 540px !important;
        min-width: 326px !important;
        width: calc(50% - 20px) !important;
      }
    `,
  ],
})
export class InstafluxComponent implements AfterViewInit {
  instagramEmbed: SafeResourceUrl;
  post1Url = 'https://www.instagram.com/reel/C9SyA6lI2MU/';
  post2Url = 'https://www.instagram.com/reel/C9NaRdYIzXT/';

  document = inject(DOCUMENT);

  constructor(private sanitizer: DomSanitizer) {
    const embedUrl = 'https://www.instagram.com/gutup_foodie/embed';
    this.instagramEmbed =
      this.sanitizer.bypassSecurityTrustResourceUrl(embedUrl);
  }

  ngAfterViewInit() {
    this.loadInstagramWidgets();
  }

  loadInstagramWidgets() {
    const script = this.document.createElement('script');
    script.async = true;
    script.src = '//www.instagram.com/embed.js';
    this.document.body.appendChild(script);
  }
}
