import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DataService } from '../../services/data.service';

@Component({
  standalone: true,
  selector: 'app-food-list',
  imports: [CommonModule, RouterModule],
  template: `
    <h2 class="text-xl">Aliments</h2>
    <br />
    <ul>
      @for (food of foods; track food.id) {
        <li>
          <a [routerLink]="['/aliment', food.id]">{{ food.name }}</a>
        </li>
      }
    </ul>
  `,
})
export class FoodListComponent {
  foods = inject(DataService).getFoods();
}
