import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-gutup-foodie',
  standalone: true,
  imports: [RouterLink],
  template: `
    <div class="mt-14">
      <a
        href="https://www.instagram.com/gutup_foodie/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div
          class="h-44 bg-footer-topper bg-cover bg-no-repeat md:hidden"
        ></div>
        <div
          class="hidden h-44 bg-footer-topper-desktop bg-cover bg-no-repeat md:block"
        ></div>

        <div
          class="flex items-center justify-center bg-gutup-footer-light pb-4 md:hidden"
        >
          <span class="ml-2 font-moresugar text-5xl">&#64;gutup_foodie</span>
        </div>

        <div
          class="relative flex justify-center bg-gutup-footer-light md:space-x-24"
        >
          <div class="relative flex md:space-x-24">
            <div class="absolute inset-0 hidden items-center md:flex">
              <span class="ml-2 font-moresugar text-7xl"
                >&#64;gutup_foodie</span
              >
            </div>

            <img
              src="/gutup/footer/Component 3.svg"
              alt="Instagram post image"
              class="h-40 w-40 md:h-auto md:w-auto"
            />
            <img
              src="/gutup/footer/Component 4.svg"
              alt="Instagram post image"
              class="h-40 w-40 md:h-auto md:w-auto"
            />
          </div>
          <img
            class="hidden md:block"
            src="/gutup/footer/Component 5.svg"
            alt="Instagram post image"
          />
          <img
            class="hidden md:block"
            src="/gutup/footer/Component 6.svg"
            alt="Instagram post image"
          />
        </div>
      </a>
    </div>
  `,
})
export class GutupFoodieComponent {}
