import { Component, inject, isDevMode } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HubSpotFormData, HubspotService } from './hubspot.service';

interface FreeResourceHubSpotFormInput {
  email: string;
  firstname: string;
  zip: string;
}

@Component({
  selector: 'app-free-resource-hubspot-form',
  standalone: true,
  styles: [
    `
      input {
        @apply rounded-xl border border-black;
      }

      .error-message {
        @apply mt-1 text-sm text-gutup-dark;
      }
    `,
  ],
  template: `
    <div class="flex w-full flex-col">
      <div class="gutup-container flex flex-col md:px-12">
        <h2 class="gutup-title -mt-12 mb-8 self-center md:-mt-8 md:self-auto">
          Ressource Gratuite
        </h2>
        <p class="font-moresugar text-xl">
          Reçois notre guide avec des astuces simples pour équilibrer ton
          microbiote.
        </p>
        <p class="mb-8">
          Télécharge instantanément les astuces sous la forme d'un PDF
          imprimable d'une page.
        </p>

        <form
          [formGroup]="form"
          id="free-resource-form"
          class="grid w-full grid-cols-1 gap-6 md:grid-cols-5 md:gap-10"
          (ngSubmit)="onSubmit()"
        >
          <div class="col-span-full md:col-span-2">
            <input
              formControlName="email"
              type="email"
              id="email"
              name="email"
              placeholder="julien.dupont@exemple.ch"
              class="w-full rounded border px-4 py-2"
              [ngClass]="{ 'border-gutup-dark': showError('email') }"
            />
            @if (showError('email')) {
              <div class="error-message">
                {{ getEmailErrorMessage() }}
              </div>
            }
          </div>
          <div class="col-span-full md:col-span-1">
            <input
              formControlName="firstname"
              type="text"
              id="firstname"
              name="firstname"
              placeholder="Prénom"
              class="w-full rounded border px-4 py-2"
              [ngClass]="{ 'border-gutup-dark': showError('firstname') }"
            />
            @if (showError('firstname')) {
              <div class="error-message">Ce champ est requis</div>
            }
          </div>
          <div class="col-span-full md:col-span-1">
            <input
              formControlName="zip"
              type="text"
              id="zip"
              name="zip"
              placeholder="Code postal"
              class="w-full rounded border px-4 py-2"
              [ngClass]="{ 'border-gutup-dark': showError('zip') }"
            />
            @if (showError('zip')) {
              <div class="error-message">Ce champ est requis</div>
            }
          </div>
          <div class="col-span-full md:col-span-1">
            <button
              class="gutup-button w-full px-0"
              type="submit"
              [disabled]="submissionStatus === 'sending'"
            >
              {{
                submissionStatus === 'sending'
                  ? 'Envoi en cours...'
                  : 'Je veux le guide'
              }}
            </button>
          </div>

          <div
            class="col-span-full flex flex-col items-center md:flex-row md:justify-between"
          >
            @if (isDevMode) {
              <button
                type="button"
                class="gutup-button"
                (click)="fillFormWithCorrectValues()"
              >
                DEV: Remplir le formulaire
              </button>
            }
          </div>

          @if (submissionStatus === 'success') {
            <div class="col-span-full mt-4 text-center text-green-600">
              Fais un tour dans tes emails (ou tes SPAMs) et ne manque plus
              jamais une nouvelle de notre part.
            </div>
          }
          @if (submissionStatus === 'error') {
            <div class="col-span-full mt-4 text-center text-red-600">
              Une erreur est survenue lors de l'envoi. Veuillez réessayer.
            </div>
          }
        </form>
      </div>
    </div>
  `,
  imports: [ReactiveFormsModule, CommonModule],
})
export default class FreeResourceFormComponent {
  form: FormGroup;
  formSubmitted = false;
  submissionStatus: 'initial' | 'sending' | 'success' | 'error' = 'initial';
  isDevMode = isDevMode();

  #fb = inject(FormBuilder);
  #hubspotService = inject(HubspotService);

  constructor() {
    this.form = this.#fb.group({
      email: ['', [Validators.required, Validators.email]],
      firstname: ['', Validators.required],
      zip: ['', Validators.required],
    });
  }

  showError(fieldName: string): boolean {
    const control = this.form.get(fieldName);
    return (
      (control?.invalid && (control.touched || this.formSubmitted)) ?? false
    );
  }

  getEmailErrorMessage(): string {
    const emailControl = this.form.get('email');
    if (emailControl?.hasError('required')) {
      return 'Ce champ est requis';
    }
    return emailControl?.hasError('email')
      ? `L'email doit être au format aaa@bbb.ccc`
      : '';
  }

  fillFormWithCorrectValues(): void {
    this.form.patchValue({
      email: 'john.doe@example.com',
      firstname: 'John',
      zip: '75000',
    });
  }

  async onSubmit(): Promise<void> {
    this.formSubmitted = true;
    this.form.markAllAsTouched();

    if (this.form.valid) {
      try {
        this.submissionStatus = 'sending';
        await this.#submitForm(this.form.value as never);
        this.submissionStatus = 'success';
        this.form.reset();
        this.formSubmitted = false;
      } catch (error) {
        console.error('Error submitting form:', error);
        this.submissionStatus = 'error';
      }
    }
  }

  #formatFormData(formData: FreeResourceHubSpotFormInput): HubSpotFormData {
    return {
      fields: [
        { objectTypeId: '0-1', name: 'email', value: formData.email },
        { objectTypeId: '0-1', name: 'firstname', value: formData.firstname },
        { objectTypeId: '0-1', name: 'zip', value: formData.zip },
      ],
      context: {
        pageUri: window.location.href,
        pageName: document.title,
      },
    };
  }

  async #submitForm(formData: {
    email: string;
    firstname: string;
    zip: string;
  }): Promise<void> {
    const hubspotData = this.#formatFormData(formData);
    const FREE_RESOURCE_FORM_ID = '4b64c84f-b42f-4239-bb56-803e4c25c065';
    await this.#hubspotService.submitForm(FREE_RESOURCE_FORM_ID, hubspotData);
  }
}

// https://legacydocs.hubspot.com/docs/methods/forms/submit_form_v3_authentication#:~:text=As%20this%20API%20is%20authenticated%2C
