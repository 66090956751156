import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeDiacritics',
  standalone: true,
})
export class RemoveDiacriticsPipe implements PipeTransform {
  transform(text: string): string {
    if (!text) return '';

    // Normalize the string and replace diacritics
    return (
      text
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '') // Remove combining diacritical marks
        // eslint-disable-next-line no-misleading-character-class
        .replace(/[\u0300-\u036f\u1DC0-\u1DFF\u20D0-\u20FF\uFE20-\uFE2F]/g, '')
    ); // Remove additional diacritical marks
  }
}
