import { Component, input } from '@angular/core';
import { NgClass } from '@angular/common';
import { GutupSimpleBubbleCardComponent } from '../../components/gutup-bubbles/gutup-simple-bubble-card.component';

@Component({
  selector: 'app-profile-bubble-card',
  standalone: true,
  imports: [NgClass, GutupSimpleBubbleCardComponent],
  template: `
    <div>
      <app-gutup-simple-bubble-card>
        <h1
          class="-mt-10 text-center font-cupcakes text-4xl md:ml-12 md:self-start md:text-4xl"
        >
          {{ title() }}
        </h1>
        <div
          class="flex items-center space-x-4 px-12 py-4 md:px-0 md:py-2 md:pl-20"
        >
          <ng-content></ng-content>
        </div>
      </app-gutup-simple-bubble-card>
    </div>
  `,
})
export class ProfileBubbleCardComponent {
  title = input.required<string>();
}
