import { Component } from '@angular/core';
import { GutupPodcastBubbleCardComponent } from '../../components/gutup-bubbles/gutup-podcast-bubble-card.component';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-profile-empty-podcast-bubble',
  template: ` <div class="flex flex-col items-center">
    <app-gutup-podcast-bubble-card>
      <div class="text-sm">
        Je souhaite participer à la prévention par l’alimentation et transmettre
        ma passion via un podcast!
        <br />
        <br />
        <a routerLink="/podcast" class="underline"
          >Je regarde les autres épisodes.</a
        >
      </div>
    </app-gutup-podcast-bubble-card>
    <div class="pt-6">
      <a href="mailto:elise@gutup.ch" class="gutup-button">Je prends contact</a>
    </div>
  </div>`,
  standalone: true,
  imports: [GutupPodcastBubbleCardComponent, RouterLink],
})
export class ProfileEmptyPodcastBubbleComponent {}
