import {
  Component,
  EventEmitter,
  inject,
  OnDestroy,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { HubSpotFormData, HubspotService } from '../hubspot.service';

@Component({
  template: '',
})
export class GenericEbookModalComponent implements OnDestroy {
  @Output() closeEvent = new EventEmitter<void>();

  protected document = inject(DOCUMENT);
  protected fb = inject(FormBuilder);
  protected hubspotService = inject(HubspotService);

  formEbook: FormGroup;
  formPaperEbook: FormGroup;

  isEbookPaperSubmitting = false;
  isEbookSubmitting = false;
  submitErrorEbook = false;
  submitErrorPaperEbook = false;
  submitSuccessEbook = false;
  submitSuccessPaperEbook = false;

  constructor() {
    const emailValidator = Validators.pattern(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    );

    this.formEbook = this.fb.group({
      email: ['', [Validators.required, Validators.email, emailValidator]],
      firstname: ['', Validators.required],
    });

    this.formPaperEbook = this.fb.group({
      email: ['', [Validators.required, Validators.email, emailValidator]],
      firstname: ['', Validators.required],
    });
  }

  ngOnDestroy() {
    this.document.body.classList.remove('overflow-hidden');
  }

  closeModal() {
    this.closeEvent.emit();
  }

  showError(form: FormGroup, fieldName: string): boolean {
    const control = form.get(fieldName);
    return control
      ? control.invalid && (control.dirty || control.touched)
      : false;
  }

  getEmailErrorMessage(form: FormGroup): string {
    const emailControl = form.get('email');
    if (emailControl?.hasError('required')) {
      return 'Email requis';
    }
    if (emailControl?.hasError('email') || emailControl?.hasError('pattern')) {
      return `L'email doit être au format aaa@bbb.ccc`;
    }
    return '';
  }

  protected formatFormData(formData: {
    email: string;
    firstname: string;
  }): HubSpotFormData {
    return {
      fields: [
        { objectTypeId: '0-1', name: 'email', value: formData.email },
        { objectTypeId: '0-1', name: 'firstname', value: formData.firstname },
      ],
      context: {
        pageUri: window.location.href,
        pageName: document.title,
      },
    };
  }

  protected async submitForm(
    formGroup: FormGroup,
    formId: string,
    successFlag: 'submitSuccessEbook' | 'submitSuccessPaperEbook',
  ) {
    if (formGroup.valid) {
      if (successFlag === 'submitSuccessEbook') {
        this.submitErrorEbook = false;
        this.isEbookSubmitting = true;
      }
      if (successFlag === 'submitSuccessPaperEbook') {
        this.submitErrorPaperEbook = false;
        this.isEbookPaperSubmitting = true;
      }
      this[successFlag] = false;

      try {
        const hubspotData = this.formatFormData(formGroup.value);
        const stripeUrl = await this.hubspotService.submitForm(
          formId,
          hubspotData,
        );
        this[successFlag] = true;
        formGroup.reset();

        if (stripeUrl) {
          window.location.href = stripeUrl;
        }
      } catch (error) {
        console.error('Error submitting form:', error);
        if (successFlag === 'submitSuccessEbook') {
          this.submitErrorEbook = true;
        }
        if (successFlag === 'submitSuccessPaperEbook') {
          this.submitErrorPaperEbook = true;
        }
      } finally {
        this.isEbookSubmitting = false;
        this.isEbookPaperSubmitting = false;
      }
    } else {
      formGroup.markAllAsTouched();
    }
  }

  async submitEbook() {
    await this.submitForm(
      this.formEbook,
      '99c12725-b48f-4a75-a8ea-7bd34e4ba3b3',
      'submitSuccessEbook',
    );
  }

  async submitPaperEbook() {
    await this.submitForm(
      this.formPaperEbook,
      'fe503809-c4b3-4e1c-8a40-4b69c2d27228',
      'submitSuccessPaperEbook',
    );
  }
}
