import { Component } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { GutupBubbleCardComponent } from '../../../components/gutup-bubbles/gutup-bubble-card.component';

@Component({
  selector: 'app-gutup-podcast-about',
  standalone: true,
  imports: [NgOptimizedImage, GutupBubbleCardComponent],
  template: `
    <div class="flex flex-col">
      <div class="gutup-container flex flex-col space-y-4 md:flex-row">
        <div
          class="gutup-title relative flex flex-col flex-wrap items-center justify-center md:hidden"
        >
          <img
            src="/gutup/podcast/title-1.svg"
            alt="Le podcast qui te veut du bien"
          />
        </div>

        <div class="relative block min-h-[400px] w-full md:w-1/2">
          <div
            class="absolute inset-0 flex min-w-[335px] animate-spin-slow justify-center"
          >
            <img
              src="/gutup/home-podcast/sun.png"
              alt="gutup 1"
              class="object-contain"
            />
          </div>

          <div class="absolute inset-0 flex min-w-[335px] justify-center">
            <img
              style="object-fit: scale-down"
              ngSrc="/gutup/podcast/podcast-about.png"
              alt="gutup 1"
              width="1896"
              height="2215"
            />
          </div>
        </div>

        <div
          class="content-center space-y-6 text-left md:w-1/2 md:max-w-[700px]"
        >
          <div
            class="gutup-title relative hidden flex-col flex-wrap items-center justify-center md:block md:pl-8"
          >
            <img
              src="/gutup/podcast/title-1-desktop.svg"
              alt="Le podcast qui te veut du bien"
            />
          </div>

          <div class="max-w-[1154px] self-center">
            <app-bubble-card>
              <div class="max-w-full space-y-4">
                <div>
                  Nous sous-estimons son pouvoir, mais l'alimentation est
                  essentielle à notre bien-être physique et mental, car elle
                  agit comme le carburant de notre corps.
                  <br />
                  <br />
                  Nos habitudes alimentaires modernes nous ont carencés en
                  nutriments essentiels, impactant négativement notre santé et
                  celle de notre planète.
                  <br />
                  <br />
                  Accompagnée de professionnels de santé et de l’alimentation,
                  on t’aide à décrypter la boîte noire qu’est notre corps et on
                  te donne les clés pour la préserver au mieux.
                  <br />
                  <br />
                  L’objectif? Te régaler, tout en restant en pleine santé pour
                  croquer la vie à pleines dents!
                  <br />
                  <br />
                  Gourmandise, santé, durabilité, rires et bienveillance sont au
                  rendez-vous!
                  <br />
                  <br />
                </div>
              </div>
            </app-bubble-card>
          </div>
        </div>
      </div>
    </div>
  `,
})
export class GutupPodcastAboutComponent {}
