import { Component, Input } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';

@Component({
  selector: 'app-podcast-shared-links',
  standalone: true,
  imports: [NgOptimizedImage],
  template: `
    <div class="flex flex-col items-center md:flex-row">
      <div class="pb-12 md:w-1/2">
        <div class="flex justify-end">
          <img
            class="md:hidden"
            ngSrc="/gutup/podcast/detail/podcast-detail-title2.svg"
            alt="gutup 1"
            height="147"
            width="336"
          />
          <img
            class="hidden md:block"
            ngSrc="/gutup/podcast/detail/podcast-detail-title2-desktop.svg"
            alt="gutup 1"
            height="197"
            width="442"
          />
        </div>
      </div>
      <div class="flex flex-col space-y-4 md:w-1/3">
        <div class="mt-12 animate-bounce-slow cursor-pointer self-start">
          <a
            [href]="spotifyLink"
            target="_blank"
            rel="noopener noreferrer"
            class="mt-12 animate-bounce-slow cursor-pointer self-start md:mt-0"
          >
            <img
              ngSrc="/gutup/podcast/detail/podcast-bouton-spotify.png"
              alt="Spotify button"
              height="100"
              width="250"
            />
          </a>
        </div>
        <div
          class="mt-6 animate-bounce-slow cursor-pointer md:mt-0 md:self-center"
        >
          <a
            [href]="youtubeLink"
            target="_blank"
            rel="noopener noreferrer"
            class="ml-24 mt-6 animate-bounce-slow cursor-pointer md:mt-0"
          >
            <img
              ngSrc="/gutup/podcast/detail/podcast-bouton-youtube.png"
              alt="YouTube button"
              height="100"
              width="250"
            />
          </a>
        </div>
      </div>
    </div>
  `,
})
export class PodcastSharedLinksComponent {
  @Input({ required: true }) spotifyLink!: string;
  @Input({ required: true }) youtubeLink!: string;
}
