import { Book } from './book-source-item.component';

export const bookData: Book[] = [
  {
    title: "L'alimentation ou la troisième médecine",
    author: 'Jean Seignalet',
  },
  { title: "Le Charme Discret de l'Intestin", author: 'Giulia Enders' },
  { title: 'The China Study', author: 'T. Colin Campbell' },
  { title: 'Nutrition et santé', author: 'Dr Walter Willett' },
  {
    title: 'Anticancer : Prévenir et lutter grâce à nos défenses naturelles',
    author: 'Dr David Servan-Schreiber',
  },
  {
    title: "Les clés de l'alimentation santé",
    author: 'Thierry Souccar et Angélique Houlbert',
  },
  { title: 'Glucose Revolution', author: 'Jessie Inchauspé' },
  { title: 'Le Guide de la Micronutrition', author: 'Dr Laurent Chevallier' },
  { title: 'Le pouvoir des protéines végétales', author: 'Dr Arnaud Cocaul' },
  { title: "L'intestin, notre deuxième cerveau", author: 'Dr Michael Mosley' },
];
