import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { AsyncPipe } from '@angular/common';
import { SvgIconComponent } from 'angular-svg-icon';

@Component({
  selector: 'app-social-icon',
  standalone: true,
  template: `
    <a
      [href]="link"
      target="_blank"
      rel="noopener noreferrer"
      class="icon-link"
    >
      <svg-icon [src]="src"></svg-icon>
    </a>
  `,
  imports: [AsyncPipe, SvgIconComponent],
  styles: [
    `
      .icon-link {
        transition: color 0.3s ease-in-out; /* Smooth transition for color change */
      }
      .icon-link:hover {
        color: #b1edc8; /* Color on hover */
      }
      .svg-icon {
        fill: currentColor; /* Ensures SVG fill uses the color of the parent element */
      }
    `,
  ],
})
export class SocialIconComponent {
  private _svg: string = '';
  public sanitizedSvg!: SafeHtml;

  @Input() color: string = '#B1EDC8';
  @Input() link: string = '';
  @Input() src: string = '';

  constructor(private sanitizer: DomSanitizer) {}

  @Input()
  set svg(value: string) {
    this._svg = value;
    this.sanitizedSvg = this.sanitizer.bypassSecurityTrustHtml(this._svg);
  }

  get svg(): string {
    return this._svg;
  }
}
