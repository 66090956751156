import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-faq-item',
  standalone: true,
  template: `
    <div class="space-y-1">
      <div class="flex cursor-pointer items-center" (click)="isOpen = !isOpen">
        <img
          [src]="
            isOpen ? '/gutup/chevron-down.png' : '/gutup/chevron-right.png'
          "
          alt="chevron"
          class="size-6"
        />
        <h3 class="">{{ question }}</h3>
      </div>

      @if (isOpen) {
        <p class="pl-6">{{ reponse }}</p>
      }
    </div>
  `,
})
export default class FaqItemComponent {
  @Input({ required: true }) question!: string;
  @Input({ required: true }) reponse!: string;
  isOpen = false;
}
